import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UploadArchivos } from '../../interfaces/cargaArchivo.interface';


@Component({
  selector: 'soph-upload-archivos',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './upload-archivos.component.html',
  styleUrl: './upload-archivos.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadArchivosComponent {
  @Input() control: FormControl = new FormControl<any>({});
  @Input() error = 'Campo requerido';
  @Input() hasDocumentUrl = false;
  @Input() fileName: string = '';
  @Input() url: string = '';
  @Input() isDisabled: boolean = false;

  @Output() fileNameChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() File: EventEmitter<UploadArchivos[]> = new EventEmitter<UploadArchivos[]>();

  selectedImage: string | ArrayBuffer | null = null;
  showMessage: boolean = false;
  showEye: boolean = false;
  showDelete: boolean = false;
  esp: boolean = true;
  currentLanguage = 'es';
  hoverText: string | undefined;
  uploading: boolean = false;
  percentLoaded: number = 0;
  documentosCargados: UploadArchivos[] = [];
  maximoCarga : boolean = false;
  formatInvalid: boolean = false;
  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {}

  onFileSelected(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    this.maximoCarga = false;
    this.formatInvalid = false;

    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];
        const fileType = file.type;
        const fileSize = file.size / (1024 * 1024);
        if (fileSize > 3) {
          this.maximoCarga = true;
          return;
        }
        if (fileType.startsWith('image/') || fileType === 'application/pdf') {
          // Llamamos a la función asíncrona que manejará la carga del archivo
          this.readFileAsync(file, fileType).then(() => {
            console.log('La carga del archivo ha finalizado.');
          });
        } else {
          this.formatInvalid = true;
        }
      }
    }
  }

  // Función asíncrona que devuelve una Promesa y espera a que se complete la carga del archivo
  private async readFileAsync(file: File, fileType: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      this.uploading = true;

      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          this.percentLoaded = Math.round((e.loaded / e.total) * 100);
        }
      };

      reader.onload = () => {
        this.selectedImage = reader.result as string;
        this.control.setValue(reader.result as string);
        this.cdr.detectChanges();
        this.documentosCargados.push({
          fileName: file.name.trim(),
          file,
          imagenSeleccionada: this.selectedImage
        })
        this.File.emit(this.documentosCargados);
        this.hasDocumentUrl = true;
        this.uploading = false;
        resolve(); // Resolvemos la promesa cuando la carga se completa
      };

      reader.onerror = () => {
        reject(new Error('Error al leer el archivo')); // Rechazamos la promesa en caso de error
      };

      if (fileType.startsWith('image/')) {
        reader.readAsDataURL(file);
      } else if (fileType === 'application/pdf') {
        reader.readAsArrayBuffer(file);
        reader.onloadend = () => {
          const base64String = btoa(
            new Uint8Array(reader.result as ArrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );
          this.selectedImage = `data:application/pdf;base64,${base64String}`;
          this.control.setValue(this.selectedImage);
          this.cdr.detectChanges();
          resolve(); // Resuelve la promesa después de la conversión
        };
      }
    });
  }

  onImageOpen(event: Event, doc: any) {
    if (this.isDisabled) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    // Obtener el tipo de archivo de la URL o del nombre del archivo
    const fileType = doc.fileName.split('.').pop()?.toLowerCase();
    // Función genérica para abrir contenido en una nueva ventana
    const openInNewWindow = (content: string, isImage: boolean) => {
      const newWindow = window.open();
      if (!newWindow) {
        console.error('Failed to open new window');
        return;
      }

      if (isImage) {
        const img = new Image();
        img.src = content;
        newWindow.document.body.appendChild(img);
      } else {
        const iframe = document.createElement('iframe');
        iframe.src = content;
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
        newWindow.document.body.appendChild(iframe);
      }
    };

    // Función para crear una URL de Blob a partir de un ArrayBuffer de PDF
    const createPdfUrlFromArrayBuffer = (arrayBuffer: ArrayBuffer) => {
      try {
        const pdfBlob = new Blob([arrayBuffer], { type: 'application/pdf' });
        return URL.createObjectURL(pdfBlob);
      } catch (error) {
        console.error('Error al crear la URL del PDF:', error);
        return null;
      }
    };

    // Lógica para manejar archivos PDF
    if (fileType === 'pdf') {
      const pdfContent =
      createPdfUrlFromArrayBuffer(doc.imagenSeleccionada as unknown as ArrayBuffer)

      if (pdfContent) {
        openInNewWindow(pdfContent, false);
      } else {
        console.error('No hay datos para abrir el PDF');
      }
      return;
    }

    // Lógica para manejar imágenes
    const imageContent = doc.imagenSeleccionada

    if (imageContent) {
      openInNewWindow(imageContent as string, true);
    } else {
      console.error('No hay datos para abrir la imagen');
    }
  }

  onImageDelete(event: Event, doc: any) {
    if (this.isDisabled) {
      return;
    }
    this.documentosCargados = this.documentosCargados.filter((elem: any) => elem.fileName !== doc.fileName);
      event.stopPropagation();
      event.preventDefault();
      this.cdr.detectChanges();
      this.showDelete = false;
      this.File.emit(this.documentosCargados);
  }


  isControlInvalid(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }
}
