import { ChangeDetectionStrategy, model, Component,} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {CommonModule, JsonPipe} from '@angular/common';
import { FormsModule, Validators  } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {MatSelect, MatSelectModule} from "@angular/material/select";

@Component({
  selector: 'soph-modal-bloqueo-horario',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule, ReactiveFormsModule,
    JsonPipe,
    MatSelectModule
  ],
  templateUrl: './modal-bloqueo-horario.component.html',
  styleUrl: './modal-bloqueo-horario.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalBloqueoHorarioComponent {
  selected = model<Date | null>(null);
  blockForm: FormGroup;
  selectingStart = true;

  selectedDate: Date | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
  isSelectingStart: boolean = true;
  times: string[] = [];
  horaInicio : string | null = null;
  horaTermino: string | null = null;
  motivo: string | null = null;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalBloqueoHorarioComponent>
  ) {
    this.blockForm = this.fb.group({
      start: [null, Validators.required],
      end: [null, Validators.required],
      startTime: [null, Validators.required],
      endTime: [null, Validators.required],
      reason: [null, Validators.required]
    });

  }

  ngOnInit(): void {
    this.generateTimeOptions();
  }

  generateTimeOptions(): void {
    const inicioDia = 9; // Hora de inicio (09:00 AM)
    const terminoDia = 22; // Hora de fin (10:00 PM)
    const intervalo = 20; // Intervalo en minutos

    for (let h = inicioDia; h <= terminoDia; h++) {
      for (let m = 0; m < 60; m += intervalo) {
        const hour = h < 10 ? `0${h}` : h;
        const minute = m < 10 ? `0${m}` : m;
        this.times.push(`${hour}:${minute}`);
      }
    }
  }

  getRangeData(): void {
    const startDate = this.blockForm.get('start')?.value;
    const endDate = this.blockForm.get('end')?.value;

    console.log('Start Date:', startDate);
    console.log('End Date:', endDate);

    // Aquí puedes usar estos valores como desees, por ejemplo, enviarlos a un servidor o usarlos en el modal
  }

  bloquearHorario(): void {
    const data = {
      startDate: this.blockForm.get('start')?.value,
      endDate: this.blockForm.get('end')?.value,
      startTime: this.blockForm.get('startTime')?.value,
      endTime: this.blockForm.get('endTime')?.value,
      reason: this.blockForm.get('reason')?.value
    };
    this.dialog.closeAll();
    console.log('DATA MODAL:', data);
  }

}
