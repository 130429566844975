import { CommonModule, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { HeaderSophiaComponent } from '../../header-sophia/header-sophia.component';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ModalErrorGeneralComponent } from '../../../modal-error-general/modal-error-general.component';
import { Router } from '@angular/router';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  NativeDateAdapter,
  provideNativeDateAdapter,
} from '@angular/material/core';
import localeEs from '@angular/common/locales/es-CL';
import { SelectFormFieldComponent } from '../../../../../shared/components/select-form-field/select-form-field.component';
import { RangoHorarioConsultaService } from '../../../../../shared/services/rango-horario-consulta.service';
import { MatDialog } from '@angular/material/dialog';
import { CrearNuevaConsultaService } from '../../../../../shared/services/crear-nueva-consulta.service';
import { ObtenerInstitucionesService } from '../../../../../shared/services/obtener-instituciones.service';
import { IDataCentrosMedico } from '../../../../../shared/interfaces/centros-medicos.interface';
import { ModalNuevoAgendamientoCreadoComponent } from '../../../../../shared/components/modal-nuevo-agendamiento-creado/modal-nuevo-agendamiento-creado.component';
import { ErrorMessages } from '../../../../../shared/interfaces/errorMesagges.interface';
import { IdDireccionSeleccionadaSignalService } from '../../../../../shared/services/id-direccion-seleccionada-signal.service';
import { ShareDataService } from '../../../../../shared/services/share-data.service';
import { registerLocaleData } from '@angular/common';
import { Subscription } from 'rxjs';
import { IDataPacienteForm } from '../../../../../shared/interfaces/paciente-form.interface';
import { ReqNuevoAgendamiento } from '../../../../../shared/interfaces/nueva-consulta.interface';
import { FichaClinicaStore } from '../../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { AppointmentStatus } from '../../../../../shared/enums';

registerLocaleData(localeEs);
interface ISelectOption {
  value: string;
}

export class CustomDateAdapter extends NativeDateAdapter {
  // Cambia el primer día de la semana a lunes (1)
  override getFirstDayOfWeek(): number {
    return 1; // 0: Domingo, 1: Lunes, etc.
  }
}

@Component({
  selector: 'soph-ingreso-hora-fecha',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    NgIf,
    HeaderSophiaComponent,
    MatCardModule,
    MatAutocompleteModule,
    MatCardModule,
    MatDatepickerModule,
    SelectFormFieldComponent,
    MatNativeDateModule,
  ],
  providers: [
    provideNativeDateAdapter(),
    { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  templateUrl: './ingreso-hora-fecha.component.html',
  styleUrl: './ingreso-hora-fecha.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IngresoHoraFechaComponent implements OnInit, OnDestroy {
  /*GET CAMPOS FORM*/
  get hora(): FormControl {
    return this.formHoraFecha.get('horaSeleccionada') as FormControl;
  }
  listDirecciones: any;
  horaSelectOptions: ISelectOption[] = [];
  /*selected = model<Date | null>(null);*/
  selected: Date | null = null;

  nuevoAgendamiento: any;
  minDate: Date = new Date();
  formDataRegistroPaciente: IDataPacienteForm = {} as IDataPacienteForm;
  formHoraFecha: FormGroup;
  institucionesOptions: IDataCentrosMedico[] = [];
  nombreInstitucionSeleccionada: string = '';
  direccion: string | null = null;
  idPaciente: any;
  idAgendamiento: any;
  subs = new Subscription();
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private serviceHoraFecha: RangoHorarioConsultaService,
    private nuevoConsultaService: CrearNuevaConsultaService,
    private ObtenerInstitucionesService: ObtenerInstitucionesService,
    private IdDireccionSignal: IdDireccionSeleccionadaSignalService,
    private ShareDataService: ShareDataService,
    private readonly _fichaClinicaStore: FichaClinicaStore
  ) {
    effect(() => {
      this.direccion = this.IdDireccionSignal.getDireccionSeleccionada()();
    });
    this.formHoraFecha = this.fb.group({
      fechaSeleccionada: ['', Validators.required],
      horaSeleccionada: ['', Validators.required],
    });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.rangoHorario();
    this.getInstituciones();
    const sub = this.ShareDataService.getFormData().subscribe((resp) => {
      this.formDataRegistroPaciente = resp;
    });
    this.subs.add(sub);
  }

  onDateChange(event: Date): void {
    if (event) {
      // Obtener los componentes de la fecha
      const dia = String(event.getDate()).padStart(2, '0');
      const mes = String(event.getMonth() + 1).padStart(2, '0');
      const año = event.getFullYear();

      // Formatear la fecha en formato YYYY/MM/DD
      const formattedDate = `${año}-${mes}-${dia}`;
      this.formHoraFecha.get('fechaSeleccionada')?.setValue(formattedDate);

      this.selected = event;
    }
  }

  public rangoHorario() {
    this.serviceHoraFecha.getRangoHora().subscribe({
      next: (resp) => {
        this.horaSelectOptions = resp.data.map((hora: string) => ({
          value: hora,
        }));
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent); // Muestra el modal de error
      },
    });
  }

  public getInstituciones() {
    this.ObtenerInstitucionesService.getInstituciones().subscribe({
      next: (resp) => {
        this.institucionesOptions = resp.data;
      },
      error: (error) => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }

  public armadoRequest(formValues: { [key: string]: any }) {
    let formDataRegistroPaciente = this.formDataRegistroPaciente || {};
    /* se agregan los campos del formulario
    fecha y hora al obj del formulario datos paciente*/
    formDataRegistroPaciente = {
      ...formDataRegistroPaciente,
      ...formValues,
    };
    /*se formatea el valor tipoAtencion a upperCase*/
    if (formDataRegistroPaciente.tipoAtencion) {
      const tipoAtencion = formDataRegistroPaciente.tipoAtencion.toLowerCase();
      if (tipoAtencion === 'presencial (centro de salud)') {
        formDataRegistroPaciente.tipoAtencion = 'PRESENCIAL';
      }
      if (tipoAtencion === 'visita domiciliaria') {
        formDataRegistroPaciente.tipoAtencion = 'DOMICILIARIA';
      } else {
        formDataRegistroPaciente.tipoAtencion =
          formDataRegistroPaciente.tipoAtencion.toUpperCase();
      }
    }
    /*se rescata el nomre de la institucion segun el idInstitucion*/
    if (formDataRegistroPaciente.institucion) {
      const institucionSeleccionada = this.institucionesOptions.find(
        (institucion) =>
          institucion.idCentroMedico === formDataRegistroPaciente.institucion
      );

      if (institucionSeleccionada) {
        this.nombreInstitucionSeleccionada = institucionSeleccionada.nombre;
      } else {
        return;
      }
    }
    const requestData: ReqNuevoAgendamiento = {
      apellidos: formDataRegistroPaciente.apellidos,
      correoElectronico: formDataRegistroPaciente.email,
      fechaAtencion: formDataRegistroPaciente.fechaSeleccionada,
      horaAtencion: formDataRegistroPaciente.horaSeleccionada,
      idPrevision: formDataRegistroPaciente.prevision,
      idTipoDocumento: formDataRegistroPaciente.tipoDocumento,
      nombres: formDataRegistroPaciente.nombre,
      numeroDocumento:
        formDataRegistroPaciente.numeroDocumento ||
        formDataRegistroPaciente.numeroPasaporte ||
        formDataRegistroPaciente.numeroDocumentoOtros,
      telefono: formDataRegistroPaciente.numero,
      tipoAtencion: formDataRegistroPaciente.tipoAtencion,
    };
    if (formDataRegistroPaciente.direccion)
      requestData.idDireccionGoogle = formDataRegistroPaciente.direccion;
    if (formDataRegistroPaciente.institucion)
      requestData.idCentroMedico = formDataRegistroPaciente.institucion;
    if (formDataRegistroPaciente.link)
      requestData.link = formDataRegistroPaciente.link;

    this.ShareDataService.updateRequestData(requestData);
    this.nuevAConsulta(requestData);
  }
  private nuevAConsulta(req: ReqNuevoAgendamiento) {
    this.nuevoConsultaService.postNuevaConsulta(req).subscribe({
      next: (resp) => {
        this.nuevoAgendamiento = resp;
        if (resp) {
          this._fichaClinicaStore.updateStore({
            ...this._fichaClinicaStore.values,
            idAgendamiento: resp.data.idAgendamiento,
            idPaciente: resp.data.idPaciente,
            estado: AppointmentStatus.AGENDADA,
          });

          this.ShareDataService.setIdPaciente(resp.data.idPaciente);

          this.ShareDataService.setIdAgendamiento(resp.data.idAgendamiento);

          const institucion = this.institucionesOptions.find((inst) => {
            return inst.idCentroMedico === req.idCentroMedico;
          });

          this.dataModalNuevoAgendamiento(
            req.nombres,
            req.apellidos,
            institucion?.nombre || '',
            req.fechaAtencion,
            req.horaAtencion,
            req.tipoAtencion,
            req.link || '',
            this.direccion || ''
          );
        }
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }

  public dataModalNuevoAgendamiento(
    nombres: string,
    apellidos: string,
    institucion: string,
    fechaSelec: string,
    horaSelec: string,
    tipoConsultaSelec: string,
    link: string,
    direccion: string
  ) {
    this.dialog.open(ModalNuevoAgendamientoCreadoComponent, {
      data: {
        nombres: nombres,
        apellidos: apellidos,
        institucion: institucion,
        fechaSelec: fechaSelec,
        horaSelec: horaSelec,
        tipoConsultaSelec: tipoConsultaSelec,
        link: link,
        direccion: direccion,
      },
    });
  }

  private getCampoValorFom() {
    const forcampoValor: { [key: string]: any } = {};
    Object.keys(this.formHoraFecha.controls).forEach((key) => {
      const control = this.formHoraFecha.get(key);
      if (control) {
        forcampoValor[key] = control.value;
      }
    });
    return forcampoValor; // Retorna obj nombreCampo: valorCampo
  }

  async onSubmit() {
    if (this.formHoraFecha.valid) {
      const formValues = this.getCampoValorFom();
      this.armadoRequest(formValues);
    } else {
      return;
    }
  }
  errorMessages(): ErrorMessages | null {
    if (this.hora.hasError('required') && this.hora.touched) {
      return { required: 'Selecciona la fecha de la consulta', pattern: '' };
    }
    return null; // No hay errores
  }

  public volverFormDatosPaciente() {
    return this.router.navigate(['/ingreso'], {
      state: {
        formDataRegistroPaciente: this.formDataRegistroPaciente,
        isClicked: true,
      },
    });
  }
}
