<div class="col-md-12 container">
  <soph-header-sophia></soph-header-sophia>

  <div class="row">
    <div class="container1">
      <div class="column left">
        <img src="assets/icons/arrow_back.svg" >
        <h2 class="subtitulos"> Volver a tu agenda</h2>
      </div>
      <div class="column right">
        <div class="col-md-4 tipo-perfil">
          <img src="assets/icons/info_ficha.svg" alt="" />
          <p class="texto-info" style="margin-bottom: 0">
            Estás como Médico General
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="container2">
      <div class="column left">
        <div>
          <button mat-fab extended (click)="irAregistro()">
            <div style="display: flex; gap: 8px;">
              <img src="assets/icons/white-calendar.svg" >
              <p class="style-button-nueva-consulta">Nueva consulta
              </p>
              <img src="assets/icons/arrow.svg" >
            </div>
          </button>
        </div>
      </div>
      <div class="column center">
        <h1 style="margin-left: 15px;">Ficha Clínica</h1>
      </div>
      <div class="column right">
        <div
          class="col-md-4 estilos-fecha-hora"
          style="width: 100%; justify-content: flex-end"
        >
          <img src="assets/icons/calendar_ficha.svg" alt="" />
          <p class="style-fecha-hora">{{ fechaNuevaConsulta }}</p>
          <img src="assets/icons/clock_ficha.svg" alt="" />
          <p class="style-fecha-hora">{{ horaNuevaConsulta }}</p>
        </div>
      </div>
    </div>

    <div class="column right">
      <div class="chip-tipo-consulta"
      [ngClass]="{'presencial': tipoAtencion === presecial, 'telemedicina': tipoAtencion === telemedicina,
        'domiciliaria': tipoAtencion === domiciliaria
       }">
        <div>
          <img src="assets/icons/house.svg" 
          style="display: table-cell"
          *ngIf="tipoAtencion === domiciliaria" alt="house" />
        </div>
        <div>
          <img src="assets/icons/hospital.svg"
          style="display: table-cell"
          *ngIf="tipoAtencion === presecial" alt="hospital" />
        </div>
        <div>
          <img src="assets/icons/telemedicina.svg"  
          style="display: table-cell"
          *ngIf="tipoAtencion === telemedicina" alt="telemedicina"/>
        </div>
        <p style="margin-bottom: 0">{{ tipoAtencion }}</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="Layaout-general">
      <div class="column-layaout left-layout">
        <soph-menu-lateral-info
        [nombrePaciente]="nombrePaciente"
        [apellidosPaciente]="apellidosPaciente"
        [previsionPaciente]="previsionPaciente"
        [correoPaciente]="correoPaciente"
        [telefonoPaciente]="telefonoPaciente"
        [tipoDocumentoPaciente]="tipoDocumentoPaciente"
        [valueTipoDocumentoPaciente]="valueTipoDocumentoPaciente"
        ></soph-menu-lateral-info>
      </div>
      <div class="column-layaout right-layout">
        <mat-tab-group (selectedTabChange)="onSelectedTabChange($event)">
          <mat-tab label="Identificación">
            <soph-registro></soph-registro>
          </mat-tab>
          <mat-tab label="Anamnesis">
            <soph-anamnesis></soph-anamnesis>
          </mat-tab>
          <mat-tab label="Diagnóstico">
            <soph-diagnostico></soph-diagnostico>
          </mat-tab>
          <mat-tab label="Tratamiento">
            <soph-tratamiento></soph-tratamiento>
          </mat-tab>
          <mat-tab label="Exámenes">
            <soph-examenes></soph-examenes>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
