<soph-header-sophia></soph-header-sophia>

<div class="header-container">
  <h1>Nueva consulta</h1>
  <div class="back-link">
    <mat-icon>arrow_back</mat-icon>
    <a href="javascript:void(0)">Volver a Tu Agenda</a>
  </div>
</div>

<div class="consulta-container">
  <div class="info-card">
    <img src="assets/icons/Health-1.svg" alt="" />
    <p class="alertIngreso">
      Te invitamos a completar los datos básicos necesarios para crear una nueva
      consulta a tu paciente
    </p>
  </div>

  <h2>Datos del paciente</h2>

  <form [formGroup]="consultaForm" (ngSubmit)="onSubmit()">
    <div class="contenedor-formulario">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Tipo de documento</mat-label>
        <mat-select
          formControlName="tipoDocumento"
          (selectionChange)="campoSeleccionado($event)"
        >
          <mat-option
            *ngFor="let documento of tipoDocumentoOptions"
            [value]="documento.idTipoDocumento"
            >{{ documento.nombre }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="consultaForm.get('tipoDocumento')?.hasError('required')"
        >
          Debes seleccionar el tipo de documento
        </mat-error>
      </mat-form-field>
      <div *ngIf="showCamposPassport">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>N° Pasaporte</mat-label>
          <input
            matInput
            formControlName="numeroPasaporte"
            placeholder="Número de pasaporte"
            minlength="8"
            maxlength="12"
            (input)="manejoDataPasaporte($event)"
          />
          <mat-error
            *ngIf="consultaForm.get('numeroPasaporte')?.hasError('required')"
          >
            Debes ingresar el número de pasaporte del paciente
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="showCamposDni">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>N° Documento</mat-label>
          <input
            matInput
            formControlName="numeroDocumento"
            placeholder="Número de documento"
            maxlength="12"
            minlength="8"
            (input)="manejoDataDni($event)"
          />
          <mat-error
            *ngIf="
              consultaForm.get('numeroDocumento')?.hasError('required') &&
              consultaForm.get('numeroDocumento')?.touched
            "
          >
            Debes ingresar el número de documento
          </mat-error>
          <mat-error
            *ngIf="consultaForm.get('numeroDocumento')?.hasError('minlength')"
          >
            El documento ingresado no es válido
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="showCamposOtros">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>N° Documento</mat-label>
          <input
            matInput
            formControlName="numeroDocumentoOtros"
            placeholder="Número de documento"
            maxlength="12"
            minlength="8"
            (input)="soloNumeros($event)"
            (input)="manejoDataPasaporte($event)"
          />
          <mat-error
            *ngIf="
              consultaForm.get('numeroDocumentoOtros')?.hasError('required') &&
              consultaForm.get('numeroDocumentoOtros')?.touched
            "
          >
            Debes ingresar el número de documento
          </mat-error>
          <mat-error
            *ngIf="
              consultaForm.get('numeroDocumentoOtros')?.hasError('minlength')
            "
          >
            El documento ingresado no es válido
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Nombres</mat-label>
        <input
          matInput
          formControlName="nombre"
          placeholder="Nombres"
          (keypress)="soloLetras($event)"
        />
        <mat-error
          *ngIf="
            consultaForm.get('nombre')!.hasError('required') &&
            consultaForm.get('nombre')?.touched
          "
          >Debes ingresar los nombres</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Apellidos</mat-label>
        <input
          matInput
          formControlName="apellidos"
          placeholder="Apellidos"
          (keypress)="soloLetras($event)"
        />
        <mat-error
          *ngIf="
            consultaForm.get('apellidos')!.hasError('required') &&
            consultaForm.get('apellidos')?.touched
          "
          >Debes ingresar los apellidos</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Correo electrónico</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="Correo electrónico"
          (keypress)="restringirCaracteresEspeciales($event)"
          (keydown.space)="$event.preventDefault()"
        />
        <mat-error *ngIf="email!.hasError('required') && email?.touched"
          >Por favor ingrese un correo electrónico</mat-error
        >
        <mat-error *ngIf="email!.hasError('pattern') && email?.touched">
          Por favor, ingrese un correo válido
        </mat-error>
      </mat-form-field>

      <soph-input-telephone [control]="numero" [type]="'number'">
      </soph-input-telephone>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Previsión</mat-label>
        <mat-select
          formControlName="prevision"
          (selectionChange)="campoSeleccionadoPrevision($event)"
        >
          <mat-option
            *ngFor="let prevision of tipoPrevisionOptions"
            [value]="prevision.idPrevision"
          >
            {{ prevision.nombre }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="consultaForm.get('prevision')?.hasError('required')">
          Debes seleccionar la previsión de tu paciente
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Tipo de atención</mat-label>
        <mat-select
          formControlName="tipoAtencion"
          (selectionChange)="campoSeleccionadoTipoAtencion($event)"
        >
          <mat-option
            *ngFor="let consulta of tipoConsultaOptions"
            [value]="consulta.value"
            >{{ consulta.value }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="consultaForm.get('tipoAtencion')?.hasError('required')"
        >
          Debes seleccionar el tipo de atención
        </mat-error>
      </mat-form-field>

      <div *ngIf="showCampoPresencial">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Instituciones</mat-label>
          <mat-select formControlName="institucion">
            <mat-option
              *ngFor="let inst of institucionesOptions"
              [value]="inst.idCentroMedico"
              >{{ inst.nombre }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="consultaForm.get('institucion')?.hasError('required')"
          >
            Debes seleccionar una institución
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="showCampoTelMed" class="consulta-telemedicina">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Link</mat-label>
          <input matInput formControlName="link" placeholder="link" />
          <mat-error
            *ngIf="
              consultaForm.get('link')?.hasError('required') &&
              consultaForm.get('link')?.touched
            "
            >Campo obligatorio</mat-error
          >
          <mat-error
            *ngIf="
              consultaForm.get('link')?.hasError('pattern') &&
              consultaForm.get('link')?.touched
            "
            >Introduce una url válida</mat-error
          >
        </mat-form-field>
        <div class="alert-telemedicina">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10 2.1875C5.69219 2.1875 2.1875 5.69219 2.1875 10C2.1875 14.3078 5.69219 17.8125 10 17.8125C14.3078 17.8125 17.8125 14.3078 17.8125 10C17.8125 5.69219 14.3078 2.1875 10 2.1875ZM10 5.39062C10.2009 5.39062 10.3972 5.45019 10.5643 5.56179C10.7313 5.67339 10.8614 5.83201 10.9383 6.01759C11.0152 6.20317 11.0353 6.40738 10.9961 6.60439C10.9569 6.8014 10.8602 6.98237 10.7182 7.12441C10.5761 7.26644 10.3952 7.36317 10.1981 7.40236C10.0011 7.44155 9.79692 7.42144 9.61134 7.34457C9.42576 7.26769 9.26714 7.13752 9.15554 6.9705C9.04394 6.80348 8.98438 6.60712 8.98438 6.40625C8.98438 6.13689 9.09138 5.87856 9.28184 5.68809C9.47231 5.49763 9.73064 5.39062 10 5.39062ZM11.875 14.2188H8.4375C8.27174 14.2188 8.11277 14.1529 7.99556 14.0357C7.87835 13.9185 7.8125 13.7595 7.8125 13.5938C7.8125 13.428 7.87835 13.269 7.99556 13.1518C8.11277 13.0346 8.27174 12.9688 8.4375 12.9688H9.53125V9.53125H8.90625C8.74049 9.53125 8.58152 9.4654 8.46431 9.34819C8.3471 9.23098 8.28125 9.07201 8.28125 8.90625C8.28125 8.74049 8.3471 8.58152 8.46431 8.46431C8.58152 8.3471 8.74049 8.28125 8.90625 8.28125H10.1562C10.322 8.28125 10.481 8.3471 10.5982 8.46431C10.7154 8.58152 10.7812 8.74049 10.7812 8.90625V12.9688H11.875C12.0408 12.9688 12.1997 13.0346 12.3169 13.1518C12.4342 13.269 12.5 13.428 12.5 13.5938C12.5 13.7595 12.4342 13.9185 12.3169 14.0357C12.1997 14.1529 12.0408 14.2188 11.875 14.2188Z"
              fill="#0C2447"
            />
          </svg>
          <p>
            Recuerda que debes adjuntar el link de la consulta para acceder a la
            misma.
          </p>
        </div>
      </div>

      <div *ngIf="showCamposDomicilio" class="consulta-presencial">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Dirección</mat-label>
          <input
            type="text"
            matInput
            formControlName="direccion"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayAddress"
            (optionSelected)="onSelectDireccion($event.option.value)"
          >
            <mat-option
              *ngFor="let direccion of filterDireccion | async"
              [value]="direccion"
            >
              {{ direccion }}
            </mat-option>
          </mat-autocomplete>
          <mat-error
            *ngIf="
              consultaForm.get('direccion')?.hasError('required') &&
              consultaForm.get('direccion')?.touched
            "
          >
            Debes ingresar la dirección de tu paciente
          </mat-error>
          <mat-error
            *ngIf="consultaForm.get('direccion')?.hasError('notFound')"
          >
            Dirección no encontrada.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>N° Casa o departamento</mat-label>
          <input
            matInput
            formControlName="casaDepto"
            placeholder="N° Casa o departamento"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="seccion-acciones">
      <button
        mat-flat-button
        class="btn-consulta"
        type="submit"
        [disabled]="consultaForm.invalid"
      >
        Continuar
      </button>
    </div>
  </form>
</div>
