import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { BehaviorSubject, catchError, firstValueFrom, map, of } from "rxjs";
import { Profile } from "../../interfaces/perfil.interface";
import { ApiResponse } from "../../interfaces/api.interface";
import { SessionKeys } from "../../enums";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: BehaviorSubject< Profile | undefined > = new BehaviorSubject< Profile | undefined >(undefined);

  private readonly http: HttpClient = inject( HttpClient );
  private readonly apiUrl = environment.baseURL;

  async getUserInfo() {
    let userData: Profile;
    try {
      const raw: string | null = sessionStorage.getItem( SessionKeys.USER );
      userData = raw ? JSON.parse( raw ) : {} as Profile;
      if ( !userData.idUsuario ) {
        const { data, error } = await firstValueFrom( this.http.get<ApiResponse< Profile >>(`${ this.apiUrl }perfil` ));
        if ( error || !data ) throw error;
        userData = data;
        sessionStorage.setItem( SessionKeys.USER, JSON.stringify( userData ));
      }
    } catch (error) {
      console.error("Error al obtener información del usuario:", error);
      userData = {} as Profile;
    }
    this.user.next( userData );
  }
}
