import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { Observable } from 'rxjs';
import { IRespPaciente } from '../interfaces/paciente.interface';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PacienteService {
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthenticationService
  ) {}

  obtenerPacienteAtencion(
    idPaciente: string,
    idAgendamiento: string
  ): Observable<IRespPaciente> {
    const token = this._authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set(
      'client-id',
      '4b9b9ab5b734408d915ec31751bbf114'
    );

    return this._httpClient.get<IRespPaciente>(
      `${environment.baseURL}paciente/obtener-paciente-atencion/${idPaciente}/${idAgendamiento}`,
      {
        headers,
        params,
      }
    );
  }
}
