import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import { ErrorMessages } from '../../interfaces/errorMesagges.interface';
import { CommonModule } from '@angular/common';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
@Component({
  selector: 'soph-date-picker-form-field',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule],
    providers: [
      provideNativeDateAdapter(),
    ],
  templateUrl: './date-picker-form-field.component.html',
  styleUrl: './date-picker-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerFormFieldComponent {
  @Input() control: FormControl = new FormControl();
  @Input() label!: string;
  @Input() errorMessages!: ErrorMessages;

  @Input() inputEvent?: (event: Event) => void = () => {};
  @Input() keydownEvent?: (event: KeyboardEvent) => void = () => {};
  @Input() type!: string;
  @Output() dateChange = new EventEmitter<Date>();

  constructor(
    private elementRef: ElementRef
  ) {}
  
  

  get hasError() {
    return this.control?.touched && this.control?.invalid;
  }

  get errorMessage() {
    if (!this.control) return '';
    const errors = this.control.errors;
    if (errors) {
      for (const error of Object.keys(errors) as (keyof ErrorMessages)[]) {
        if (errors[error]) {
          return this.errorMessages[error] || '';
        }
      }
    }
    return '';
  }
  ngOnInit() {
      this.control.valueChanges.subscribe((value) => {
        this.dateChange.emit(value);
      });

  }

}
