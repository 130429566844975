<section>
  <div [ngTemplateOutlet]="selected ? selected.content : null"></div>

  <button mat-icon-button class="dialog-button-left" cdkStepperPrevious>
    <mat-icon>chevron_left</mat-icon>
  </button>

  <button mat-icon-button class="dialog-button-right" cdkStepperNext>
    <mat-icon>chevron_right</mat-icon>
  </button>
</section>
