import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IRespExistePaciente } from '../../shared/interfaces/exite-paciente.interface';
import { ReqNuevoAgendamiento, RespNuevaConsulta } from '../../shared/interfaces/nueva-consulta.interface';
import { IDataHora } from '../../shared/interfaces/rango-horario.interface';
import { IRespAgendamientoByID } from '../../shared/interfaces/agendamientos.interface';

@Injectable({
  providedIn: 'root',
})
export class AgendamientosService {
  private apiUrl = environment.baseURL + 'agendamientos';

  private _httpClient = inject(HttpClient);

  getExistePaciente(numeroDocumento: string): Observable<IRespExistePaciente> {
    const params = new HttpParams().set('numeroDocumento', numeroDocumento);
    return this._httpClient.get<IRespExistePaciente>(`${this.apiUrl}/existe-paciente`, { params });
  }

  postNuevaConsulta(req: ReqNuevoAgendamiento): Observable<RespNuevaConsulta> {
    if(req.isUpdate) {
      return this._httpClient.put<RespNuevaConsulta>(`${this.apiUrl}/update/${req.idAgendamientoToUpdate}`, req);
    } else {
      return this._httpClient.post<any>(this.apiUrl, req);
    }

  }

  getRangoHora(): Observable<IDataHora> {
    return this._httpClient.get<IDataHora>(this.apiUrl + '/horarios-atencion');
  }

  getAgendamientoById(idAgendamiento: string): Observable<IRespAgendamientoByID> {
    return this._httpClient.get<IRespAgendamientoByID>(`${this.apiUrl}/${idAgendamiento}`);
  }
}
