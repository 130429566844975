import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ErrorMessages } from '../../interfaces/errorMesagges.interface';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'soph-text-area-form-field',
  standalone: true,
  imports: [    
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule],
  templateUrl: './text-area-form-field.component.html',
  styleUrl: './text-area-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAreaFormFieldComponent {
  @Input() control: FormControl = new FormControl();
  @Input() label!: string;
  @Input() errorMessages!: ErrorMessages;
  @Input() matInt! : string;
  @Input() maxLength?: number;

  @Input() inputEvent?: (event: Event) => void = () => {};
  @Input() keydownEvent?: (event: KeyboardEvent) => void = () => {};
  @Input() type!: string;

  @Output() valueOnBlur: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private elementRef: ElementRef
  ) {}

  onBlur(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    this.valueOnBlur.emit(inputElement.value)
  }

  get hasError() {
    return this.control?.touched && this.control?.invalid;
  }

  get errorMessage() {
    if (!this.control) return '';
    const errors = this.control.errors;
    if (errors) {
      for (const error of Object.keys(errors) as (keyof ErrorMessages)[]) {
        if (errors[error]) {
          return this.errorMessages[error] || '';
        }
      }
    }
    return '';
  }

  ngOnInit() {}
}
