<button
  (click)="dialogRef.close()"
  tabindex="-1"
  mat-icon-button
  matTooltip="Cerrar"
  aria-label="icon-button close"
>
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-tittle>
  <h3 style="padding-left: 50px;">Paciente</h3>
  <section class="history-360__header">
    <div class="history-360__header--div1">
      <img ngSrc="assets/icons/Avatar.svg" height="53" width="53">
      <div>
        <p>Nombre: <span>{{ historialResult.nombrePaciente }}</span></p>
        <p>Historia clínica: <span>{{ historialResult.historiaClinica }}</span></p>
      </div>
    </div>
    <div class="history-360__header--div2">
      <img ngSrc="assets/icons/Calendar.svg" height="20" width="20" alt="Logo">
      <label>Fecha de última atención: <span>{{  historialResult.fechaUltimaAtencion }}</span></label>
    </div>
  </section>
  
  <section class="history-360__bread">
    <div class="history-360__bread--blue">
      <label>Historial 360</label>
    </div>
  </section>
</div>


<mat-dialog-content>
    <section class="history-360">

      <section class="history-360__text">
        <p>Te presentamos el resumen de atención con los datos más relevantes de la última consulta del paciente.</p>
      </section>

      <section class="history-360__chip">
        <div class="history-360__chip--container">

            <soph-container-chip>
              <div class="sop-title" sopTitle>
                  <label class="title">Alergias:</label>
              </div>
              @if (historialResult.alergias) {
                <ng-container sopChip>
                  @for (alergia of historialResult.alergias; track $index) {
                    <soph-information-chip [info]="alergia"/>
                  }
                </ng-container>
              }
            </soph-container-chip>

          <soph-container-chip>
            <div class="sop-title" sopTitle>
              <label class="title">Enfermedades pre-existentes:</label>
            </div>
            @if (historialResult.enfermedades) {
              <ng-container sopChip>
                @for ( enfermedad of historialResult.enfermedades; track $index ) {
                <soph-information-chip [info]="enfermedad" />
                }
              </ng-container>
            }
          </soph-container-chip>

          <soph-container-chip>
            <div class="sop-title" sopTitle>
                <label class="title">Cirugías:</label>
            </div>
            @if (historialResult.cirugias) {
              <ng-container sopChip>
                @for ( cirugia of historialResult.cirugias; track $index ) {
                  <soph-information-chip [info]="cirugia"/>
                }
              </ng-container>
            }
          </soph-container-chip>

          <soph-container-chip>
            <div class="sop-title" sopTitle>
                <label class="title">Medicamentos de uso frecuente:</label>
            </div>
            @if ( historialResult.medicamentos ) {
              <ng-container sopChip>
                @for ( medicamento of historialResult.medicamentos ; track $index) {
                  <soph-information-chip [info]="medicamento"/>
                }
              </ng-container>
            }
          </soph-container-chip>
        </div>

        <soph-container-diagnosticos>
          <div class="sop-title" sopTitleDefinitivo>
            <label class="title">Diagnósticos:</label>
            @if( historialResult.diagnosticos.definitivo ) {
              <label class="subtitle">Definitivo</label>
            }
          </div>
          @if( historialResult.diagnosticos.definitivo ) {
            <ng-container sopChipDefinitivo>
              @for(diagnostico of historialResult.diagnosticos.definitivo; track $index) {
                <soph-information-chip [info]="diagnostico"/>
              }
            </ng-container>
          }
          @if( historialResult.diagnosticos.presuntivo ) {
            <div class="sop-title" sopTitlePresuntivo>
                <label class="subtitle">Presuntivo</label>
            </div>
          }
          @if( historialResult.diagnosticos.presuntivo ) {
            <ng-container sopChipPresuntivo>
              @for(diagnostico of historialResult.diagnosticos.presuntivo; track $index ){
                <soph-information-chip [info]="diagnostico"/>
              }
            </ng-container>
          }
        </soph-container-diagnosticos>

        <section class="history-360__sign">
          <img ngSrc="assets/images/Logo.svg" height="30" width="115" alt="Logo">
          <label class="history-360__sign--doc-name">Daniel Carvalo</label>
          <div class="history-360__sign--container">
            <label>DNI: 96522155</label>
            <div class="div-vertical"></div>
            <label>CMP: 0944300 </label>
          </div>
          <div class="div-horiz"></div>
          
            <label class="history-360__sign--specialist">Ginecología y obstetricia</label>
          
        </section>

      </section>
    </section>
</mat-dialog-content>
