<div class="col-md-12 container">
  <soph-header-sophia></soph-header-sophia>

  <div class="row">
    <div class="container1">
      <div class="column left">
        <h2 class="subtitulos">< Volver a tu agenda</h2>
      </div>
      <div class="column right">
        <div class="col-md-4 tipo-perfil">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10 2.1875C5.69219 2.1875 2.1875 5.69219 2.1875 10C2.1875 14.3078 5.69219 17.8125 10 17.8125C14.3078 17.8125 17.8125 14.3078 17.8125 10C17.8125 5.69219 14.3078 2.1875 10 2.1875ZM10 5.39063C10.2009 5.39063 10.3972 5.45019 10.5643 5.56179C10.7313 5.67339 10.8614 5.83201 10.9383 6.01759C11.0152 6.20317 11.0353 6.40738 10.9961 6.60439C10.9569 6.8014 10.8602 6.98237 10.7182 7.12441C10.5761 7.26644 10.3952 7.36317 10.1981 7.40236C10.0011 7.44155 9.79692 7.42144 9.61134 7.34457C9.42576 7.2677 9.26714 7.13752 9.15554 6.9705C9.04394 6.80348 8.98438 6.60712 8.98438 6.40625C8.98438 6.13689 9.09138 5.87856 9.28185 5.68809C9.47231 5.49763 9.73064 5.39063 10 5.39063ZM11.875 14.2188H8.4375C8.27174 14.2188 8.11277 14.1529 7.99556 14.0357C7.87835 13.9185 7.8125 13.7595 7.8125 13.5938C7.8125 13.428 7.87835 13.269 7.99556 13.1518C8.11277 13.0346 8.27174 12.9688 8.4375 12.9688H9.53125V9.53125H8.90625C8.74049 9.53125 8.58152 9.4654 8.46431 9.34819C8.3471 9.23098 8.28125 9.07201 8.28125 8.90625C8.28125 8.74049 8.3471 8.58152 8.46431 8.46431C8.58152 8.3471 8.74049 8.28125 8.90625 8.28125H10.1563C10.322 8.28125 10.481 8.3471 10.5982 8.46431C10.7154 8.58152 10.7813 8.74049 10.7813 8.90625V12.9688H11.875C12.0408 12.9688 12.1997 13.0346 12.3169 13.1518C12.4342 13.269 12.5 13.428 12.5 13.5938C12.5 13.7595 12.4342 13.9185 12.3169 14.0357C12.1997 14.1529 12.0408 14.2188 11.875 14.2188Z"
              fill="#0C2447"
            />
          </svg>
          <p class="texto-info">Estás como Médico General</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="container2">
      <div class="column left">
        <div>
          <button mat-fab extended (click)="irAregistro()">
            <mat-icon>calendar_month</mat-icon>
            Nuevo agendamiento
          </button>
        </div>
      </div>
      <div class="column center">
        <h1>Ficha Clínica</h1>
      </div>
      <div class="column right">
        <div
          class="col-md-4 estilos-fecha-hora"
          style="width: 100%; justify-content: flex-end"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clip-path="url(#clip0_1301_89033)">
              <path
                d="M14.625 2.8125H3.375C2.44302 2.8125 1.6875 3.56802 1.6875 4.5V14.625C1.6875 15.557 2.44302 16.3125 3.375 16.3125H14.625C15.557 16.3125 16.3125 15.557 16.3125 14.625V4.5C16.3125 3.56802 15.557 2.8125 14.625 2.8125Z"
                stroke="#0C2447"
                stroke-width="1.4"
                stroke-linejoin="round"
              />
              <path
                d="M10.4062 9C10.8722 9 11.25 8.62224 11.25 8.15625C11.25 7.69026 10.8722 7.3125 10.4062 7.3125C9.94026 7.3125 9.5625 7.69026 9.5625 8.15625C9.5625 8.62224 9.94026 9 10.4062 9Z"
                fill="#0C2447"
              />
              <path
                d="M13.2188 9C13.6847 9 14.0625 8.62224 14.0625 8.15625C14.0625 7.69026 13.6847 7.3125 13.2188 7.3125C12.7528 7.3125 12.375 7.69026 12.375 8.15625C12.375 8.62224 12.7528 9 13.2188 9Z"
                fill="#0C2447"
              />
              <path
                d="M10.4062 11.8125C10.8722 11.8125 11.25 11.4347 11.25 10.9688C11.25 10.5028 10.8722 10.125 10.4062 10.125C9.94026 10.125 9.5625 10.5028 9.5625 10.9688C9.5625 11.4347 9.94026 11.8125 10.4062 11.8125Z"
                fill="#0C2447"
              />
              <path
                d="M13.2188 11.8125C13.6847 11.8125 14.0625 11.4347 14.0625 10.9688C14.0625 10.5028 13.6847 10.125 13.2188 10.125C12.7528 10.125 12.375 10.5028 12.375 10.9688C12.375 11.4347 12.7528 11.8125 13.2188 11.8125Z"
                fill="#0C2447"
              />
              <path
                d="M4.78125 11.8125C5.24724 11.8125 5.625 11.4347 5.625 10.9688C5.625 10.5028 5.24724 10.125 4.78125 10.125C4.31526 10.125 3.9375 10.5028 3.9375 10.9688C3.9375 11.4347 4.31526 11.8125 4.78125 11.8125Z"
                fill="#0C2447"
              />
              <path
                d="M7.59375 11.8125C8.05974 11.8125 8.4375 11.4347 8.4375 10.9688C8.4375 10.5028 8.05974 10.125 7.59375 10.125C7.12776 10.125 6.75 10.5028 6.75 10.9688C6.75 11.4347 7.12776 11.8125 7.59375 11.8125Z"
                fill="#0C2447"
              />
              <path
                d="M4.78125 14.625C5.24724 14.625 5.625 14.2472 5.625 13.7812C5.625 13.3153 5.24724 12.9375 4.78125 12.9375C4.31526 12.9375 3.9375 13.3153 3.9375 13.7812C3.9375 14.2472 4.31526 14.625 4.78125 14.625Z"
                fill="#0C2447"
              />
              <path
                d="M7.59375 14.625C8.05974 14.625 8.4375 14.2472 8.4375 13.7812C8.4375 13.3153 8.05974 12.9375 7.59375 12.9375C7.12776 12.9375 6.75 13.3153 6.75 13.7812C6.75 14.2472 7.12776 14.625 7.59375 14.625Z"
                fill="#0C2447"
              />
              <path
                d="M10.4062 14.625C10.8722 14.625 11.25 14.2472 11.25 13.7812C11.25 13.3153 10.8722 12.9375 10.4062 12.9375C9.94026 12.9375 9.5625 13.3153 9.5625 13.7812C9.5625 14.2472 9.94026 14.625 10.4062 14.625Z"
                fill="#0C2447"
              />
              <path
                d="M4.5 1.6875V2.8125M13.5 1.6875V2.8125"
                stroke="#0C2447"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.3125 5.625H1.6875"
                stroke="#0C2447"
                stroke-width="1.4"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1301_89033">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>17/01/2024</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M9 2.25C5.27344 2.25 2.25 5.27344 2.25 9C2.25 12.7266 5.27344 15.75 9 15.75C12.7266 15.75 15.75 12.7266 15.75 9C15.75 5.27344 12.7266 2.25 9 2.25Z"
              stroke="#0C2447"
              stroke-width="1.6"
              stroke-miterlimit="10"
            />
            <path
              d="M9 4.5V9.5625H12.375"
              stroke="#0C2447"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>11:00 am</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="Layaout-general">
      <div class="column-layaout left-layout">
        <soph-menu-lateral-info></soph-menu-lateral-info>
      </div>
      <div class="column-layaout right-layout">
        <mat-tab-group (selectedTabChange)="onSelectedTabChange($event)">
          <mat-tab label="Identificación">
            <soph-registro></soph-registro>
          </mat-tab>
          <mat-tab label="Anamnesis">
            <soph-anamnesis></soph-anamnesis>
          </mat-tab>
          <mat-tab label="Diagnóstico">
            <soph-diagnostico></soph-diagnostico>
          </mat-tab>
          <mat-tab label="Tratamiento">
            <soph-tratamiento></soph-tratamiento>
          </mat-tab>
          <mat-tab label="Exámenes">
            <soph-examenes></soph-examenes>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
