import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'soph-modal-spinner',
  standalone: true,
  imports: [MatDialogModule, MatProgressSpinnerModule],
  templateUrl: './modal-spinner.component.html',
  styleUrl: './modal-spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSpinnerComponent {}
