<div class="col-md-12 container" #scrollableDiv>
  @if (showTopButton) {
    @if (isGoUpButtonHovered) {
      <button mat-fab extended @buttonAnimation
              (mouseenter)="mouseEvent( $event, scrollButton.BUTTON_UP )"
              (mouseleave)="mouseEvent( $event, scrollButton.BUTTON_UP )"
              class="go-to-top rounded-full transition-transform duration-200"
              [style.bottom]="upBtnPosition"
              (click)="scrollToTop()">
        <mat-icon style="font-size: 20px">arrow_upward</mat-icon> Subir
      </button>
    } @else {
      <button mat-icon-button @buttonAnimation
              (mouseenter)="mouseEvent( $event, scrollButton.BUTTON_UP )"
              (mouseleave)="mouseEvent( $event, scrollButton.BUTTON_UP )"
              class="go-to-top rounded-full transition-transform duration-200"
              [style.bottom]="upBtnPosition"
              (click)="scrollToTop()">
        <mat-icon style="font-size: 20px">arrow_upward</mat-icon>
      </button>
    }
  }
  @if (showBottomButton) {
    @if (isGoDownButtonHovered){
      <button mat-fab extended @buttonAnimation
              class="go-to-bottom rounded-full transition-transform duration-200"
              [style.top]="downBtnPosition"
              (click)="scrollToBottom()"
              (mouseenter)="mouseEvent( $event, scrollButton.BUTTON_DOWN )"
              (mouseleave)="mouseEvent( $event, scrollButton.BUTTON_DOWN )">
        <mat-icon style="font-size: 20px">arrow_downward</mat-icon> Bajar
      </button>
    } @else {
      <button mat-icon-button @buttonAnimation
              class="go-to-bottom rounded-full"
              [style.top]="downBtnPosition"
              (click)="scrollToBottom()"
              (mouseenter)="mouseEvent( $event, scrollButton.BUTTON_DOWN )"
              (mouseleave)="mouseEvent( $event, scrollButton.BUTTON_DOWN )">
        <mat-icon style="font-size: 20px">arrow_downward</mat-icon>
      </button>
    }
  }
  <div #headerHeight>
    <soph-header-sophia />
  </div>

  <div class="row" #navHeight>
    <div class="container1">
      <div class="column left">
        <img src="assets/icons/arrow_back.svg">
        <a href="/calendario">Volver a Tu Agenda</a>
      </div>
      <div class="column right">
        <div class="col-md-4 tipo-perfil">
          <img src="assets/icons/info_ficha.svg" alt=""/>
          <p class="texto-info" style="margin-bottom: 0">
            Estás como Médico General
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row" #infoHeight>
    <div class="container2">
      <div class="column left">
        <div>
          <button mat-fab extended (click)="irAregistro()">
            <div style="display: flex; gap: 8px;">
              <img src="assets/icons/white-calendar.svg">
              <p class="style-button-nueva-consulta">Nueva consulta
              </p>
              <img src="assets/icons/arrow.svg">
            </div>
          </button>
        </div>
      </div>
      <div class="column center">
        <h1 style="margin-left: 15px;">Ficha Clínica</h1>
      </div>
      <div class="column right">
        <div
          class="col-md-4 estilos-fecha-hora"
          style="width: 100%; justify-content: flex-end"
        >
          <img src="assets/icons/calendar_ficha.svg" alt=""/>
          <p class="style-fecha-hora">{{ fechaNuevaConsulta }}</p>
          <img src="assets/icons/clock_ficha.svg" alt=""/>
          <p class="style-fecha-hora">{{ horaNuevaConsulta }}</p>
        </div>
      </div>
    </div>

    <div class="column right">
      <div class="chip-tipo-consulta"
           [ngClass]="{'presencial': tipoAtencion === presencial, 'telemedicina': tipoAtencion === telemedicina,
        'domiciliaria': tipoAtencion === domiciliaria
       }">
        <div>
          <img src="assets/icons/house.svg"
               style="display: table-cell"
               *ngIf="tipoAtencion === domiciliaria" alt="house"/>
        </div>
        <div>
          <img src="assets/icons/hospital.svg"
               style="display: table-cell"
               *ngIf="tipoAtencion === presencial" alt="hospital"/>
        </div>
        <div>
          <img src="assets/icons/telemedicina.svg"
               style="display: table-cell"
               *ngIf="tipoAtencion === telemedicina" alt="telemedicina"/>
        </div>
        <p style="margin-bottom: 0">{{ tipoAtencion }}</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="Layaout-general">
      <div class="column-layaout left-layout">
        <soph-menu-lateral-info
          [nombrePaciente]="nombrePaciente"
          [apellidosPaciente]="apellidosPaciente"
          [previsionPaciente]="previsionPaciente"
          [correoPaciente]="correoPaciente"
          [telefonoPaciente]="telefonoPaciente"
          [tipoDocumentoPaciente]="tipoDocumentoPaciente"
          [valueTipoDocumentoPaciente]="valueTipoDocumentoPaciente"
          (hasToSetAnamnesis)="selectedTabIndex = TabPosition.Anamnesis"
        ></soph-menu-lateral-info>
      </div>
      <div class="column-layaout right-layout">
        @if (mostrarTabsFijos) {
          <div class="floating-tab-bar" #floatingTabBar>
            @for ( tab of tabs; let idx = $index; track tab.component ) {
              <button [class.active]="idx === selectedTabIndex" (click)="selectedTabIndex = idx">
                {{ tab.label }}
              </button>
            }
          </div>
        }
        <div #seguimientoDiv></div>
          <mat-tab-group (selectedTabChange)="onSelectedTabChange($event)" [(selectedIndex)]="selectedTabIndex">
            <mat-tab label="Identificación">
              <soph-registro></soph-registro>
            </mat-tab>
            <mat-tab label="Anamnesis">
              <soph-anamnesis></soph-anamnesis>
            </mat-tab>
            <mat-tab label="Diagnóstico">
              <soph-diagnostico></soph-diagnostico>
            </mat-tab>
            <mat-tab label="Tratamiento">
              <soph-tratamiento></soph-tratamiento>
            </mat-tab>
            <mat-tab label="Exámenes">
              <soph-examenes></soph-examenes>
            </mat-tab>
          </mat-tab-group>
      </div>
    </div>
  </div>
</div>
