import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IRespAnulacionHoras } from '../interfaces/anulacion-horas.interface';


@Injectable({
  providedIn: 'root'
})
export class AnulacionHorasService {
  private apiUrl = environment.baseURL + 'atenciones/anular-atencion/' ;
  constructor(
    private httpClient: HttpClient
  ) {}

    putAnulacionHoras(idAgendamiento: string ): Observable<IRespAnulacionHoras> {
      return this.httpClient.put<IRespAnulacionHoras>(`${this.apiUrl}/${idAgendamiento}`,{});
    }
}
