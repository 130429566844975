<div class="section-inputs">
      <mat-label>{{ label }}</mat-label>
      <mat-radio-group [formControl]="control"
      (change)="onSelectionChange($event)">
        <div *ngFor="let option of options">
          <mat-radio-button [value]="option">{{ option }}</mat-radio-button>
        </div>
      </mat-radio-group>
      <mat-error *ngIf="control.hasError('required') && control.touched">
        {{ errorMessages.required }}
      </mat-error>
  </div>
  