<div class="contenedor-principal">
  <div class="header-form">
    <div class="info-card">
      <img src="assets/icons/Health-1.svg" alt="" />
      <p class="alertIngreso">
        Te invitamos a completar los datos básicos necesarios para crear un
        registro de tu paciente
      </p>
    </div>
  </div>
  <form class="form-container">
    <h3 class="tittle-registro">Datos personales del paciente</h3>

    <div class="row">
      <soph-select-form-field
        [control]="idTipoDocumento"
        [options]="tipoDocumentoOptions"
        label="Tipo de documento"
        [errorMessages]="{ required: 'Debes seleccionar un tipo de documento' }"
        valueProperty="idTipoDocumento"
        displayProperty="nombre"
      >
      </soph-select-form-field>

      <soph-input-form-field
        [control]="numeroDocumento"
        label="N° Documento"
        [errorMessages]="{ required: 'Ingrese un número de documento' }"
      >
      </soph-input-form-field>
    </div>

    <div class="row">
      <soph-input-form-field
        [control]="historiaClinica"
        label="Historia clínica"
        [errorMessages]="{ required: 'falta mensaje' }"
      >
      </soph-input-form-field>

      <soph-select-form-field
        [control]="tipoSeguro"
        [options]="tipoPrevisionOptions"
        label="Previsión"
        [errorMessages]="{
          required: 'Debes seleccionar la previsión de tu paciente'
        }"
        valueProperty="idPrevision"
        displayProperty="nombre"
      >
      </soph-select-form-field>
    </div>

    <div class="row">
      <soph-input-form-field
        [control]="nombre"
        label="Nombre"
        [errorMessages]="{ required: 'Debes ingresar los nombres' }"
        (keypress)="soloLetras($event)"
      >
      </soph-input-form-field>

      <soph-input-form-field
        [control]="apellido"
        label="Apellido"
        [errorMessages]="{ required: 'Debes ingresar los apellidos' }"
        (keypress)="soloLetras($event)"
      >
      </soph-input-form-field>
    </div>

    <div class="row">
      <soph-date-picker-form-field
        [control]="fechaNacimiento"
        label="Fecha de nacimiento"
        (dateChange)="onDateChange($event)"
        [errorMessages]="{ required: 'Debes seleccionar fecha de nacimiento' }"
      >
      </soph-date-picker-form-field>
      <soph-input-form-field
        [control]="edad"
        label="Edad"
        maxlength="2"
        minlength="1"
        (input)="soloNumeros($event)"
      >
      </soph-input-form-field>
    </div>

    <div class="row">
      <soph-select-form-field
        [control]="idSexo"
        [options]="generoOptions"
        label="Sexo"
        [errorMessages]="{ required: 'Debes seleccionar el sexo' }"
        valueProperty="idSexo"
        displayProperty="nombre"
      >
      </soph-select-form-field>

      <soph-select-form-field
        [control]="idEstadoCivil"
        [options]="estadoCivilOptions"
        label="Estado civil"
        [errorMessages]="{ required: 'Debes seleccionar el estado civil' }"
        valueProperty="idEstadoCivil"
        displayProperty="nombre"
      >
      </soph-select-form-field>
    </div>

    <div class="row">
      <soph-select-form-field
        [control]="paisNacimineto"
        [options]="PaisessOptions"
        [errorMessages]="{
          required: 'Debes seleccionar el país',
          pattern: 'Formato no válido'
        }"
        label="País de nacimiento"
        valueProperty="idPais"
        displayProperty="nombre"
      >
      </soph-select-form-field>

      <soph-select-form-field
        [control]="ciudadNacimiento"
        [options]="regionesOptions"
        label="Departamento de nacimiento"
        valueProperty="idRegion"
        [errorMessages]="{
          required: 'Debes seleccionar la ciudad de nacimiento'
        }"
        displayProperty="nombre"
      >
      </soph-select-form-field>
    </div>

    <div class="row">
      <soph-select-form-field
        [control]="idReligion"
        [options]="religionOptions"
        label="Religión"
        valueProperty="idReligion"
        displayProperty="nombre"
      >
      </soph-select-form-field>

      <soph-select-form-field
        [control]="etnia"
        [options]="etniasOptions"
        label="Etnias"
        valueProperty="idEtnia"
        displayProperty="nombre"
      >
      </soph-select-form-field>
    </div>

    <div class="row">
      <soph-input-autocomplete-formfield
        [options]="listDirecciones"
        label="Dirección"
        [control]="direccionCompleta"
        [errorMessages]="{
          required: 'Debes ingresar la dirección de tu paciente'
        }"
        (inputChanged)="loadDirecciones($event)"
        (optionSelected)="onSelectDireccion($event)"
      >
      </soph-input-autocomplete-formfield>

      <soph-input-form-field
        [control]="numeroCasa"
        label="Número de casa o departamento"
      >
      </soph-input-form-field>
    </div>

    <div class="row">
      <soph-select-form-field
        [control]="idGradoInstruccion"
        [options]="gradoInstruccionOptions"
        label="Grado de instrucción"
        [errorMessages]="{ required: 'Debes ingresar la ocupación' }"
        valueProperty="idGradoInstruccion"
        displayProperty="nombre"
      >
      </soph-select-form-field>

      <soph-input-form-field
        [control]="ocupacion"
        (keypress)="soloLetras($event)"
        [errorMessages]="{ required: 'Debes ingresar la ocupación' }"
        label="Ocupación"
      >
      </soph-input-form-field>
    </div>

    <div class="row">
      <soph-input-telephone-ficha
      [control]="numeroControl" [type]="'number'" [idInput]="'numeroControl'">
      </soph-input-telephone-ficha>

      <soph-input-form-field
        [control]="email"
        label="Correo electrónico"
        (keydown.space)="$event.preventDefault()"
        (keypress)="restringirCaracteresEspeciales($event)"
        [errorMessages]="{
          required: 'Por favor ingrese un correo electrónico',
          pattern: 'Por favor, ingrese un correo válido'
        }"
      >
      </soph-input-form-field>
    </div>
    <!-- Seccion grupo sanguineo/peso/altura -->
    <div class="form-container-grupo">
      <div class="form-section-grupo">
        <h3 class="peso-grupo">Peso (kg) y Altura (cm)</h3>
        <p class="peso-grupo-descrip">Ingresa el peso y altura del paciente</p>

        <div class="form-fields">
          <soph-input-form-field
            [control]="peso"
            label="Peso"
            (keypress)="formatPeso($event)"
            [errorMessages]="{ required: 'Debes ingresar el peso' }"
          >
          </soph-input-form-field>

          <soph-input-form-field
            [control]="altura"
            label="Altura"
            (keypress)="formatAltura($event)"
            [errorMessages]="{ required: 'Debes ingresar la altura' }"
          >
          </soph-input-form-field>
        </div>
      </div>

      <div class="form-section-grupo">
        <h3 class="peso-grupo">Grupo sanguíneo</h3>
        <p class="peso-grupo-descrip">
          Ingresa el grupo sanguíneo del paciente
        </p>
        <soph-select-form-field
          [control]="idGrupoSanguineo"
          [options]="grupoSanguineoOptions"
          [errorMessages]="{
            required: 'Debes ingresar el tipo de sangre del paciente'
          }"
          label="Grupo sanguineo"
          valueProperty="idGrupoSanguineo"
          displayProperty="nombre"
        >
        </soph-select-form-field>
      </div>
    </div>

    <!-- Seccion acompañante -->

    <div class="row">
      <h3
        class="tittle-acompanante"
        style="align-content: center; margin-bottom: 0"
      >
        ¿El paciente viene con acompañante?
      </h3>
      <soph-radio-button-form-field
        [control]="acompanante"
        [options]="['Si', 'No']"
        (selectionChange)="onAcomSelectionChange($event)"
        [errorMessages]="{ required: 'Debes ingresar la ocupación' }"
      >
      </soph-radio-button-form-field>
    </div>

    <!-- datos del acompañante -->
    <div *ngIf="showSeccionAcompanante">
      <h3 class="tittle-acompanante">Datos del acompañante</h3>
      <div class="row">
        <soph-select-form-field
          [control]="tipoDocAcom"
          [options]="tipoDocumentoOptions"
          label="Tipo de documento"
          [errorMessages]="{
            required: 'Debes seleccionar un tipo de documento'
          }"
          (selectionChange)="onDocAcomSelected($event)"
          valueProperty="idTipoDocumento"
          displayProperty="nombre"
        >
        </soph-select-form-field>

        <soph-input-form-field
          *ngIf="flagDni"
          [control]="nDniAcom"
          (keydown.space)="$event.preventDefault()"
          (input)="manejoDataDni($event)"
          (valueOnBlur)="getAcompanante($event)"
          label="N° Documento"
          maxlength="12"
          minlength="8"
          [errorMessages]="{
            required: 'Debes ingresar el número de documento',
            minlength: 'El documento ingresado no es válido'
          }"
        >
        </soph-input-form-field>

        <soph-input-form-field
          *ngIf="flagPasaporte"
          [control]="nDocAcom"
          (keydown.space)="$event.preventDefault()"
          (valueOnBlur)="getAcompanante($event)"
          label="Número pasaporte"
          (input)="manejoDataPasaporte($event)"
          maxlength="12"
          [errorMessages]="{
            required: 'Debes ingresar el número de documento',
            minlength: 'El documento ingresado no es válido'
          }"
        >
        </soph-input-form-field>

        <soph-input-form-field
          *ngIf="flagOtros"
          [control]="nDocOtrosAcom"
          (keydown.space)="$event.preventDefault()"
          (valueOnBlur)="getAcompanante($event)"
          label="N° Documento"
          (input)="manejoDataPasaporte($event)"
          maxlength="12"
          [errorMessages]="{
            required: 'Debes ingresar el número de documento',
            minlength: 'El documento ingresado no es válido'
          }"
        >
        </soph-input-form-field>
      </div>

      <div class="row">
        <soph-input-form-field
          [control]="nombreAcom"
          label="Nombres"
          (keypress)="soloLetras($event)"
          [errorMessages]="{ required: 'Debes ingresar los nombres' }"
        >
        </soph-input-form-field>

        <soph-input-form-field
          [control]="apellidoAcom"
          label="Apellidos"
          (keypress)="soloLetras($event)"
          [errorMessages]="{ required: 'Debes ingresar los apellidos' }"
        >
        </soph-input-form-field>
      </div>

      <div class="row">
        <soph-date-picker-form-field
          [control]="fechaNacimientoAcom"
          (dateChange)="onDateChangeAcom($event)"
          label="Fecha de nacimiento"
          [errorMessages]="{
            required: 'Debes seleccionar una fecha de nacimiento'
          }"
        >
        </soph-date-picker-form-field>

        <soph-input-form-field
          [control]="edadAcom"
          label="Edad"
          [errorMessages]="{
            required: 'Debes ingresar la edad del acompañante'
          }"
        >
        </soph-input-form-field>
      </div>

      <div class="row">
        <soph-input-telephone-ficha
          [control]="telefonoAcom"
          [type]="'number'"
          [idInput]="'telefonoAcom'">
        </soph-input-telephone-ficha>


        <soph-select-form-field
          [control]="parentezcoAcom"
          [options]="parentezcoOptions"
          label="Parentesco"
          [errorMessages]="{ required: 'Debes seleccionar el tipo parentesco' }"
          valueProperty="idParentesco"
          displayProperty="nombre"
        >
        </soph-select-form-field>
      </div>
    </div>

    <!-- Botón de edición -->
    <div class="button-container">
      <button
        mat-stroked-button
        (click)="onCancelarEdicion()"
        [disabled]="btnCancelarDeshabilitado()"
      >
        Cancelar
      </button>
      <button
        mat-flat-button
        type="submit"
        (click)="submitForm()"
        [disabled]="btnGuardarDisabled()"
        color="primary"
      >
        Guardar
      </button>
    </div>
  </form>
</div>
