import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { environment } from '@environments/environment';

export const authHttpInterceptor: HttpInterceptorFn = (request, next) => {
  const _authService = inject(AuthenticationService);

  const token = _authService.getToken();

  const clientId = environment.clientId;

  if (token) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
      setParams: {
        'client-id': clientId,
      },
    });
  }

  return next(request);
};
