import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  model,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ErrorMessages } from '../../interfaces/errorMesagges.interface';
import { distinctUntilChanged } from 'rxjs';

export interface Value {
  [key: string]: any;
}

@Component({
  selector: 'soph-multi-select-search-form-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  templateUrl: './multi-select-search-form-field.component.html',
  styleUrl: './multi-select-search-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectSearchFormFieldComponent<T extends Value>
  implements OnInit
{
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() values!: WritableSignal<T[]>;
  @Input() limitValues!: number;
  @Input() idField!: keyof T;
  @Input() viewField!: keyof T;
  @Input() errorMessages!: ErrorMessages;

  readonly searchCtrl = new FormControl<string>('');
  readonly currentValue = model('');

  readonly selectedValues: WritableSignal<Array<any>> = signal([]);

  readonly filteredValuesSignal = signal<Array<any>>([]);

  readonly filteredValues = computed(() => {
    this.selectedValues();

    const currentValue = this.currentValue()
      ? this.currentValue().toLowerCase()
      : '';

    const filtered = currentValue
      ? this.values().filter((value) =>
          value[this.viewField].toLowerCase().includes(currentValue)
        )
      : this.values();

    return typeof this.limitValues === 'number' && this.limitValues > 0
      ? filtered.slice(0, this.limitValues)
      : filtered.slice();
  });

  readonly placeholderTemp = signal('');

  @ViewChild('autoTrigger') autoCompleteTrigger!: MatAutocompleteTrigger;

  get hasError() {
    return this.formGroup.controls[this.controlName].invalid;
  }

  get errorMessage() {
    if (!this.formGroup.controls[this.controlName]) return '';
    const errors = this.formGroup.controls[this.controlName].errors;
    if (errors) {
      for (const error of Object.keys(errors) as (keyof ErrorMessages)[]) {
        if (errors[error]) {
          return this.errorMessages[error] || '';
        }
      }
    }
    return '';
  }

  ngOnInit() {
    this.searchCtrl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.currentValue.set(value || '');
      });

    this.formGroup.get(this.controlName)?.statusChanges.subscribe((state) => {
      if (state === 'DISABLED') {
        this.searchCtrl.disable();
      } else {
        this.searchCtrl.enable();
      }
    });

    this.initValues();

    this.formGroup
      .get(this.controlName)
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((values) => {
        if (values) {
          const filterValues = this.values().filter((value) =>
            values.includes(value[this.idField])
          );

          const filterSelectedValues = this.values().map((value) => ({
            ...value,
            selected: values.includes(value[this.idField]),
          }));

          this.values.set(filterSelectedValues);
          this.selectedValues.set(filterValues);
        } else {
          const resetValues = this.values().map((value) => ({
            ...value,
            selected: false,
          }));
          this.values.set(resetValues);
          this.selectedValues.set([]);
        }

        this.getSelectedValuesTag();
      });
  }

  private initValues() {
    const values = this.formGroup.get(this.controlName)?.value;

    if (values) {
      const filterValues = this.values().filter((value) =>
        values.includes(value[this.idField])
      );

      const filterSelectedValues = this.values().map((value) => ({
        ...value,
        selected: values.includes(value[this.idField]),
      }));

      this.values.set(filterSelectedValues);
      this.selectedValues.set(filterValues);
    }

    this.getSelectedValuesTag();
  }

  public getSelectedValuesTag(event: 'panel-closed' | null = null) {
    const values = this.selectedValues().map((value) => value[this.viewField]);

    let selectedValuesInfo = values?.[0] || '';

    if ((values.length || 0) > 1) {
      selectedValuesInfo += ` (+${(values?.length || 0) - 1} ${
        values?.length === 2 ? 'otro' : 'otros'
      })`;
    }

    this.placeholderTemp.set(selectedValuesInfo);

    if (event) {
      this.currentValue.set('');
    }
  }

  public displayFn(value: any[] | string): string {
    let displayValue: string = '';

    if (Array.isArray(value)) {
      value.forEach((value, index) => {
        displayValue = value[this.viewField];
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  public toggleSelection(item: any) {
    item.selected = !item.selected;

    if (item.selected) {
      this.selectedValues.update((values) => {
        values.push(item);
        return values;
      });
    } else {
      const i = this.selectedValues().findIndex(
        (value) => value.nombre === item.nombre
      );

      this.selectedValues().splice(i, 1);
    }

    this.formGroup
      .get(this.controlName)
      ?.setValue(this.selectedValues().map((value) => value[this.idField]));

    this.getSelectedValuesTag();

    setTimeout(() => {
      this.autoCompleteTrigger.openPanel();
    });
  }
}
