<div class="section-inputs">
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <textarea
      matInput
      [formControl]="control"
      [placeholder]="label"
      (input)="inputEvent ? inputEvent($event) : null"
      (blur)="onBlur($event)"
    >
    </textarea>
    <mat-hint class="justify-start" *ngIf="maxLength"
      >{{ matInt }} {{ " " }}</mat-hint
    >
    <mat-hint class="justify-end" *ngIf="maxLength" style="color: #0c2447">
      {{ control.value?.length || 0 }}/{{ maxLength }}</mat-hint
    >

    <mat-error *ngIf="control.hasError('required') && control.touched">
      {{ errorMessages.required }}
    </mat-error>
    <mat-error *ngIf="control.hasError('pattern') && control.touched">
      {{ errorMessages.pattern }}
    </mat-error>
    <mat-error *ngIf="control.hasError('minlength') && control.touched">
      {{ errorMessages.minlength }}
    </mat-error>
    <mat-error *ngIf="control.hasError('maxlength') && control.touched">
      {{ errorMessages.maxlength }}
    </mat-error>
  </mat-form-field>
</div>
