import { ChangeDetectionStrategy, Component } from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'soph-menu-lateral-info',
  standalone: true,
  imports: [MatCardModule, MatIconModule],
  templateUrl: './menu-lateral-info.component.html',
  styleUrl: './menu-lateral-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuLateralInfoComponent {

}
