import {ChangeDetectionStrategy, Component, input} from '@angular/core';

@Component({
  selector: 'soph-information-chip',
  standalone: true,
  imports: [],
  templateUrl: './information-chip.component.html',
  styleUrl: './information-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationChipComponent {

  public info = input.required<string>()

}
