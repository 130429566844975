import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { environment } from '@environments/environment';
import { IDataEnitas } from '../interfaces/etnias.interface';
import { IDataReligion } from '../interfaces/religion.interface';
import { IdataGrupoSanguineo } from '../interfaces/grupo-sanguineo.interface';
import { IdataOcupaciones } from '../interfaces/ocupacion.interface';
import { IdataEstadoCivil } from '../interfaces/estado-civil.interface';
import { IdataGenero } from '../interfaces/genero.interface';
import { IdataRegiones } from '../interfaces/regiones.interface';
import { IdataPaises } from '../interfaces/paises.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceFormIdentificacionService {
  private apiUrl = `${environment.baseURL}datos-personales`;
  constructor(private httpClient: HttpClient) {}

  getDatosPersonales(): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl).pipe(
      tap((data) => {
        localStorage.setItem('datosPersonales', JSON.stringify(data));
      })
    );
  }

  getEtnias(): IDataEnitas[] {
    const data = localStorage.getItem('datosPersonales') || '';
    const jsonData = JSON.parse(data);
    const etnias = jsonData.data.etnias;
    return etnias;
  }

  getReligion(): IDataReligion[] {
    const data = localStorage.getItem('datosPersonales') || '';
    const jsonData = JSON.parse(data);
    const religion = jsonData.data.religiones;
    return religion;
  }

  getGrupoSanguineo(): IdataGrupoSanguineo[] {
    const data = localStorage.getItem('datosPersonales') || '';
    const jsonData = JSON.parse(data);
    const grupoSanguineo = jsonData.data.gruposSanguineos;
    return grupoSanguineo;
  }

  geInstrucciones(): IdataOcupaciones[] {
    const data = localStorage.getItem('datosPersonales') || '';
    const jsonData = JSON.parse(data);
    const instrucciones = jsonData.data.gradosInstruccion;
    return instrucciones;
  }

  getEstadoCivil(): IdataEstadoCivil[] {
    const data = localStorage.getItem('datosPersonales') || '';
    const jsonData = JSON.parse(data);
    const estadoCivil = jsonData.data.estadosCiviles;
    return estadoCivil;
  }

  getGenero(): IdataGenero[] {
    const data = localStorage.getItem('datosPersonales') || '';
    const jsonData = JSON.parse(data);
    const sexos = jsonData.data.sexos;
    return sexos;
  }

  getRegiones(): IdataRegiones[] {
    const data = localStorage.getItem('datosPersonales') || '';
    const jsonData = JSON.parse(data);
    const regiones = jsonData.data.regiones;
    return regiones;
  }

  getPaises(): IdataPaises[] {
    const data = localStorage.getItem('datosPersonales') || '';
    const jsonData = JSON.parse(data);
    const paises = jsonData.data.paises;
    return paises;
  }
}
