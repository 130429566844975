import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef, HostListener,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatTab, MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MenuLateralInfoComponent } from './menu-lateral-info/menu-lateral-info.component';
import { RegistroComponent } from './registro/registro.component';
import { AnamnesisComponent } from './anamnesis/anamnesis.component';
import { ExamenesComponent } from './examenes/examenes.component';
import { DiagnosticoComponent } from './diagnostico/diagnostico.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HeaderSophiaComponent } from '../../common/components/header-sophia/header-sophia.component';
import { Router } from '@angular/router';
import { TratamientoComponent } from './tratamiento/tratamiento.component';
import { FichaClinicaStore } from '../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { FichaClinicaService } from '../../services/ficha-clinica.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalErrorGeneralComponent } from '../../common/modal-error-general/modal-error-general.component';
import { ShareDataService } from '../../../shared/services/share-data.service';
import { BehaviorSubject } from 'rxjs';
import { IDataPacienteForm } from '../../../shared/interfaces/paciente-form.interface';
import { CommonModule } from '@angular/common';
import { UtilServiceService } from '../../../shared/services/util-service.service';
import { animate, style, transition, trigger } from "@angular/animations";

export enum TabPosition {
  Identificacion,
  Anamnesis,
  Diagnostico,
  Tratamiento,
  Examenes,
}

export enum ScrollButton {
  BUTTON_UP = 'UP',
  BUTTON_DOWN = 'DOWN'
}

@Component({
  selector: 'soph-ficha-clinica',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    HeaderSophiaComponent,
    MenuLateralInfoComponent,
    RegistroComponent,
    AnamnesisComponent,
    DiagnosticoComponent,
    TratamientoComponent,
    ExamenesComponent
  ],
  templateUrl: './ficha-clinica.component.html',
  styleUrl: './ficha-clinica.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('buttonAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(10px)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateX(-10px)' }))
      ])
    ])
  ]
})
export class FichaClinicaComponent implements OnInit, AfterViewInit {
  readonly dialog: MatDialog = inject(MatDialog);
  dataNuevaConsulta = new BehaviorSubject<any | null>(null);
  horaNuevaConsulta!: string;
  fechaNuevaConsulta!: string;
  tipoAtencion!: string;
  public presencial = 'Presencial'
  public domiciliaria = 'Domiciliaria'
  public telemedicina = 'Telemedicina'

  existePaciente = new BehaviorSubject<any | null>(null);
  nombrePaciente!: string;
  apellidosPaciente!: string;
  historiaClinica!: string;
  dniPaciente!: string;
  correoPaciente!: string;
  telefonoPaciente!: string;
  previsionPaciente!: string;
  tipoDocumentoPaciente!: string;
  valueTipoDocumentoPaciente!: string;

  showTopButton: boolean = false;
  showBottomButton: boolean = true;
  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  @ViewChild('headerHeight') headerHeight!: ElementRef;
  @ViewChild('navHeight') navHeight!: ElementRef;
  @ViewChild('infoHeight') infoHeight!: ElementRef;
  @ViewChild('seguimientoDiv') seguimientoDiv!: ElementRef;
  @ViewChild('floatingTabBar') floatingTabBar!: ElementRef;
  mostrarTabsFijos = false;
  downBtnPosition: string = '20px';
  downBtnInitialValue: number = 0;
  upBtnPosition: string = '20px';
  upBtnLastValue: number = 30;
  scrollButton = ScrollButton;
  isGoDownButtonHovered = false;
  isGoUpButtonHovered = false;
  selectedTabIndex = 0;
  tabs = [
    { label: 'Identificación', component: 'registro' },
    { label: 'Anamnesis', component: 'anamnesis' },
    { label: 'Diagnóstico', component: 'diagnostico' },
    { label: 'Tratamiento', component: 'tratamiento' },
    { label: 'Exámenes', component: 'examenes' },
  ];


  constructor(
    private readonly _router: Router,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _fichaClinicaService: FichaClinicaService,
    private readonly _shareDataService: ShareDataService,
    private readonly _utilService: UtilServiceService
  ) {
    if (!this._fichaClinicaStore.values.idAgendamiento) {
      this.irAregistro();
    } else {
      this.getFichaClinica();
    }
  }

  ngOnInit(): void {
    this.getDataNuevaConsulta();
    this.getDataPaciente();
  }

  ngAfterViewInit(): void {
    const headerHeight: number =  this.headerHeight.nativeElement.offsetHeight;
    const navHeight: number =  this.navHeight.nativeElement.offsetHeight;
    const infoHeight: number =  this.infoHeight.nativeElement.offsetHeight;
    const viewHeight: number =  this.scrollableDiv.nativeElement.offsetHeight;
    this.downBtnInitialValue = headerHeight + navHeight + infoHeight + ( Math.trunc( viewHeight * 0.0515 ));
    this.downBtnPosition = `${ this.downBtnInitialValue }px`;
    const observer = new IntersectionObserver(([entry]) => {
      this.mostrarTabsFijos = !entry.isIntersecting;
    }, {
      threshold: 0,
    });

    observer.observe(this.seguimientoDiv.nativeElement);
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    const downBtnPositionValue: number = window.scrollY < this.downBtnInitialValue ?
      this.downBtnInitialValue - window.scrollY : 20 + ( this.floatingTabBar?.nativeElement.offsetHeight || 0 );
    const pxToBottom: number = this.scrollableDiv.nativeElement.offsetHeight - ( window.scrollY + window.visualViewport!.height );
    const upBtnPositionValue: number = pxToBottom < 20 ? this.upBtnLastValue - pxToBottom : 20 ;
    this.downBtnPosition = `${downBtnPositionValue}px`;
    this.upBtnPosition = `${ upBtnPositionValue }px`;
    this.showTopButton = window.scrollY > 350;
    this.showBottomButton = ((window.document.body.scrollHeight - window.innerHeight) - window.scrollY) > 350;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setHoversToFalse();
  }

  scrollToBottom() {
    window.scrollTo({ top: window.document.body.scrollHeight - window.innerHeight, behavior: 'smooth' });
    this.setHoversToFalse();
  }

  setHoversToFalse(): void {
    this.isGoUpButtonHovered = false;
    this.isGoUpButtonHovered = false;
  }

  mouseEvent({ type } : MouseEvent, origen: ScrollButton ) {
    if ( origen === this.scrollButton.BUTTON_UP ) this.isGoUpButtonHovered = type === 'mouseenter';
    if ( origen === this.scrollButton.BUTTON_DOWN ) this.isGoDownButtonHovered = type === 'mouseenter';
  }

  private getDataNuevaConsulta() {
    this.horaNuevaConsulta =this._fichaClinicaStore.values.horaAtencion
    this.fechaNuevaConsulta =this._fichaClinicaStore.values.fechaAtencion
    this.tipoAtencion =this._utilService.formatUpperCaseFirstLetter(this._fichaClinicaStore.values.tipoAtencion);
  }

  private getFichaClinica() {
    this._fichaClinicaService
      .info(this._fichaClinicaStore.values.idAgendamiento!)
      .subscribe({
        next: (response) => {
          if (response.data) {
            this._fichaClinicaStore.updateStore({
              ...this._fichaClinicaStore.values,
              estado: response.data.estado
            });
          }
        },
        error: () => {
          this.dialog.open(ModalErrorGeneralComponent);
        },
      });
  }

  private getDataPaciente () {
    this._shareDataService.getDataExistePaciente().subscribe({
      next: (data) => {
        if(data){
          this.nombrePaciente = data.nombres;
          this.apellidosPaciente = data.apellidos;
          this.previsionPaciente = data.prevision.nombre
          this.correoPaciente = data.correoElectronico;
          this.telefonoPaciente = data.telefono;
          this.tipoDocumentoPaciente = data.tipoDocumento?.nombre;
          this.valueTipoDocumentoPaciente = data.numeroDocumento;

        }
      },
    });
  }

  public irAregistro() {
    const vaciarAgendamiento: IDataPacienteForm = {
      apellidos: '',
      casaDepto: '',
      direccion: '',
      direccionCompleta: '',
      email: '',
      fechaSeleccionada: '',
      horaSeleccionada: '',
      institucion: '',
      link: '',
      nombre: '',
      numero: '',
      numeroDocumento: '',
      numeroDocumentoOtros: '',
      numeroPasaporte: '',
      prevision: '',
      tipoAtencion: '',
      tipoDocumento: '',
    };
    this._shareDataService.setFormData(vaciarAgendamiento);
    this._router.navigate(['/ingreso'], { replaceUrl: true });
  }

  public onSelectedTabChange(event: MatTabChangeEvent) {
    const { index } = event;

    this._fichaClinicaStore.updateStore({
      ...this._fichaClinicaStore.values,
      tabActualSeleccionado: index,
    });
  }

  protected readonly TabPosition = TabPosition;
}
