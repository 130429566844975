import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DialogRef } from '@angular/cdk/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import {DateTime} from 'luxon';
import { DesbloqueoHorarioService } from '../../../../shared/services/desbloqueo-horario.service';
import { ShareDataService } from '../../../../shared/services/share-data.service';
import { IRespDesbloqueo } from '../../../../shared/interfaces/desbloqueoHorario.interface';
import { IRespDetalleNuevaConsulta } from '../../../../shared/interfaces/tratamiento/detalle-nueva-consulta.interface';

@Component({
  selector: 'soph-detalle-desbloqueo-horario',
  standalone: true,
  imports: [MatButtonModule, CommonModule, MatDialogModule,
          MatIcon],
  templateUrl: './detalle-desbloqueo-horario.component.html',
  styleUrl: './detalle-desbloqueo-horario.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetalleDesbloqueoHorarioComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IRespDetalleNuevaConsulta,
    private readonly _desbloqueoConsultaService: DesbloqueoHorarioService,
    private _refreshCalendar: ShareDataService,
  ) {}

    readonly dialog = inject(MatDialog);
    public readonly dialogRef = inject(DialogRef);
    desHorario : IRespDesbloqueo | null = null;
    fechaInicio!: string;
    fechaFin!: string;
    horaInicio!: string;
    horaFin!: string;
  ngOnInit(): void {
    this.data;
    this.setFecha();
  }

  setFecha() {
    const fechaAtencion = this.data.evento.start.split('T')[0];
    const fechaTermino = this.data.evento.end.split('T')[0];
    const horaAtencion = this.data.evento.start.split('T')[1].split('.')[0].split(':');
    const horaTermino = this.data.evento.end.split('T')[1].split('.')[0].split(':');

    this.fechaFin = this.setDDMMYYYY(fechaTermino);
    this.fechaInicio = this.setDDMMYYYY(fechaAtencion);
    this.horaInicio = `${horaAtencion[0]}:${horaAtencion[1]}`;
    this.horaFin = `${horaTermino[0]}:${horaTermino[1]}`;
  }

  setDDMMYYYY(date: string) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  }

    desbloqueoHorario() {
      if(this.data.idAgendamiento){
        this._desbloqueoConsultaService.deleteDesbloqueoHorario(this.data.idAgendamiento)
        .subscribe ({ next: (resp) => {
          this.desHorario = resp;
          this._refreshCalendar.refreshCalendar()
        }})
        this.dialogRef.close();
      }
    }
}
