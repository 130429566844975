import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import intlTelInput from 'intl-tel-input';
import { UserEdirUseCaseService } from '../../services/user-edir-use-case.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';

interface ErrorMessages {
  required: string;
  pattern: string;
}

@Component({
  selector: 'soph-input-telephone-ficha',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
  ],
  templateUrl: './input-telephone-ficha.component.html',
  styleUrls: ['./input-telephone-ficha.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTelephoneFichaComponent implements OnInit, AfterViewInit {
  @Input() control: FormControl = new FormControl();
  @Input() label = 'Número de contacto';
  @Input() error = 'Campo requerido *';
  @Input() onlyNumbers = 'Debe contener un código de área + 8 dígitos';
  @Input() hintText = '';
  @Input() onInputChange!: (value: string) => void;
  @Input() type!: string;
  @Input() idInput: string = 'phone';
  telephonePrefix: string = '';


  constructor(
    private _editFormUseEditCaseService: UserEdirUseCaseService,
    private elementRef: ElementRef
  ) {}


  ngOnInit() {}

  setBandera () {
    const input = document.getElementById(this.idInput) as HTMLInputElement;

    if (input) {
      const telInput = intlTelInput(input, {
        initialCountry: 'PE',
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@19.5.6/build/js/utils.js',
        separateDialCode: true,
        nationalMode: true,
      });

      this.telephonePrefix =
        telInput.getSelectedCountryData().dialCode!;
      this._editFormUseEditCaseService.
      changeTelephonePrefix(this.telephonePrefix);

      // Limpia espacios del valor al actualizarse
      this.control.valueChanges.subscribe((value: string | null) => {
        if (typeof value === 'string') {
          const cleanedValue = value.replace(/\s+/g, ''); // Elimina todos los espacios
          if (value !== cleanedValue) {
            this.control.setValue(cleanedValue, { emitEvent: false });
          }
        }
      });

      input.addEventListener('countrychange', () => {
        const selectedCountryData = telInput.getSelectedCountryData();
        this.telephonePrefix = selectedCountryData.dialCode!;
        this._editFormUseEditCaseService.changeTelephonePrefix(this.telephonePrefix);
        this.adjustPrefixPosition();
      });
    }
  }

  restrictNonNumericInput(event: KeyboardEvent): void {
    const allowedKeys = [
      'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter'
    ];

    if (allowedKeys.includes(event.key)) {
      return;
    }

    // Expresión regular que permite solo números
    const regex = /^[0-9]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();  // Previene la entrada de caracteres no numéricos
    }
  }
  ngAfterViewInit(): void {
    this.adjustPrefixPosition();
    this.setBandera()
  }

  adjustPrefixPosition(): void {
    const prefixElement =
      this.elementRef.nativeElement.querySelector('.telInput') as HTMLElement;
    if (prefixElement) {
      const prefixLength = this.telephonePrefix.length;
      const newLeft = 77 + prefixLength * 5;
      prefixElement.style.paddingLeft = `${newLeft}px`;
    }
  }

  handleInput(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (this.onInputChange) {
      this.onInputChange(inputValue);
    }
  }
}
