<div class="modal">
  <div class="icon-success">
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <path d="M17 3.1875C9.38387 3.1875 3.1875 9.38387 3.1875 17C3.1875 24.6161 9.38387 30.8125 17 30.8125C24.6161 30.8125 30.8125 24.6161 30.8125 17C30.8125 9.38387 24.6161 3.1875 17 3.1875ZM24.1885 12.3708L15.2635 22.9958C15.1656 23.1124 15.0438 23.2066 14.9063 23.2721C14.7688 23.3375 14.6188 23.3726 14.4666 23.375H14.4487C14.2997 23.3749 14.1525 23.3436 14.0165 23.283C13.8805 23.2223 13.7587 23.1338 13.6591 23.023L9.8341 18.773C9.73696 18.67 9.66139 18.5486 9.61184 18.416C9.5623 18.2833 9.53976 18.1421 9.54557 18.0006C9.55138 17.8591 9.58541 17.7202 9.64566 17.5921C9.70592 17.4639 9.79118 17.3491 9.89644 17.2544C10.0017 17.1597 10.1248 17.0869 10.2586 17.0405C10.3924 16.994 10.5341 16.9748 10.6754 16.9839C10.8167 16.993 10.9548 17.0302 11.0815 17.0934C11.2082 17.1566 11.321 17.2445 11.4132 17.352L14.4208 20.6935L22.5615 11.0042C22.7441 10.7931 23.0025 10.6623 23.2807 10.6401C23.559 10.6179 23.8348 10.7062 24.0485 10.8857C24.2623 11.0652 24.3968 11.3217 24.4229 11.5996C24.4491 11.8775 24.3649 12.1545 24.1885 12.3708Z" fill="#008218"/>
    </svg>
  </div>
  <div class="texto-modal">
    Tu nueva contraseña ha sido creada con éxito
  </div>
  <div class="btn-modal">
    <button mat-flat-button color="primary"
    (click)="volverAlogin()">
      Aceptar
    </button>
  </div>
</div>
