import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import { ErrorMessages } from '../../interfaces/errorMesagges.interface';

@Component({
  selector: 'soph-input-form-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './input-form-field.component.html',
  styleUrl: './input-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFormFieldComponent {
  @Input() control: FormControl = new FormControl();
  @Input() label!: string;
  @Input() errorMessages!: ErrorMessages;
  @Input() matInt! : string;
  @Input() maxLength?: number;
  /*@Input() errorMessagesForm!: ErrorMessagesForm;*/

  @Input() inputEvent?: (event: Event) => void = () => {};
  @Input() keydownEvent?: (event: KeyboardEvent) => void = () => {};
  @Input() type!: string;

  @Output() valueOnBlur: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private elementRef: ElementRef
  ) {}

  onBlur(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    this.valueOnBlur.emit(inputElement.value)
  }

  get hasError() {
    return this.control?.touched && this.control?.invalid;
  }

  get errorMessage() {
    if (!this.control) return '';
    const errors = this.control.errors;
    if (errors) {
      for (const error of Object.keys(errors) as (keyof ErrorMessages)[]) {
        if (errors[error]) {
          return this.errorMessages[error] || '';
        }
      }
    }
    return '';
  }

  ngOnInit() {}

}
