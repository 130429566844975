import { Routes } from '@angular/router';
import { LoginComponent } from './pages/session/login/login.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { NotFoundComponent } from './pages/common/not-found/not-found.component';
import { HomeComponent } from './pages/navigate/home/home.component';
import { NavLayoutComponent } from './shared/components/layouts/nav-layout/nav-layout.component';
import { FichaClinicaComponent } from './pages/navigate/ficha-clinica/ficha-clinica.component';
import { RegistroComponent } from './pages/navigate/ficha-clinica/registro/registro.component';
import { AnamnesisComponent } from './pages/navigate/ficha-clinica/anamnesis/anamnesis.component';
import { CalendarioAgendamientoComponent } from './pages/navigate/calendario-agendamiento/calendario-agendamiento.component';
import { IngresoNuevaConsultaComponent } from './pages/common/components/nuevo-agendamiento/ingreso-nueva-consulta.component';
import { CrearPasswordComponent } from './pages/session/login/crear-password/crear-password.component';
import { ModalRecuperarPasswordComponent } from './pages/session/modal-recuperar-password/modal-recuperar-password.component';
import { InputTelephoneComponent } from './shared/components/input-telephone/input-telephone.component';
import { IngresoHoraFechaComponent } from './pages/common/components/nuevo-agendamiento/ingreso-hora-fecha/ingreso-hora-fecha.component';
import { ModalNuevoAgendamientoCreadoComponent } from './shared/components/modal-nuevo-agendamiento-creado/modal-nuevo-agendamiento-creado.component';
import { TratamientoComponent } from './pages/navigate/ficha-clinica/tratamiento/tratamiento.component';
import { R } from '@angular/cdk/keycodes';

export enum ROUTES_APP {
  HOME = 'home',
  LOGIN = 'login',
  FICHA_CLINICA = 'ficha',
  REGISTRO_CONSULTA = 'registro',
  ANAMNESIS = 'anamnesis',
  CALENDARIO = 'calendario',
  NUEVA_CONSULTA = 'ingreso',
  RECUPERAR_PASSWORD = 'recuperar-pass',
  CREAR_PASSWORD = 'crear-pass',
  SELECT_HORA_FECHA_NUEVA_CONSULTA = 'hora-fecha',
  MODAL_NUEVA_CONSULTA = 'modal-prueba'
}

export const routes: Routes = [
  {
    path: '',
    redirectTo: ROUTES_APP.LOGIN,
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: ROUTES_APP.LOGIN,
        loadComponent: () => LoginComponent,
      },
      {
        path: ROUTES_APP.CREAR_PASSWORD,
        loadComponent: () => CrearPasswordComponent,
      },

      {
        path: ROUTES_APP.RECUPERAR_PASSWORD,
        loadComponent: () => ModalRecuperarPasswordComponent,
      },
    ],
  },
  {
    path: '',
    component: CalendarioAgendamientoComponent,
    children: [
      {
        path: ROUTES_APP.CALENDARIO,
        loadComponent: () => CalendarioAgendamientoComponent,
      },
    ],
  },
  {
    path: ROUTES_APP.NUEVA_CONSULTA,
    loadComponent: () => IngresoNuevaConsultaComponent,
  },
  {
    path: ROUTES_APP.SELECT_HORA_FECHA_NUEVA_CONSULTA,
    loadComponent: () => IngresoHoraFechaComponent,
  },
{
  path: ROUTES_APP.MODAL_NUEVA_CONSULTA,
  loadComponent: () => ModalNuevoAgendamientoCreadoComponent,
},
  {
    path: '',
    component: NavLayoutComponent,
    children: [
      {
        path: ROUTES_APP.HOME,
        loadComponent: () => HomeComponent,
      },
      {
        path: ROUTES_APP.FICHA_CLINICA,
        loadComponent: () => FichaClinicaComponent,
      },
      {
        path: ROUTES_APP.ANAMNESIS,
        loadComponent: () =>
          import(
            '../app/pages/navigate/ficha-clinica/anamnesis/anamnesis.component'
          ).then((m) => AnamnesisComponent),
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => NotFoundComponent,
  },
];
