import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {IRespEnlace} from "../interfaces/reenvio-enlace.interface";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReenvioEnlacePasswordService {
  private apiUrl = environment.baseURL + 'auth/validar-restablecer-pass';

  constructor(private httpClient: HttpClient) { }

  getEnlacePass(email: string): Observable<IRespEnlace> {
    const params =new
    HttpParams()
      .set('client-id', '4b9b9ab5b734408d915ec31751bbf114')
      .set('email', email)
    return this.httpClient.get<IRespEnlace>(this.apiUrl, {params});
  }
}
