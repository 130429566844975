<section class="container">
  <ng-content select="[sopTitleDefinitivo]"></ng-content>
  <div class="container__chip">
    <ng-content select="[sopChipDefinitivo]"></ng-content>
  </div>
  <div class="container-title">
    <ng-content select="[sopTitlePresuntivo]"></ng-content>
  </div>
  <div class="container__chip">
    <ng-content select="[sopChipPresuntivo]"></ng-content>
  </div>
</section>
