import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {  UserEditRepository} from '../interfaces/user.edit.repositoy'
import { UserEditModel } from '../interfaces/user-edit.interface';

@Injectable({
  providedIn: 'root'
})
export class UserEdirUseCaseService {
  private telephonePrefixSource = new BehaviorSubject<string>('');
  currentTelephonePrefix = this.telephonePrefixSource.asObservable();

  constructor(private repository: UserEditRepository) {}

  post(form: Partial<UserEditModel>, captcha: string): Observable<any> {
    return this.repository.post(form, captcha);
  }

  changeTelephonePrefix(prefix: string) {
    this.telephonePrefixSource.next(prefix);
  }
}
