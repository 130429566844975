<soph-header-sophia></soph-header-sophia>

<div class="header-container">
  <h1>{{ formDataRegistroPaciente.isUpdate ? 'Editar consulta' : 'Nueva consulta' }}</h1>
  <div class="back-link">
    <mat-icon>arrow_back</mat-icon>
    <a href="/calendario">Volver a Tu Agenda</a>
  </div>
</div>

<div class="consulta-container">
  <div class="info-card">
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5163_36881)">
        <path
          d="M16.875 9.37512H13.125C12.1304 9.37512 11.1766 9.77021 10.4733 10.4735C9.77009 11.1767 9.375 12.1306 9.375 13.1251V35.6251C9.375 36.6197 9.77009 37.5735 10.4733 38.2768C11.1766 38.98 12.1304 39.3751 13.125 39.3751H31.875C32.8696 39.3751 33.8234 38.98 34.5267 38.2768C35.2299 37.5735 35.625 36.6197 35.625 35.6251V13.1251C35.625 12.1306 35.2299 11.1767 34.5267 10.4735C33.8234 9.77021 32.8696 9.37512 31.875 9.37512H28.125"
          stroke="#0C2447"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.875 9.375C16.875 8.38044 17.2701 7.42661 17.9734 6.72335C18.6766 6.02009 19.6304 5.625 20.625 5.625H24.375C25.3696 5.625 26.3234 6.02009 27.0266 6.72335C27.7299 7.42661 28.125 8.38044 28.125 9.375C28.125 10.3696 27.7299 11.3234 27.0266 12.0267C26.3234 12.7299 25.3696 13.125 24.375 13.125H20.625C19.6304 13.125 18.6766 12.7299 17.9734 12.0267C17.2701 11.3234 16.875 10.3696 16.875 9.375Z"
          stroke="#0C2447"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.8301 24.7643H26.6037"
          stroke="#0C2447"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.2168 20.3774V29.151"
          stroke="#0C2447"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5163_36881">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <p class="alertIngreso">
      Ten en consideración el tiempo que ocupas en trasladarte entre una
      consulta y otra
    </p>
  </div>

  <h2>Datos de la consulta</h2>

  <form [formGroup]="formHoraFecha" (ngSubmit)="onSubmit()">
    <div class="contenedor-formulario">
      <div>
        <h2>Fecha</h2>
        <mat-card class="demo-inline-calendar-card">
          <mat-calendar
            [minDate]="minDate"
            [(selected)]="selectedDate"
            (selectedChange)="onDateChange($event)"
          ></mat-calendar>
        </mat-card>
        <mat-error *ngIf="!selectedDate">Debes seleccionar una fecha</mat-error>
      </div>

      <div class="section-hora">
        <h2>Hora</h2>
        <soph-select-form-field
          [control]="hora"
          [options]="horaSelectOptions"
          label="Hora"
          valueProperty="value"
          displayProperty="value"
          [errorMessages]="{
            required: 'Selecciona la hora de la consulta',
            pattern: 'Formato no válido'
          }"
          >>
        </soph-select-form-field>
      </div>
    </div>
    <div class="seccion-acciones">
      <button
        mat-flat-button
        class="btn-consulta"
        type="submit"
        [disabled]="formHoraFecha.invalid"
      >
      {{ formDataRegistroPaciente.isUpdate ? 'Actualizar' : 'Crear consulta' }}
      </button>
      <button mat-stroked-button (click)="volverFormDatosPaciente()">
        Volver
      </button>
    </div>
  </form>
</div>

