<soph-header-sophia></soph-header-sophia>

<div class="header-container">
  <p class="titulo-agenda">Tu agenda</p>
</div>

<div class="header-container2">
  <button mat-fab extended (click)="openModalNuevaConsulta()">
    <mat-icon>calendar_month</mat-icon>
    Nueva consulta
  </button>
  <div class="container-tipo-consulta">
    <div
      class="chip-tipo-consulta"
      [ngClass]="{ 'filtro-dom': filtroActivo === 'DOMICILIARIA' }"
    >
      <div style="display: flex; gap: 5px">
        <img
          src="assets/icons/house.svg"
          style="display: table-cell"
          alt="house"
        />
        <p (click)="filterTipoDomiciliaria()">Visita domiciliaria</p>
      </div>
    </div>
    <div
      class="chip-tipo-presnecial"
      [ngClass]="{ 'filtro-presc': filtroActivo === 'PRESENCIAL' }"
    >
      <div style="display: flex; gap: 5px">
        <img
          src="assets/icons/hospital.svg"
          style="display: table-cell"
          alt="house"
        />
        <p (click)="filterTipoPresencial()">Presencial</p>
      </div>
    </div>
    <div
      class="chip-tipo-telemedicina"
      [ngClass]="{ 'filtro-tel': filtroActivo === 'TELEMEDICINA' }"
    >
      <div style="display: flex; gap: 5px">
        <img
          src="assets/icons/telemedicina.svg"
          style="display: table-cell"
          alt="house"
        />
        <p (click)="filterTipoTelemedicina()">Telemedicina</p>
      </div>
    </div>

    <div
    *ngIf="flagFiltros" 
      class="chip-tipo-delete"
    >
      <div style="display: flex; gap: 5px">
        <img
        src="assets/icons/delete-filtros.svg"
          style="display: table-cell"
          alt="house"
        />
        <p   class="text-delete-filtros"
         (click)="eliminarFiltrosTipo()">Eliminar filtros</p>
      </div>
    </div>
  </div>

  <div>
    <button
      class="btn_bloqueo"
      mat-fab
      extended
      (click)="openBloqueoHorarios()"
    >
      <mat-icon style="color: #d96704 !important">lock</mat-icon>
      Bloquear horario
    </button>
  </div>
</div>

<div class="container">
  <div class="column right">
    <full-calendar [options]="calendarOptions" #calendar></full-calendar>
  </div>
</div>
