import { IDataRespAcompanante } from './../../../../shared/interfaces/existe-acompanante.interface';
import {
  IDataFormularioPaciente,
  IDataReqAcompanante,
} from './../../../../shared/interfaces/exite-paciente.interface';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  signal,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormsModule,
  ReactiveFormsModule,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConsultaPacienteRegistradoService } from '../../../../shared/services/consulta-paciente-registrado.service';
import { ObtenerPrevisionService } from '../../../../shared/services/obtener-prevision.service';
import { ObtenerTipoDocumentoService } from '../../../../shared/services/obtener-tipo-documento.service';
import { ObtenerInstitucionesService } from '../../../../shared/services/obtener-instituciones.service';
import { IDataSeguro } from '../../../../shared/interfaces/seguros.interface';
import { IDataDocumento } from '../../../../shared/interfaces/tipo-documento.interface';
import { IDataCentrosMedico } from '../../../../shared/interfaces/centros-medicos.interface';
import { distinctUntilChanged, forkJoin, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalErrorGeneralComponent } from '../../../common/modal-error-general/modal-error-general.component';
import { InputFormFieldComponent } from '../../../../shared/components/input-form-field/input-form-field.component';
import { SelectFormFieldComponent } from '../../../../shared/components/select-form-field/select-form-field.component';
import { DatePickerFormFieldComponent } from '../../../../shared/components/date-picker-form-field/date-picker-form-field.component';
import { ServiceFormIdentificacionService } from '../../../../shared/services/service-form-identificacion.service';
import { IDataEnitas } from '../../../../shared/interfaces/etnias.interface';
import { IDataReligion } from '../../../../shared/interfaces/religion.interface';
import { IdataGrupoSanguineo } from '../../../../shared/interfaces/grupo-sanguineo.interface';
import { IdataOcupaciones } from '../../../../shared/interfaces/ocupacion.interface';
import { IdataEstadoCivil } from '../../../../shared/interfaces/estado-civil.interface';
import { IdataGenero } from '../../../../shared/interfaces/genero.interface';
import { IdataRegiones } from '../../../../shared/interfaces/regiones.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  SnackInfoComponent,
  SnackInfoType,
} from '../../../../shared/components/snack-info/snack-info.component';
import { IdataPaises } from '../../../../shared/interfaces/paises.interface';
import { CommonModule } from '@angular/common';
import { RadioButtonFormFieldComponent } from '../../../../shared/components/radio-button-form-field/radio-button-form-field.component';
import { ShareDataService } from '../../../../shared/services/share-data.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IDataDireccion } from '../../../../shared/interfaces/buscar-direccion.interface';
import { BuscardorDireccionService } from '../../../../shared/services/buscardor-direccion.service';
import { InputAutocompleteFormfieldComponent } from '../../../../shared/components/input-autocomplete-formfield/input-autocomplete-formfield.component';
import { ExisteAcompananteService } from '../../../../shared/services/existe-acompanante.service';
import { IDataParentezco } from '../../../../shared/interfaces/parentezco.interface';
import { UtilServiceService } from '../../../../shared/services/util-service.service';
import { GuardadoDatosRegistroService } from '../../../../shared/services/guardado-datos-registro.service';
import { ModalConfirmCambioDatosComponent } from '../../../../shared/components/modal-confirm-cambio-datos/modal-confirm-cambio-datos.component';
import { IReqDataPaciente } from '../../../../shared/interfaces/exite-paciente.interface';
import { InputTelephoneFichaComponent } from '../../../../shared/components/input-telephone-ficha/input-telephone-ficha.component';
import {
  ModalConfirmComponent,
  ModalConfirmProps,
  ModalConfirmType,
} from '../../../../shared/components/modal-confirm/modal-confirm.component';
import moment, { Moment } from 'moment';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { PacienteService } from '../../../../shared/services/paciente.service';
import { isFinalizedStatus } from '../../../../shared/helpers/ficha-clinica.helper';

@Component({
  selector: 'soph-registro',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDividerModule,
    MatRadioModule,
    MatGridListModule,
    InputFormFieldComponent,
    SelectFormFieldComponent,
    DatePickerFormFieldComponent,
    InputTelephoneFichaComponent,
    RadioButtonFormFieldComponent,
    MatAutocompleteModule,
    InputAutocompleteFormfieldComponent,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './registro.component.html',
  styleUrl: './registro.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistroComponent {
  readonly path = 'indentificacion';
  genero = new FormControl('');
  formRegistroPaciente: FormGroup;
  formRegistroAcompanante: FormGroup;
  showSeccionAcompanante = false;

  /*GET CAMPOS FORM*/
  get idTipoDocumento(): FormControl {
    return this.formRegistroPaciente.get('idTipoDocumento') as FormControl;
  }
  get numeroDocumento(): FormControl {
    return this.formRegistroPaciente.get('numeroDocumento') as FormControl;
  }
  get historiaClinica(): FormControl {
    return this.formRegistroPaciente.get('historiaClinica') as FormControl;
  }
  get tipoSeguro(): FormControl {
    return this.formRegistroPaciente.get('tipoSeguro') as FormControl;
  }
  get nombre(): FormControl {
    return this.formRegistroPaciente.get('nombre') as FormControl;
  }
  get apellido(): FormControl {
    return this.formRegistroPaciente.get('apellido') as FormControl;
  }
  get fechaNacimiento(): FormControl {
    return this.formRegistroPaciente.get('fechaNacimiento') as FormControl;
  }
  get edad(): FormControl {
    return this.formRegistroPaciente.get('edad') as FormControl;
  }
  get idSexo(): FormControl {
    return this.formRegistroPaciente.get('idSexo') as FormControl;
  }
  get idEstadoCivil(): FormControl {
    return this.formRegistroPaciente.get('idEstadoCivil') as FormControl;
  }
  get paisNacimineto(): FormControl {
    return this.formRegistroPaciente.get('paisNacimineto') as FormControl;
  }
  get ciudadNacimiento(): FormControl {
    return this.formRegistroPaciente.get('ciudadNacimiento') as FormControl;
  }
  get idReligion(): FormControl {
    return this.formRegistroPaciente.get('idReligion') as FormControl;
  }
  get etnia(): FormControl {
    return this.formRegistroPaciente.get('etnia') as FormControl;
  }
  get direccionCompleta(): FormControl {
    return this.formRegistroPaciente.get('direccionCompleta') as FormControl;
  }
  get numeroCasa(): FormControl {
    return this.formRegistroPaciente.get('numeroCasa') as FormControl;
  }
  get idGradoInstruccion(): FormControl {
    return this.formRegistroPaciente.get('idGradoInstruccion') as FormControl;
  }
  get ocupacion(): FormControl {
    return this.formRegistroPaciente.get('ocupacion') as FormControl;
  }
  get numeroControl(): FormControl {
    return this.formRegistroPaciente.get('numero') as FormControl;
  }
  get email(): FormControl {
    return this.formRegistroPaciente.get('email') as FormControl;
  }
  get idGrupoSanguineo(): FormControl {
    return this.formRegistroPaciente.get('idGrupoSanguineo') as FormControl;
  }
  get peso(): FormControl {
    return this.formRegistroPaciente.get('peso') as FormControl;
  }
  get altura(): FormControl {
    return this.formRegistroPaciente.get('altura') as FormControl;
  }
  get acompanante(): FormControl {
    return this.formRegistroPaciente.get('acompanante') as FormControl;
  }
  get tipoDocAcom(): FormControl {
    return this.formRegistroAcompanante.get('tipoDocAcom') as FormControl;
  }
  get nDocAcom(): FormControl {
    return this.formRegistroAcompanante.get('nDocAcom') as FormControl;
  }
  get nombreAcom(): FormControl {
    return this.formRegistroAcompanante.get('nombreAcom') as FormControl;
  }
  get apellidoAcom(): FormControl {
    return this.formRegistroAcompanante.get('apellidoAcom') as FormControl;
  }
  get edadAcom(): FormControl {
    return this.formRegistroAcompanante.get('edadAcom') as FormControl;
  }
  get telefonoAcom(): FormControl {
    return this.formRegistroAcompanante.get('telefonoAcom') as FormControl;
  }
  get fechaNacimientoAcom(): FormControl {
    return this.formRegistroAcompanante.get(
      'fechaNacimientoAcom'
    ) as FormControl;
  }
  get parentezcoAcom(): FormControl {
    return this.formRegistroAcompanante.get('parentezcoAcom') as FormControl;
  }
  get nDniAcom(): FormControl {
    return this.formRegistroAcompanante.get('nDniAcom') as FormControl;
  }
  get nDocOtrosAcom(): FormControl {
    return this.formRegistroAcompanante.get('nDocOtrosAcom') as FormControl;
  }

  esEditable: boolean = false;
  pasaporteId: string = '';
  dniId: string = '';
  otrosId: string = '';

  /*DATA FORM*/
  PaisessOptions: IdataPaises[] = [];
  regionesOptions: IdataRegiones[] = [];
  generoOptions: IdataGenero[] = [];
  estadoCivilOptions: IdataEstadoCivil[] = [];
  gradoInstruccionOptions: IdataOcupaciones[] = [];
  grupoSanguineoOptions: IdataGrupoSanguineo[] = [];
  religionOptions: IDataReligion[] = [];
  etniasOptions: IDataEnitas[] = [];
  tipoPrevisionOptions: IDataSeguro[] = [];
  dataExisteAcompanante: IDataRespAcompanante | null = null;
  tipoDocumentoOptions = [
    { idTipoDocumento: '89afaff0-6e5b-4d15-ac65-47900d6d35ac', nombre: 'DNI' },
    {
      idTipoDocumento: '55823300-6b75-4245-9d18-384a3051e30d',
      nombre: 'PASAPORTE',
    },
    { idTipoDocumento: 'another-id', nombre: 'Otro Documento' },
  ];

  tipoDocuementoAcom: IDataDocumento[] = [];
  institucionesOptions: IDataCentrosMedico[] = [];
  dataExistePaciente: IDataFormularioPaciente = {} as IDataFormularioPaciente;
  parentezcoOptions: IDataParentezco[] = [];

  listDirecciones: IDataDireccion[] = [];
  filterDireccion!: Observable<string[]>;
  filteredOptionsEmptyDireccion: boolean = false;
  addresDireccionSeleccionada: any;
  idDirecSeleccionada = signal<string | null>(null);
  labelNumeroDocumento: string = 'Número documento';
  options: string[] = ['Si', 'No'];
  optionsExisteAcom: string[] = ['Si', 'No'];
  requestData: any;
  numeroDocPaciente: any; // Label dinámico
  listPrevisiones: any;
  telephonePrefix: string = '';
  guardadoDatosResp: any;
  idDireccionSeleccionada: any;
  /* idPaciente!: any;
  idAgendamiento!: any; */
  idPacient: any;
  flagPasaporte: boolean = false;
  flagDni: boolean = false;
  flagOtros = false;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,

    private obtenerPrevisionService: ObtenerPrevisionService,
    private obtenerTipoDocumentoService: ObtenerTipoDocumentoService,
    private obtenerInstitucionesService: ObtenerInstitucionesService,
    private datosPersonalesService: ServiceFormIdentificacionService,
    private snackBar: MatSnackBar,
    private ShareDataService: ShareDataService,
    private buscarDireccionService: BuscardorDireccionService,
    private ExisteAcompananteService: ExisteAcompananteService,
    private utilServiceService: UtilServiceService,
    private guardadoDatosRegistroService: GuardadoDatosRegistroService,
    private existePacienteServ: ConsultaPacienteRegistradoService,

    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _pacienteService: PacienteService
  ) {
    const formatoEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    this.formRegistroPaciente = this.fb.group({
      idTipoDocumento: ['', Validators.required],
      numeroDocumento: ['', Validators.required],
      historiaClinica: ['', Validators.required],
      tipoSeguro: ['', Validators.required],
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      fechaNacimiento: ['', Validators.required],
      edad: [''],
      idSexo: ['', Validators.required],
      idEstadoCivil: ['', Validators.required],
      paisNacimineto: ['', Validators.required],
      ciudadNacimiento: ['', Validators.required],
      idReligion: [''],
      etnia: [''],
      direccionCompleta: ['', Validators.required],
      numeroCasa: [''],
      idGradoInstruccion: [''],
      ocupacion: ['', Validators.required],
      numero: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
      ]),
      email: ['', [Validators.required, Validators.pattern(formatoEmail)]],
      idGrupoSanguineo: [''],
      peso: [''],
      altura: [''],
      acompanante: ['', Validators.required],
    });

    this.formRegistroAcompanante = this.fb.group({
      tipoDocAcom: [''],
      nDocAcom: [''],
      nDniAcom: [''],
      nDocOtrosAcom: [''],
      nombreAcom: [''],
      apellidoAcom: [''],
      fechaNacimientoAcom: [''],
      edadAcom: [''],
      parentezcoAcom: [''],
      telefonoAcom: [''],
    });

    this._fichaClinicaStore.state$
      .pipe(distinctUntilChanged())
      .subscribe((values) => {
        this.toggleFormState(isFinalizedStatus(values.estado || ''));
      });
  }

  ngOnInit(): void {
    this.cargaDeServicios();

    /* this.ShareDataService.idPaciente$.subscribe(id => {
      this.idPaciente = id;
    });

    this.ShareDataService.idAgendamiento$.subscribe(id => {
      this.idAgendamiento = id;
    }); */

    this.formRegistroPaciente.get('idTipoDocumento')?.disable();
    this.formRegistroPaciente.get('numeroDocumento')?.disable();
    this.formRegistroPaciente.get('historiaClinica')?.disable();
    this.formRegistroPaciente.get('edad')?.disable();
    this.formRegistroAcompanante.get('edadAcom')?.disable();
  }

  private toggleFormState(shouldDisable: boolean) {
    shouldDisable
      ? this.formRegistroPaciente.disable()
      : this.formRegistroPaciente.enable();
    shouldDisable
      ? this.formRegistroAcompanante.disable()
      : this.formRegistroAcompanante.enable();
  }

  /* dataFormNuevaConsulta(listPrevisiones: any) {
    this.ShareDataService.requestData$.subscribe((data) => {
      this.requestData = data;
      if (data) {
        this.dataPrecargada(data);
        this.numeroDocPaciente = this.requestData.numeroDocumento;
      }
    });
  } */

  dataPrecargada(dataAgendamiento: any) {
    if (dataAgendamiento) {
      const numeroDocumento = dataAgendamiento.numeroDocumento;
      const idTipoDocumento = dataAgendamiento.idTipoDocumento;
      this.existePacienteService(numeroDocumento, idTipoDocumento);
    }
    return;
  }

  actualizarLabelNumeroDocumento(tipoDocSeleccionado: any): void {
    const idDocDni = this.dniId;
    const idDocPasaporte = this.pasaporteId;
    if (tipoDocSeleccionado === idDocDni) {
      const nombreDocumento = 'documento';
      this.labelNumeroDocumento = `Número de ${nombreDocumento}`;
    } else if (tipoDocSeleccionado === idDocPasaporte) {
      const nombrePasaporte = 'pasaporte';
      this.labelNumeroDocumento = `Número de ${nombrePasaporte}`;
    } else {
      this.labelNumeroDocumento = 'Número de documento';
    }
  }

  /*SERVICES*/
  private cargaDeServicios(): void {
    this.getPrevisiones();
    this.getTipoDocumento();
    this.getEtnias();
    this.getReligion();
    this.getGrupoSanguineo();
    this.getEstadoCivil();
    this.getGenero();
    this.getRegiones();
    this.getPaises();
    this.getParentezco();
    this.getInstrucciones();

    if (this._fichaClinicaStore.values.idPaciente) {
      this.getPaciente(this._fichaClinicaStore.values.idPaciente);
    }
  }

  /* private llamadaService(): void {
    forkJoin([
      this.obtenerPrevisionService.getPrevision(),
      this.obtenerTipoDocumentoService.getTipoDocumento(),
      this.obtenerInstitucionesService.getInstituciones(),
      this.datosPersonalesService.getEtnias(),
      this.datosPersonalesService.getReligion(),
      this.datosPersonalesService.getGrupoSanguineo(),
      this.datosPersonalesService.geInstrucciones(),
      this.datosPersonalesService.getEstadoCivil(),
      this.datosPersonalesService.getGenero(),
      this.datosPersonalesService.getRegiones(),
      this.datosPersonalesService.getPaises(),
      this.ExisteAcompananteService.getParentezcoAcompanante(),
    ]).subscribe({
      next: (responses) => {
        this.cargaDeServicios();
      },
      error: (error: HttpErrorResponse) => {
        this.dialog.open(ModalErrorGeneralComponent); // Muestra el modal de error
      },
    });
  } */

  public getPaises() {
    this.datosPersonalesService.getPaises().subscribe({
      next: (resp) => {
        this.PaisessOptions = resp.data;
      },
    });
  }

  public getRegiones() {
    this.datosPersonalesService.getRegiones().subscribe({
      next: (resp) => {
        this.regionesOptions = resp.data;
      },
    });
  }

  public getGenero() {
    this.datosPersonalesService.getGenero().subscribe({
      next: (resp) => {
        this.generoOptions = resp.data;
      },
    });
  }

  public getEstadoCivil() {
    this.datosPersonalesService.getEstadoCivil().subscribe({
      next: (resp) => {
        this.estadoCivilOptions = resp.data;
      },
    });
  }

  public getInstrucciones() {
    this.datosPersonalesService.geInstrucciones().subscribe({
      next: (resp) => {
        this.gradoInstruccionOptions = resp.data;
      },
    });
  }

  public getGrupoSanguineo() {
    this.datosPersonalesService.getGrupoSanguineo().subscribe({
      next: (resp) => {
        this.grupoSanguineoOptions = resp.data;
      },
    });
  }

  public getEtnias() {
    this.datosPersonalesService.getEtnias().subscribe({
      next: (resp) => {
        this.etniasOptions = resp.data;
      },
    });
  }

  public getReligion() {
    this.datosPersonalesService.getReligion().subscribe({
      next: (resp) => {
        this.religionOptions = resp.data;
      },
    });
  }

  public getPrevisiones() {
    this.obtenerPrevisionService.getPrevision().subscribe({
      next: (resp) => {
        this.tipoPrevisionOptions = resp.data;
        this.listPrevisiones = resp.data;
      },
    });
  }

  public getTipoDocumento() {
    this.obtenerTipoDocumentoService.getTipoDocumento().subscribe({
      next: (resp) => {
        this.tipoDocumentoOptions = resp.data;
        this.pasaporteId =
          this.tipoDocumentoOptions.find((doc) => doc.nombre === 'Pasaporte')
            ?.idTipoDocumento || '';
        this.dniId =
          this.tipoDocumentoOptions.find((doc) => doc.nombre === 'DNI')
            ?.idTipoDocumento || '';
        this.otrosId =
          this.tipoDocumentoOptions.find((doc) => doc.nombre === 'Otro')
            ?.idTipoDocumento || '';
      },
    });
  }

  public getParentezco() {
    this.ExisteAcompananteService.getParentezcoAcompanante().subscribe({
      next: (resp) => {
        this.parentezcoOptions = resp.data;
      },
    });
  }

  public getAcompanante(numeroDocumento: string) {
    this.getNumDocAcom();
    if (!numeroDocumento) return;
    this.ExisteAcompananteService.getExisteAcompanante(
      numeroDocumento
    ).subscribe({
      next: (resp) => {
        if (resp.data) {
          this.dataExisteAcompanante = resp.data;
          this.setDataAcompanante(resp.data);
        }
      },
    });
  }

  public getPaciente(id: string) {
    this._pacienteService.obtenerPacientexId(id).subscribe({
      next: (response) => {
        const { data } = response;

        if (data) {
          this.dataPrecargada(data);
        }
      },
      error: () => {},
    });
  }

  setDataAcompanante(data: IDataRespAcompanante) {
    this.tipoDocAcom.setValue(data.idTipoDocumento);
    this.onDocAcomSelected(data.idTipoDocumento);
    //según el tipo de id, se setea el input con el num de documento
    switch (data.idTipoDocumento) {
      case this.dniId:
        this.nDniAcom.setValue(data.numeroDocumento);
        break;
      case this.pasaporteId:
        this.nDocAcom.setValue(data.numeroDocumento);
        break;
      case this.otrosId:
        this.nDocOtrosAcom.setValue(data.numeroDocumento);
        break;
    }
    this.nombreAcom.setValue(data.nombre);
    this.apellidoAcom.setValue(data.apellidos);
    this.fechaNacimientoAcom.setValue(data.fechaNacimiento);
    this.parentezcoAcom.setValue(data.idParentesco);
    this.telefonoAcom.setValue(data.telefono);
    this.cdr.detectChanges();
  }

  public existePacienteService(
    numeroDocumento: string,
    idTipoDocumento: string
  ) {
    if (!numeroDocumento || !idTipoDocumento) {
      return;
    }

    this.existePacienteServ.getExistePaciente(numeroDocumento, true).subscribe({
      next: (resp) => {
        const existePaciente = resp.data;
        this.idPacient = resp.data.idPaciente;
        if (
          !existePaciente ||
          !existePaciente.tipoDocumento ||
          !existePaciente.numeroDocumento
        ) {
          return;
        }

        const numeroDocumentoService = existePaciente?.numeroDocumento;
        const idTipoDocumentoService =
          existePaciente?.tipoDocumento.idTipoDocumento;

        const data: IDataFormularioPaciente = {
          idTipoDocumento: idTipoDocumento,
          numeroDocumento: existePaciente.numeroDocumento || numeroDocumento,
          historiaClinica: existePaciente.numeroDocumento || '',
          nombre: existePaciente.nombres || '',
          apellido: existePaciente.apellidos || '',
          email: existePaciente.correoElectronico || '',
          numero: existePaciente.telefono || '',
          tipoSeguro: existePaciente.prevision.idPrevision || '',
          fechaNacimiento: existePaciente.fechaNacimiento ?? ' ',
          edad: existePaciente.edad ?? '',
          idSexo: existePaciente.sexo?.idSexo ?? '',
          idEstadoCivil: existePaciente.estadoCivil?.idEstadoCivil ?? '',
          idReligion: existePaciente.religion?.idReligion ?? ' ',
          etnia: existePaciente.etnias?.idEtnia ?? '',
          idGradoInstruccion:
            existePaciente.gradoInstruccion?.idGradoInstruccion ?? '',
          paisNacimineto: existePaciente.paisNacimiento?.idPais ?? '',
          ciudadNacimiento: existePaciente.regionNacimiento?.idRegion ?? '',
          idGrupoSanguineo:
            existePaciente.grupoSanguineo?.idGrupoSanguineo ?? '',
          ocupacion: existePaciente.ocupacion ?? '',
          direccionCompleta: existePaciente.direccion?.direccionCompleta ?? '',
          /*peso: this.dataExistePaciente.data.peso ?? '',
          altura: this.dataExistePaciente.data.altura ?? ''*/
        };

        if (
          numeroDocumento === numeroDocumentoService &&
          idTipoDocumento === idTipoDocumentoService
        ) {
          this.dataExistePaciente = { ...data };
          this.formRegistroPaciente.patchValue(data);

          this.formRegistroPaciente.get('numeroDocumento')?.disable();
          this.formRegistroPaciente.get('historiaClinica')?.disable();
          this.formRegistroPaciente.get('idTipoDocumento')?.disable();
        }
      },
    });
  }

  /*MÉTODOS PARA RESCATAR DATA DE CAMPOS FORM*/
  public onSelectDireccion(selectedAddress: any): void {
    //se busca la direccion seleccionada en el la lista de direcciones
    const direccionSeleccionada = this.listDirecciones.find(
      (direccion) => direccion.address === selectedAddress
    );
    if (direccionSeleccionada != undefined) {
      const nombreDireccion = direccionSeleccionada?.address;
      //id de la seleccion de direccion
      this.idDireccionSeleccionada = direccionSeleccionada?.id;
    }
    if (direccionSeleccionada) {
      this.formRegistroPaciente.patchValue({
        direccionCompleta: direccionSeleccionada?.address,
      });
    }
  }

  onAcomSelectionChange(selectedValue: string) {
    this.flagDni = true;
    this.formRegistroAcompanante.reset();
    if (selectedValue === 'Si') {
      this.showSeccionAcompanante = true;
      this.addAcompananteValidators();
    } else {
      this.showSeccionAcompanante = false;
      this.removeAcompananteValidators();
    }
  }

  addAcompananteValidators() {
    Object.keys(this.formRegistroAcompanante.controls).forEach(
      (nombreControl) => {
        const control = this.formRegistroAcompanante.get(nombreControl);
        control?.setValidators([Validators.required]);
        control?.updateValueAndValidity();
      }
    );
  }

  removeAcompananteValidators() {
    Object.keys(this.formRegistroAcompanante.controls).forEach(
      (nombreControl) => {
        this.formRegistroAcompanante.get(nombreControl)?.clearValidators();
        this.formRegistroAcompanante
          .get(nombreControl)
          ?.updateValueAndValidity();
      }
    );
  }

  /*MÉTODOS PARA CALCULAR EDAD SEGUN SELECCION DATEPICKER*/
  onDateChange(event: any) {
    const edad = Math.floor(this.calcularEdad(event));
    this.edad.setValue(edad);
    const esMayorEdad = edad >= 18;
    if (!esMayorEdad) {
      this.acompanante.setValue('Si');
      this.onAcomSelectionChange('Si');
      this.acompanante.disable();
    } else {
      this.acompanante.setValue(null);
      this.onAcomSelectionChange('');
      this.acompanante.enable();
    }
  }

  onDateChangeAcom(event: Date) {
    const edad = Math.floor(this.calcularEdad(event));
    this.edadAcom.setValue(edad);
  }

  private calcularEdad(fecha: Date | string): number {
    let fechaMoment: Moment;
    if (fecha instanceof Date) {
      fechaMoment = moment(fecha);
    } else if (typeof fecha === 'string') {
      fechaMoment = moment(fecha, 'YYYY-MM-DD');
    } else {
      return 0;
    }
    const hoy = moment();
    let edad = hoy.diff(fechaMoment, 'years');
    return edad;
  }

  // Método para cargar direcciones basadas en el término de búsqueda
  loadDirecciones(searchTerm: string) {
    this.buscarDireccionService.getDirecciones(searchTerm).subscribe((resp) => {
      this.listDirecciones = resp.data;
    });
  }

  onInputChanged(searchTerm: string) {
    this.loadDirecciones(searchTerm);
  }

  displayFn(option: IDataDireccion): string {
    return option && option.address ? option.address : '';
  }

  public displayAddress(direccion: any): string {
    return direccion ? direccion : ''; // Mostramos el atributo address
  }

  /* RESET CAMPOS FORMULARIO*/
  public soloLetras(event: KeyboardEvent) {
    this.utilServiceService.onlyLetters(event);
  }

  public formatAltura(event: KeyboardEvent) {
    this.utilServiceService.formatAltura(event);
  }

  public formatPeso(event: KeyboardEvent) {
    this.utilServiceService.formatPeso(event);
  }

  public soloNumeros(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    value = value.replace(/[^0-9]/g, '');
    this.formRegistroPaciente.controls['edad'].setValue(value);
  }

  public restringirCaracteresEspeciales(event: KeyboardEvent) {
    this.utilServiceService.restringirCaracteresEspeciales(event);
  }

  public submitForm() {
    if (this.formRegistroPaciente.valid && this.formRegistroAcompanante.valid) {
      this.armadoRequest();
    }
  }

  armarReqAcompanante(): IDataReqAcompanante | null {
    if (this.acompanante.value == 'Si') {
      return {
        nombre: this.nombreAcom.value,
        apellido: this.apellidoAcom.value,
        edad: this.edadAcom.value.toString(),
        fechaNacimiento: this.fechaNacimientoAcom.value,
        idParentesco: this.parentezcoAcom.value,
        idTipoDocumento: this.tipoDocAcom.value,
        numeroDocumento: this.getNumDocAcom(),
        telefono: this.telefonoAcom.value,
      };
    } else {
      return null;
    }
  }

  getNumDocAcom() {
    switch (this.tipoDocAcom.value) {
      case this.dniId:
        return this.nDniAcom.value;
      case this.pasaporteId:
        return this.nDocAcom.value;
      case this.otrosId:
        return this.nDocOtrosAcom.value;
    }
  }

  armarReqGuardarPaciente(): IReqDataPaciente {
    return {
      idAgendamiento: this._fichaClinicaStore.values.idAgendamiento!,
      idPaciente: this.idPacient,
      nombres: this.nombre.value,
      apellidos: this.apellido.value,
      fechaNacimiento: this.fechaNacimiento.value,
      edad: String(this.edad.value),
      idSexo: this.idSexo.value,
      idEstadoCivil: this.idEstadoCivil.value,
      idPaisNacimiento: this.paisNacimineto.value,
      idRegionNacimiento: this.ciudadNacimiento.value,
      idReligion: this.idReligion.value,
      idEtnia: this.etnia.value,
      idDireccion: this.idDireccionSeleccionada,
      idGradoInstruccion: this.idGradoInstruccion.value,
      ocupacion: this.ocupacion.value,
      telefono: this.formRegistroPaciente.get('numero')?.value,
      correoElectronico: this.email.value,
      idGrupoSanguineo: this.idGrupoSanguineo.value,
      peso: this.peso.value,
      altura: this.altura.value,
      direccionCompleta: this.direccionCompleta.value,
      acompaniante: this.armarReqAcompanante(),
    };
  }

  private armadoRequest() {
    const reqData = this.armarReqGuardarPaciente();

    if (this.formularioModificado()) {
      const dialogRef = this.dialog.open(ModalConfirmCambioDatosComponent, {
        data: { reqData },
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.update) {
          this.guardarService(reqData);
        } else {
        }
      });
    } else {
      this.guardarService(reqData);
    }
  }

  private guardarService(req: IReqDataPaciente) {
    this.guardadoDatosRegistroService.postNuevaConsulta(req).subscribe({
      next: (resp) => {
        this.guardadoDatosResp = resp;
        if (resp) {
          this.dataExistePaciente = {
            idTipoDocumento: this.dataExistePaciente.idTipoDocumento,
            numeroDocumento: this.dataExistePaciente.numeroDocumento,
            historiaClinica: this.dataExistePaciente.numeroDocumento,
            nombre: req.nombres,
            apellido: req.apellidos,
            email: req.correoElectronico,
            numero: req.telefono,
            tipoSeguro: this.dataExistePaciente.tipoSeguro,
            fechaNacimiento: req.fechaNacimiento,
            edad: req.edad,
            idSexo: req.idSexo,
            idEstadoCivil: req.idEstadoCivil,
            idReligion: req.idReligion,
            etnia: req.idEtnia,
            idGradoInstruccion: req.idGradoInstruccion,
            paisNacimineto: req.idPaisNacimiento,
            ciudadNacimiento: req.idRegionNacimiento,
            idGrupoSanguineo: req.idGrupoSanguineo,
            ocupacion: req.ocupacion,
            direccionCompleta: req.direccionCompleta,
          };

          this.dataExisteAcompanante = req.acompaniante
            ? {
                apellidos: req.acompaniante.apellido,
                edad: req.acompaniante.edad,
                fechaNacimiento: req.acompaniante.fechaNacimiento,
                idParentesco: req.acompaniante.idParentesco,
                idTipoDocumento: req.acompaniante.idTipoDocumento,
                nombre: req.acompaniante.nombre,
                numeroDocumento: req.acompaniante.numeroDocumento,
                telefono: req.acompaniante.telefono,
              }
            : null;

          this.restablecerFormulario();

          this._fichaClinicaStore.updateMedicalHistoryBySection(
            'identificacion',
            true
          );

          this.snackBar.openFromComponent(SnackInfoComponent, {
            data: {
              message: 'Los cambios han sido guardados con éxito.',
              type: SnackInfoType.SUCCESS,
            },
            duration: 3000, // Duración en milisegundos
          });
        }
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }

  private formularioModificado(): boolean {
    const valoresActuales = this.formRegistroPaciente.getRawValue();
    if (!this.dataExistePaciente) {
      return false;
    }
    // Compara valores actuales formulario con precargar
    return Object.keys(this.dataExistePaciente).some((key) => {
      return (
        this.dataExistePaciente[key as keyof IDataFormularioPaciente] !==
        valoresActuales[key]
      );
    });
  }

  onDocAcomSelected(selectedValue: any) {
    this.addRequiredIdAcom(selectedValue);
    this.flagDni = false;
    this.flagPasaporte = false;
    this.flagOtros = false;
    switch (selectedValue) {
      case this.dniId:
        this.flagDni = true;
        break;
      case this.pasaporteId:
        this.flagPasaporte = true;
        break;
      default:
        this.flagOtros = true;
        break;
    }
  }

  // Limpia los campos de id y añade los validadores
  addRequiredIdAcom(tipoId: String) {
    //control
    let control: AbstractControl<any, any>;
    // eliminar los validadores de todos los id
    const tiposId = ['nDniAcom', 'nDocAcom', 'nDocOtrosAcom'];
    for (const id of tiposId) {
      this.formRegistroAcompanante.get(id)?.reset();
      this.formRegistroAcompanante.get(id)?.clearValidators();
    }
    //asignar control segun tipoId
    switch (tipoId) {
      case this.dniId:
        control = this.formRegistroAcompanante.get('nDniAcom')!;
        break;
      case this.pasaporteId:
        control = this.formRegistroAcompanante.get('nDocAcom')!;
        break;
      default:
        control = this.formRegistroAcompanante.get('nDocOtrosAcom')!;
        break;
    }
    // añadir validacion
    control.addValidators([Validators.required, Validators.minLength(8)]);
    for (const id of tiposId) {
      this.formRegistroAcompanante.get(id)?.updateValueAndValidity();
    }
  }

  manejoDataDni(event: Event) {
    this.utilServiceService.formatDni(event);
  }
  manejoDataPasaporte(event: Event) {
    this.utilServiceService.formatPasaporte(event);
  }

  onCancelarEdicion() {
    this.openConfirmarCancelar();
  }

  restablecerFormulario() {
    this.formRegistroPaciente.reset();
    this.formRegistroPaciente.patchValue(this.dataExistePaciente);
    if (this.dataExisteAcompanante) {
      this.showSeccionAcompanante = true;
      this.acompanante.setValue('Si');
      this.setDataAcompanante(this.dataExisteAcompanante);
    } else {
      this.showSeccionAcompanante = false;
    }
    this.cdr.detectChanges();
  }

  public openConfirmarCancelar() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      autoFocus: false,
      panelClass: 'custom-confirmation-dialog',
      width: '452px',
      data: {
        type: ModalConfirmType.WARNING,
        description: '¿Confirmas que deseas cancelar sin guardar?',
        config: {
          buttons: [
            { title: 'Volver', action: false },
            { title: 'Confirmar', action: true },
          ],
        },
      } as ModalConfirmProps,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.restablecerFormulario();
      }
    });
  }

  btnCancelarDeshabilitado(): boolean {
    return (
      this.formRegistroPaciente.pristine &&
      this.formRegistroAcompanante.pristine
    );
  }

  btnGuardarDisabled(): boolean {
    return (
      this.formRegistroPaciente.invalid || this.formRegistroAcompanante.invalid
    );
  }
}
