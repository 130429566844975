import { ChangeDetectionStrategy, Component } from '@angular/core';
import {Router} from "@angular/router";
import { MatDialogRef } from '@angular/material/dialog';
import {MatButtonModule} from "@angular/material/button";
@Component({
  selector: 'soph-modal-solicitud-enviada',
  standalone: true,
  imports: [    MatButtonModule],
  templateUrl: './modal-solicitud-enviada.component.html',
  styleUrl: './modal-solicitud-enviada.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalSolicitudEnviadaComponent {
 constructor(private router: Router,
             private dialogRef: MatDialogRef<ModalSolicitudEnviadaComponent>) {
 }
  volverAlogin(){
    this.dialogRef.close();
    this.router.navigate(['/login']); // redireccionamiento login
  }

}
