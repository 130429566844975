<form [formGroup]="form" (ngSubmit)="onSave(form)" autocomplete="off">
  <div class="container">
    <div class="input-container">
      <h2 class="texto-info">Tipo de diagnóstico</h2>
      <div class="input-total">
        <p>
          Elige el tipo de diagnóstico que otorgarás al paciente y luego busca y
          selecciona por el código CIE 10 o su descripción
        </p>

        <mat-radio-group
          class="radio-group-field"
          formControlName="tipoDiagnostico"
        >
          <mat-radio-button
            value="PRESUNTIVO"
            (click)="onRadioButtonClick('PRESUNTIVO')"
          >
            Presuntivo
          </mat-radio-button>

          @if(tipoDiagnositico() === "PRESUNTIVO") {
          <ng-container [ngTemplateOutlet]="multiSelectField" />
          }

          <mat-radio-button
            value="DEFINITIVO"
            (click)="onRadioButtonClick('DEFINITIVO')"
          >
            Definitivo
          </mat-radio-button>

          @if(tipoDiagnositico() === "DEFINITIVO") {
          <ng-container [ngTemplateOutlet]="multiSelectField" />
          }
        </mat-radio-group>

        @if(form.get('tipoDiagnostico')?.hasError('required') &&
        form.get('tipoDiagnostico')?.touched) {
        <div class="error-radio-group">
          <span>Este campo es requerido</span>
        </div>
        }

        <ng-template #multiSelectField>
          <div class="multi-select-template" (click)="$event.stopPropagation()">
            <soph-multi-select-search-form-field
              [formGroup]="form"
              controlName="diagnosticos"
              placeholder="CIE 10"
              [values]="diagnoses"
              [limitValues]="50"
              idField="idDiagnostico"
              viewField="nombre"
              [errorMessages]="{
                required: 'Debes indicar el diagnóstico'
              }"
            ></soph-multi-select-search-form-field>
          </div>

          @if(selectedValuesTag().length > 0) {
          <div class="selected-values-action">
            <mat-list
              [ngClass]="{
                'last-divider-none': tipoDiagnositico() === 'DEFINITIVO'
              }"
            >
              <div mat-subheader>Diagnósticos seleccionados:</div>
              <mat-divider></mat-divider>

              @for(item of selectedValuesTag(); track item) {
              <mat-list-item>
                <div matListItemTitle class="title">{{ item.nombre }}</div>
                @if(!form.controls['diagnosticos'].disabled) {
                <div matListItemTitle class="action">
                  <button
                    mat-button
                    (click)="
                      eliminarDiagnosticoSeleccionado(item.idDiagnostico)
                    "
                  >
                    <mat-icon svgIcon="trash"></mat-icon> Eliminar
                  </button>
                </div>
                }
              </mat-list-item>

              <mat-divider class="secondary"></mat-divider>
              }
            </mat-list>
          </div>
          }
        </ng-template>
      </div>
    </div>

    <mat-divider class="primary"></mat-divider>

    <div class="input-container">
      <h2 class="texto-info">Observaciones</h2>
      <div class="input-total">
        <p>Ingresa las notas que necesites con respecto al diagnóstico</p>
        <soph-textarea-with-speech-to-text-form-field
          [control]="form.controls['observacion']"
          label="Observaciones"
          [maxLength]="1000"
          [errorMessages]="{
            maxlength: 'Ha excedido el número máximo de caracteres'
          }"
        ></soph-textarea-with-speech-to-text-form-field>
      </div>
    </div>

    <div class="section-acction">
      <button
        mat-stroked-button
        class="estilo-button"
        type="button"
        (click)="revertChanges()"
        [disabled]="!hasChanges()"
      >
        Cancelar
      </button>
      <button
        mat-flat-button
        class="estilo-button-flat"
        type="submit"
        [disabled]="isBtnGuardarDisabled()"
      >
        Guardar
      </button>
    </div>
  </div>
</form>
