<div class="col-md-12 layout-menu-lateral">

  <div class="card-paciente-datos">
    <div class="card-header">
      <span class="texto-info">Paciente</span>
    </div>
    <div class="card-body">
      <div class="patient-info">
        <div class="patient-avatar">
          <mat-icon>person</mat-icon>
        </div>
        <div class="patient-details">
          <p class="texto-card"><strong>Nombre:</strong> Jimmy Sarmiento</p>
          <p class="texto-card"><strong>Historia clínica:</strong> 42181478-9</p>
          <p class="texto-card"><strong>DNI:</strong> 42181478-9</p>
          <p class="texto-card"><strong>Correo:</strong> </p>
          <p class="texto-card"><strong>Teléfono:</strong> +51 2334 11 22</p>
          <p class="texto-card"><strong>Número de seguro:</strong> SOAT</p>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <p  class="style-hipervinculo" href="#"> <strong>Ver menos </strong></p></div>
  </div>

  <div class="row info-consultas">
    <h1 class="texto-info">Historial de consultas (2)</h1>
    <p>Selecciona una consulta y podrás visualizar la ficha clínica asociada</p>
  </div>
  <div class="card-info-estado">
    <div>
      <h1 class="texto-info">Consulta 22/02/2023 - 12:50</h1>
    </div>
    <div class="row consulta-realizada">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
      <p class="estilo-texto-chip">Consulta realizada</p>
    </div>
    <div class="col-md-12 detalle-consulta">
      <div class="col-md-8">
        <p class="style-hipervinculo"> <strong>ver más</strong></p>
      </div>
    </div>
  </div>
  <div class="row card-info-estado">
    <div>
      <h1 class="texto-info">Consulta 22/02/2023 - 12:50</h1>
    </div>
    <div class="row consulta-anulada">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
      <p class="estilo-texto-chip">Consulta anulada</p>
    </div>
  </div>
  <div class="row card-info-estado">
    <div>
      <h1 class="texto-info">Consulta 22/02/2023 - 12:50</h1>
    </div>
    <div class="row consulta-suspendida">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/></svg>
      <p class="estilo-texto-chip">Consulta suspendida
      </p>
    </div>
  </div>
</div>
