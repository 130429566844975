import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { AnulacionHorasService } from '../../services/anulacion-horas.service';
import { IRespDetalleNuevaConsulta } from '../../interfaces/tratamiento/detalle-nueva-consulta.interface';
import { IDataAnulacionHoras } from '../../interfaces/anulacion-horas.interface';
import { ShareDataService } from '../../services/share-data.service';

@Component({
  selector: 'soph-modal-confirm-anular-consulta',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './modal-confirm-anular-consulta.component.html',
  styleUrl: './modal-confirm-anular-consulta.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmAnularConsultaComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IRespDetalleNuevaConsulta,
    private readonly _anulacionHoraService: AnulacionHorasService,
        private _refreshCalendar: ShareDataService,
    private dialogRef: MatDialogRef<ModalConfirmAnularConsultaComponent>
  ) {}

  anulacionHora: IDataAnulacionHoras | null = null;
  ngOnInit(): void {
  }

  anularConsulta() {
    this._anulacionHoraService
      .putAnulacionHoras(this.data.idAgendamiento)
      .subscribe({ next:(resp) => {
        this.anulacionHora = resp.data;
        this._refreshCalendar.refreshCalendar()
      }
      });
    this.dialogRef.close();
  }

  cancelar() {
    this.dialogRef.close();
  }
}
