<div class="section-inputs">
<mat-form-field class="full-width"  appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input matInput
         [formControl]="control"
         [placeholder]="label"
         [matDatepicker]="picker">
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error *ngIf="control.hasError('required') && control.touched">
    {{  errorMessages.required}}
  </mat-error>
</mat-form-field>
</div>
