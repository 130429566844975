import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { Observable } from 'rxjs';
import { IDataDiagnosticos, IRespDiagnosticos } from '../interfaces/diagnosticos.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceFormDiagnosticoService {
  private endpointUrlBase = `${environment.baseURL}datos-medicos`;

  constructor(
    private httpClient: HttpClient
  ) {}

  getDiagnosticos(): IDataDiagnosticos[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const diagnosticos = jsonData.data.diagnosticos;
    return diagnosticos;
  }
}
