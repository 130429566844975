export interface IRespEvent {
  success: boolean;
  data: IDataEvent[];
  error: any;
}

export interface IDataEvent {
  id: string;
  title: string;
  start: string;
  end: string;
  tipo: TipoEvento;
  color: string;
  metadata: DataBloqueo | DataAgendamiento;
}

export interface DataBloqueo {
  tipoBloqueo: string;
  descripcion: string;
}

export interface DataAgendamiento {
  tipoAtencion: string;
  idPaciente: string;
}

export enum TipoEvento {
  Agendamiento = 'AGENDAMIENTO',
  Bloqueo = 'BLOQUEO',
}
