<mat-dialog-content class="modal-content">
    <img
    src="../../../../assets/icons/success.svg"
    height="34"
    width="34"
    alt="Logo"
    style="    padding-top: 4px;"
  />
    <h2>El bloqueo de horario ha sido realizado <br> con éxito</h2>
  </mat-dialog-content>
  
  <mat-dialog-actions class="modal-actions">
    <button
    style="font-size: 16px;"
     (click)="irACalendario()"
      mat-flat-button color="primary"
      mat-flat-button>Aceptar
    </button>
  </mat-dialog-actions>
  