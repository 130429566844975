import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IRespMotivos } from '../interfaces/motivos-bloqueo.interface';

@Injectable({
  providedIn: 'root'
})
export class MotivosBloqueoHorarioService {
  private apiUrl = environment.baseURL;
  constructor(private httpClient: HttpClient) { }

    getMotivosBloqueo(): Observable<IRespMotivos> {
      return this.httpClient.get<IRespMotivos>(this.apiUrl + 'bloqueos/tipos');
    }
}
