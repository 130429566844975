import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ReqBloqueoHorario } from '../interfaces/bloqueo-horario.interface';
import { Observable } from 'rxjs';
import { IDataBloqueo } from '../interfaces/bloqueoHorario.interface';

@Injectable({
  providedIn: 'root',
})
export class BloqueoHorariosService {
  private apiUrl = environment.baseURL;
  constructor(
    private httpClient: HttpClient
  ) {}

  postBloquearHorarios(req: ReqBloqueoHorario): Observable<IDataBloqueo> {
    return this.httpClient.post<IDataBloqueo>(this.apiUrl + 'bloqueos', req);
  }
}
