<div>
  <mat-dialog-content class="modal-content">
    <div class="section-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="22"
        viewBox="0 0 31 22"
        fill="none"
      >
        <mask
          id="mask0_4795_30546"
          style="mask-type: luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="31"
          height="22"
        >
          <path
            d="M15.4997 1.08301C7.52595 1.08301 2.73903 5.86284 1.59862 7.14492C1.51674 7.23886 1.46338 7.35422 1.44481 7.47744C1.42624 7.60065 1.44323 7.72661 1.49378 7.84051L6.68303 20.1634C6.8502 20.5608 7.33258 20.7258 7.72216 20.5402C8.99999 19.9303 11.8702 18.7913 15.4997 18.7913C19.1292 18.7913 21.9993 19.9303 23.2772 20.5409C23.6667 20.7258 24.1491 20.5608 24.3163 20.1634L29.5055 7.84051C29.5561 7.72661 29.5731 7.60065 29.5545 7.47744C29.5359 7.35422 29.4826 7.23886 29.4007 7.14492C28.2603 5.86284 23.4734 1.08301 15.4997 1.08301Z"
            fill="white"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.666 11.0001H18.3327M15.4993 8.16602V13.8327"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </mask>
        <g mask="url(#mask0_4795_30546)">
          <path d="M-1.5 -6H32.5V28H-1.5V-6Z" fill="#0C2447" />
        </g>
      </svg>
    </div>
    <h2>{{ data.isUpdate ? 
    'La consulta fue editada con éxito' : 'La consulta fue creada con éxito' }}</h2>
    <div class="contenedor-info-consulta">
      <div class="contenedor-nombre">
        <img src="assets/icons/Persona_circulo.svg" alt="" />
        <H2> {{ data.nombres }} {{ data.apellidos }}</H2>
      </div>
      <div class="contenedor-hora-fecha">
        <div class="info-item" style="display: flex; gap: 4px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M16.75 3.125H4.25C3.21447 3.125 2.375 3.96447 2.375 5V16.25C2.375 17.2855 3.21447 18.125 4.25 18.125H16.75C17.7855 18.125 18.625 17.2855 18.625 16.25V5C18.625 3.96447 17.7855 3.125 16.75 3.125Z"
              stroke="#0C2447"
              stroke-width="1.8"
              stroke-linejoin="round"
            />
            <path
              d="M12.0625 10C12.5803 10 13 9.58027 13 9.0625C13 8.54473 12.5803 8.125 12.0625 8.125C11.5447 8.125 11.125 8.54473 11.125 9.0625C11.125 9.58027 11.5447 10 12.0625 10Z"
              fill="#0C2447"
            />
            <path
              d="M15.1875 10C15.7053 10 16.125 9.58027 16.125 9.0625C16.125 8.54473 15.7053 8.125 15.1875 8.125C14.6697 8.125 14.25 8.54473 14.25 9.0625C14.25 9.58027 14.6697 10 15.1875 10Z"
              fill="#0C2447"
            />
            <path
              d="M12.0625 13.125C12.5803 13.125 13 12.7053 13 12.1875C13 11.6697 12.5803 11.25 12.0625 11.25C11.5447 11.25 11.125 11.6697 11.125 12.1875C11.125 12.7053 11.5447 13.125 12.0625 13.125Z"
              fill="#0C2447"
            />
            <path
              d="M15.1875 13.125C15.7053 13.125 16.125 12.7053 16.125 12.1875C16.125 11.6697 15.7053 11.25 15.1875 11.25C14.6697 11.25 14.25 11.6697 14.25 12.1875C14.25 12.7053 14.6697 13.125 15.1875 13.125Z"
              fill="#0C2447"
            />
            <path
              d="M5.8125 13.125C6.33027 13.125 6.75 12.7053 6.75 12.1875C6.75 11.6697 6.33027 11.25 5.8125 11.25C5.29473 11.25 4.875 11.6697 4.875 12.1875C4.875 12.7053 5.29473 13.125 5.8125 13.125Z"
              fill="#0C2447"
            />
            <path
              d="M8.9375 13.125C9.45527 13.125 9.875 12.7053 9.875 12.1875C9.875 11.6697 9.45527 11.25 8.9375 11.25C8.41973 11.25 8 11.6697 8 12.1875C8 12.7053 8.41973 13.125 8.9375 13.125Z"
              fill="#0C2447"
            />
            <path
              d="M5.8125 16.25C6.33027 16.25 6.75 15.8303 6.75 15.3125C6.75 14.7947 6.33027 14.375 5.8125 14.375C5.29473 14.375 4.875 14.7947 4.875 15.3125C4.875 15.8303 5.29473 16.25 5.8125 16.25Z"
              fill="#0C2447"
            />
            <path
              d="M8.9375 16.25C9.45527 16.25 9.875 15.8303 9.875 15.3125C9.875 14.7947 9.45527 14.375 8.9375 14.375C8.41973 14.375 8 14.7947 8 15.3125C8 15.8303 8.41973 16.25 8.9375 16.25Z"
              fill="#0C2447"
            />
            <path
              d="M12.0625 16.25C12.5803 16.25 13 15.8303 13 15.3125C13 14.7947 12.5803 14.375 12.0625 14.375C11.5447 14.375 11.125 14.7947 11.125 15.3125C11.125 15.8303 11.5447 16.25 12.0625 16.25Z"
              fill="#0C2447"
            />
            <path
              d="M5.5 1.875V3.125M15.5 1.875V3.125"
              stroke="#0C2447"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.625 6.25H2.375"
              stroke="#0C2447"
              stroke-width="1.8"
              stroke-linejoin="round"
            />
          </svg>
          <p>{{ data.fechaSelec }}</p>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M10.5 2.5C6.35938 2.5 3 5.85938 3 10C3 14.1406 6.35938 17.5 10.5 17.5C14.6406 17.5 18 14.1406 18 10C18 5.85938 14.6406 2.5 10.5 2.5Z"
              stroke="#0C2447"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M10.5 5V10.625H14.25"
              stroke="#0C2447"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>{{ data.horaSelec }}</p>
        </div>
      </div>

      <div *ngIf="showCampoPresnecial" class="tipoConsulta">
        <div class="info-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M1.5 16.5H16.5M3.16667 16.5V3.16667C3.16667 2.72464 3.34226 2.30072 3.65482 1.98816C3.96738 1.67559 4.39131 1.5 4.83333 1.5H13.1667C13.6087 1.5 14.0326 1.67559 14.3452 1.98816C14.6577 2.30072 14.8333 2.72464 14.8333 3.16667V16.5"
              stroke="#0C2447"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.5 16.4997V13.1663C6.5 12.7243 6.6756 12.3004 6.98816 11.9878C7.30072 11.6753 7.72464 11.4997 8.16667 11.4997H9.83333C10.2754 11.4997 10.6993 11.6753 11.0118 11.9878C11.3244 12.3004 11.5 12.7243 11.5 13.1663V16.4997M7.33333 6.49967H10.6667M9 4.83301V8.16634"
              stroke="#0C2447"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>Presencial</p>
        </div>
      </div>

      <div *ngIf="showCamposDomicilio" class="tipoConsultaDom">
        <div class="info-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M3.125 8.28125V17.5C3.125 17.6658 3.19085 17.8247 3.30806 17.9419C3.42527 18.0592 3.58424 18.125 3.75 18.125H7.5V12.8125C7.5 12.5639 7.59877 12.3254 7.77459 12.1496C7.9504 11.9738 8.18886 11.875 8.4375 11.875H11.5625C11.8111 11.875 12.0496 11.9738 12.2254 12.1496C12.4012 12.3254 12.5 12.5639 12.5 12.8125V18.125H16.25C16.4158 18.125 16.5747 18.0592 16.6919 17.9419C16.8092 17.8247 16.875 17.6658 16.875 17.5V8.28125"
              stroke="#0C2447"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M18.75 10.0001L10.4254 2.0313C10.2301 1.82505 9.77344 1.82271 9.57461 2.0313L1.25 10.0001M15.625 6.99224V2.50005H13.75V5.19536"
              stroke="#0C2447"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>Domiciliaria</p>
        </div>
      </div>

      <div *ngIf="showCamposTelemedicina" class="tipoConsultaTel">
        <div class="info-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M14.6402 12.0617L17.8711 14.3359C17.9663 14.3782 18.0705 14.3961 18.1743 14.3879C18.2781 14.3797 18.3782 14.3457 18.4655 14.2889C18.5529 14.2322 18.6246 14.1545 18.6743 14.063C18.724 13.9715 18.75 13.869 18.75 13.7648V6.23516C18.75 6.13102 18.724 6.02854 18.6743 5.93701C18.6246 5.84549 18.5529 5.76783 18.4655 5.71109C18.3782 5.65435 18.2781 5.62033 18.1743 5.61211C18.0705 5.6039 17.9663 5.62176 17.8711 5.66407L14.6402 7.93829C14.5583 7.99596 14.4914 8.07248 14.4453 8.1614C14.3991 8.25032 14.375 8.34904 14.375 8.44922V11.5508C14.375 11.651 14.3991 11.7497 14.4453 11.8386C14.4914 11.9275 14.5583 12.0041 14.6402 12.0617Z"
              stroke="#0C2447"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.4688 15H3.28125C2.743 14.9985 2.22725 14.784 1.84665 14.4034C1.46605 14.0228 1.25155 13.507 1.25 12.9688V7.03125C1.25155 6.493 1.46605 5.97725 1.84665 5.59665C2.22725 5.21605 2.743 5.00155 3.28125 5H10.4875C11.0207 5.00165 11.5317 5.21421 11.9087 5.59127C12.2858 5.96833 12.4984 6.47926 12.5 7.0125V12.9688C12.4985 13.507 12.284 14.0228 11.9034 14.4034C11.5228 14.784 11.007 14.9985 10.4688 15Z"
              stroke="#0C2447"
              stroke-width="1.6"
              stroke-miterlimit="10"
            />
          </svg>
          <p>Telemedicina</p>
        </div>
      </div>

      <div *ngIf="data.institucion && showCampoPresnecial" class="ubicacion">
        <div class="info-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M10.5 8.75C11.1904 8.75 11.75 8.19036 11.75 7.5C11.75 6.80964 11.1904 6.25 10.5 6.25C9.80964 6.25 9.25 6.80964 9.25 7.5C9.25 8.19036 9.80964 8.75 10.5 8.75Z"
              fill="#0C2447"
            />
            <path
              d="M10.5 1.25C7.05391 1.25 4.25 3.93164 4.25 7.22656C4.25 8.7957 4.96523 10.8824 6.37578 13.4289C7.50859 15.4734 8.81914 17.3223 9.50078 18.2422C9.61596 18.3994 9.76656 18.5272 9.94037 18.6153C10.1142 18.7034 10.3063 18.7493 10.5012 18.7493C10.696 18.7493 10.8882 18.7034 11.062 18.6153C11.2358 18.5272 11.3864 18.3994 11.5016 18.2422C12.182 17.3223 13.4938 15.4734 14.6266 13.4289C16.0348 10.8832 16.75 8.79648 16.75 7.22656C16.75 3.93164 13.9461 1.25 10.5 1.25ZM10.5 10C10.0055 10 9.5222 9.85338 9.11107 9.57867C8.69995 9.30397 8.37952 8.91352 8.1903 8.45671C8.00108 7.99989 7.95157 7.49723 8.04804 7.01227C8.1445 6.52732 8.3826 6.08186 8.73223 5.73223C9.08186 5.3826 9.52732 5.1445 10.0123 5.04804C10.4972 4.95157 10.9999 5.00108 11.4567 5.1903C11.9135 5.37952 12.304 5.69995 12.5787 6.11107C12.8534 6.5222 13 7.00555 13 7.5C12.9993 8.16282 12.7357 8.79828 12.267 9.26697C11.7983 9.73565 11.1628 9.99928 10.5 10Z"
              fill="#0C2447"
            />
          </svg>
          <p>{{ data.institucion }}</p>
        </div>
      </div>

      <div *ngIf="data.link && showCamposTelemedicina" class="ubicacion">
        <div class="info-item">
          <p>{{ data.link }}</p>
        </div>
      </div>

      <div
        *ngIf="
          (data.direccion || data.direccionCompleta) && showCamposDomicilio
        "
        class="ubicacion"
      >
        <div class="info-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M10.5 8.75C11.1904 8.75 11.75 8.19036 11.75 7.5C11.75 6.80964 11.1904 6.25 10.5 6.25C9.80964 6.25 9.25 6.80964 9.25 7.5C9.25 8.19036 9.80964 8.75 10.5 8.75Z"
              fill="#0C2447"
            />
            <path
              d="M10.5 1.25C7.05391 1.25 4.25 3.93164 4.25 7.22656C4.25 8.7957 4.96523 10.8824 6.37578 13.4289C7.50859 15.4734 8.81914 17.3223 9.50078 18.2422C9.61596 18.3994 9.76656 18.5272 9.94037 18.6153C10.1142 18.7034 10.3063 18.7493 10.5012 18.7493C10.696 18.7493 10.8882 18.7034 11.062 18.6153C11.2358 18.5272 11.3864 18.3994 11.5016 18.2422C12.182 17.3223 13.4938 15.4734 14.6266 13.4289C16.0348 10.8832 16.75 8.79648 16.75 7.22656C16.75 3.93164 13.9461 1.25 10.5 1.25ZM10.5 10C10.0055 10 9.5222 9.85338 9.11107 9.57867C8.69995 9.30397 8.37952 8.91352 8.1903 8.45671C8.00108 7.99989 7.95157 7.49723 8.04804 7.01227C8.1445 6.52732 8.3826 6.08186 8.73223 5.73223C9.08186 5.3826 9.52732 5.1445 10.0123 5.04804C10.4972 4.95157 10.9999 5.00108 11.4567 5.1903C11.9135 5.37952 12.304 5.69995 12.5787 6.11107C12.8534 6.5222 13 7.00555 13 7.5C12.9993 8.16282 12.7357 8.79828 12.267 9.26697C11.7983 9.73565 11.1628 9.99928 10.5 10Z"
              fill="#0C2447"
            />
          </svg>
          <p *ngIf="!data.flagDireccionCompleta">
            {{ data.direccion }}
          </p>
          <p *ngIf="data.flagDireccionCompleta">{{ data.direccionCompleta }}</p>
        </div>
        <p *ngIf="data.casaDpto">{{ data.casaDpto }}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="modal-actions">
    <button
      [mat-dialog-close]="true"
      mat-flat-button
      color="primary"
      (click)="irAFicha()"
      mat-flat-button
    >
      Aceptar
    </button>
  </mat-dialog-actions>
</div>
