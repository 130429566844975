import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable} from 'rxjs';
import {IDataSeguro, IRespSeguro} from "../interfaces/seguros.interface";
import {AuthenticationService} from "../../pages/services/authentication.service";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObtenerPrevisionService {
  private apiUrl = environment.baseURL + 'datos-medicos/previsiones';


  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) { }

  getPrevision(): IDataSeguro[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const prevision = jsonData.data.previsiones;
    return prevision;
  }
}

