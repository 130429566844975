import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IResAtencionesMed } from '../interfaces/atenciones-medicas.interface';

@Injectable({
  providedIn: 'root',
})
export class ObtenerAtencionesService {
  private apiUrl = environment.baseURL + 'atenciones';
  constructor(
    private httpClient: HttpClient
  ) {}

  getAtenciones(idPaciente: string): Observable<IResAtencionesMed> {
    const params = new HttpParams().set ('idPaciente', idPaciente)
    return this.httpClient.get<IResAtencionesMed>(
      this.apiUrl + '/obtener-todas', {
        params
      }
    );
  }
}
