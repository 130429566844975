<div class="col-md-12 layout-menu-lateral">
  <div class="card-paciente-datos">
    <div class="card-header">
      <span class="texto-info">Paciente</span>
    </div>
    <div class="card-body">
      <div class="patient-info">
        <div class="patient-avatar">
          <img src="assets/icons/Persona_outline.svg" alt="" />
        </div>
        <div class="patient-details">
          <p class="texto-card">Nombre: {{ nombrePaciente }}  {{ apellidosPaciente}}</p>
          <p class="texto-card" style="margin-bottom: 0;">Historia clínica: {{valueTipoDocumentoPaciente}}</p>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div style="margin-top: 20px;" *ngIf="isExpanded">
          <p class="texto-card-panel">{{tipoDocumentoPaciente}}: {{valueTipoDocumentoPaciente}}</p>
          <p class="texto-card-panel">Correo: {{ correoPaciente }}</p>
          <p class="texto-card-panel">Teléfono: {{telefonoPaciente}}</p>
          <p class="texto-card-panel">Tipo de seguro: {{previsionPaciente}}</p>
      </div>
      <mat-accordion>
        <mat-expansion-panel (opened)="isExpanded = true" (closed)="isExpanded = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-panel">{{ isExpanded ? 'Ver menos' : 'Ver más' }}</mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  @if (dataHistorial360) {
    <div class="history-360">
      <button (click)="openHistory360()" class="history-360-btn">
        <img ngSrc="assets/images/Logo-mini.svg" alt="" height="17" width="17">
        <span>
          Ver Historial 360&deg;
        </span>
      </button>
    </div>
  }

  <div class="row info-consultas">
    <h1 class="texto-info" style="margin-bottom: 6px; font-size: 20px;">Historial de consultas (2)</h1>
    <p>Selecciona una consulta y podrás visualizar la ficha clínica asociada</p>
  </div>
  <div class="card-info-estado">
    <div>
      <h1 class="texto-info-consulta">Consulta 22/02/2023 - 12:50</h1>
    </div>
    <div class="row consulta-realizada">
      <img src="assets/icons/check_icon.svg" alt="" />
      <p class="estilo-texto-chip">Consulta realizada</p>
    </div>
    <div class="row detalle-consulta">
      <img src="assets/icons/eye_outline.svg" alt="" />
      <p class="style-hipervinculo">Ver consulta</p>
    </div>
    <div class="row detalle-consulta">
      <img src="assets/icons/eye_outline.svg" alt="" />
      <p class="style-hipervinculo">Ver receta médica</p>
    </div>
  </div>
  <div class="row card-info-estado">
    <div>
      <h1 class="texto-info-consulta">Consulta 22/02/2023 - 12:50</h1>
    </div>
    <div class="row consulta-anulada">
      <img src="assets/icons/cancel.svg" alt="" />
      <p class="estilo-texto-chip">Consulta anulada</p>
    </div>
  </div>
  <div class="row card-info-estado">
    <div>
      <h1 class="texto-info-consulta">Consulta 22/02/2023 - 12:50</h1>
    </div>
    <div class="row consulta-suspendida">
      <img src="assets/icons/delete.svg" alt="" />
      <p class="estilo-texto-chip">Consulta suspendida</p>
    </div>
  </div>
</div>
