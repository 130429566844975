import { Injectable, 
  signal, 
  WritableSignal } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class IdDireccionSeleccionadaSignalService {

  idDireccionSeleccionada: WritableSignal<string | null> = signal<string | null>(null);


  setDireccionSeleccionada(direccion: string | null): void {
    this.idDireccionSeleccionada.set(direccion);
  }


  getDireccionSeleccionada(): WritableSignal<string | null> {
    return this.idDireccionSeleccionada;
  }
  constructor() { }
}
