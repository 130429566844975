import { CommonModule, NgIf, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CL';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  NativeDateAdapter,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalNuevoAgendamientoCreadoComponent } from '../../../../../shared/components/modal-nuevo-agendamiento-creado/modal-nuevo-agendamiento-creado.component';
import { SelectFormFieldComponent } from '../../../../../shared/components/select-form-field/select-form-field.component';
import { EstadoFichaClinica } from '../../../../../shared/enums';
import { IDataCentrosMedico } from '../../../../../shared/interfaces/centros-medicos.interface';
import { ErrorMessages } from '../../../../../shared/interfaces/errorMesagges.interface';
import { ReqNuevoAgendamiento } from '../../../../../shared/interfaces/nueva-consulta.interface';
import { IDataPacienteForm } from '../../../../../shared/interfaces/paciente-form.interface';
import { IdDireccionSeleccionadaSignalService } from '../../../../../shared/services/id-direccion-seleccionada-signal.service';
import { ObtenerInstitucionesService } from '../../../../../shared/services/obtener-instituciones.service';
import { ShareDataService } from '../../../../../shared/services/share-data.service';
import { FichaClinicaStore } from '../../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { AgendamientosService } from '../../../../services/agendamientos.service';
import { ModalErrorGeneralComponent } from '../../../modal-error-general/modal-error-general.component';
import { HeaderSophiaComponent } from '../../header-sophia/header-sophia.component';
import { DateTime } from 'luxon';
import { EventosService } from '../../../../services/eventos.service';
import { parseFechaHoraToUtc } from '../../../../../shared/helpers/date.helper';

registerLocaleData(localeEs);
interface ISelectOption {
  value: string;
}

export class CustomDateAdapter extends NativeDateAdapter {
  // Cambia el primer día de la semana a lunes (1)
  override getFirstDayOfWeek(): number {
    return 1; // 0: Domingo, 1: Lunes, etc.
  }
}

@Component({
  selector: 'soph-ingreso-hora-fecha',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    NgIf,
    HeaderSophiaComponent,
    MatCardModule,
    MatAutocompleteModule,
    MatCardModule,
    MatDatepickerModule,
    SelectFormFieldComponent,
    MatNativeDateModule,
  ],
  providers: [
    provideNativeDateAdapter(),
    { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  templateUrl: './ingreso-hora-fecha.component.html',
  styleUrl: './ingreso-hora-fecha.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IngresoHoraFechaComponent implements OnInit, OnDestroy {
  /*GET CAMPOS FORM*/
  get hora(): FormControl {
    return this.formHoraFecha.get('horaSeleccionada') as FormControl;
  }
  listDirecciones: any;
  horaSelectOptions: ISelectOption[] = [];
  /*selected = model<Date | null>(null);*/
  selectedDate: Date | null = null;

  nuevoAgendamiento: any;
  minDate: Date = new Date();
  formDataRegistroPaciente: IDataPacienteForm = {} as IDataPacienteForm;
  formHoraFecha: FormGroup;
  institucionesOptions: IDataCentrosMedico[] = [];
  nombreInstitucionSeleccionada: string = '';
  direccion: string | null = null;
  idPaciente: any;
  idAgendamiento: any;
  subs = new Subscription();
  direccionCompleta!: string;
  showdireccionCompleta: boolean = false;
  fromEdit: boolean = false;
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private ObtenerInstitucionesService: ObtenerInstitucionesService,
    private IdDireccionSignal: IdDireccionSeleccionadaSignalService,
    private ShareDataService: ShareDataService,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private _agendamientos: AgendamientosService,
    private _eventos: EventosService,
        private route: ActivatedRoute
  ) {
    effect(() => {
      this.direccion = this.IdDireccionSignal.getDireccionSeleccionada()();
    });
    this.formHoraFecha = this.fb.group({
      fechaSeleccionada: ['', Validators.required],
      horaSeleccionada: ['', Validators.required],
    });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { formDataRegistroPaciente?: any };

    if (state?.formDataRegistroPaciente) {
      this.fromEdit = state.formDataRegistroPaciente.fromEdit || false;
    }


    this.getInstituciones();
    const sub = this.ShareDataService.getFormData().subscribe((resp) => {
      this.formDataRegistroPaciente = resp;
      if(resp.isUpdate) {
        this.selectedDate = DateTime.fromFormat(this.formDataRegistroPaciente.fechaSeleccionada, 'yyyy-MM-dd').toJSDate();
        this.onDateChange(this.selectedDate);
        this.hora.setValue(this.formDataRegistroPaciente.horaSeleccionada);
      }
    });
    this.subs.add(sub);
  }

  onDateChange(event: Date): void {
    if (event) {
      const diaConsulta = DateTime.fromJSDate(event).toFormat('yyyy-MM-dd');
      this.formHoraFecha.get('fechaSeleccionada')?.setValue(diaConsulta);

      this.hora.setValue('');
      this.hora.disable();

      this._eventos.getHorariosDisponibles(diaConsulta).subscribe({
        next: (resp) => {
          this.horaSelectOptions = resp.data.map((hora: string) => ({
            value: hora,
          }));
          this.hora.enable();

          // La hora original del agendamiento debe mostrarse en la lista de opciones
          if(diaConsulta === this.formDataRegistroPaciente.fechaSeleccionada) {
            this.horaSelectOptions = [...this.horaSelectOptions, { value: this.formDataRegistroPaciente.horaSeleccionada }]
          }

          // Ordenar las opciones en orden ascendente
          this.horaSelectOptions.sort((a, b) => {
            const timeA = DateTime.fromFormat(a.value, 'hh:mm a');
            const timeB = DateTime.fromFormat(b.value, 'hh:mm a');
            return timeA.toMillis() - timeB.toMillis();
          });

        },
        error: () => {
          this.dialog.open(ModalErrorGeneralComponent); // Muestra el modal de error
          }
      })

      this.selectedDate = event;
    }
  }

  public getInstituciones() {
    this.ObtenerInstitucionesService.getInstituciones().subscribe({
      next: (resp) => {
        this.institucionesOptions = resp.data;
      },
      error: (error) => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }

  public armadoRequest(formValues: { [key: string]: any }) {
    let formDataRegistroPaciente = this.formDataRegistroPaciente || {};
    /* se agregan los campos del formulario
    fecha y hora al obj del formulario datos paciente*/
    formDataRegistroPaciente = {
      ...formDataRegistroPaciente,
      ...formValues,
    };
    /*se formatea el valor tipoAtencion a upperCase*/
    if (formDataRegistroPaciente.tipoAtencion) {
      const tipoAtencion = formDataRegistroPaciente.tipoAtencion.toLowerCase();
      if (tipoAtencion === 'presencial (centro de salud)') {
        formDataRegistroPaciente.tipoAtencion = 'PRESENCIAL';
      }
      if (tipoAtencion === 'visita domiciliaria') {
        formDataRegistroPaciente.tipoAtencion = 'DOMICILIARIA';
      } else {
        formDataRegistroPaciente.tipoAtencion =
          formDataRegistroPaciente.tipoAtencion.toUpperCase();
      }
    }
    /*se rescata el nomre de la institucion segun el idInstitucion*/
    if (formDataRegistroPaciente.institucion) {
      const institucionSeleccionada = this.institucionesOptions.find(
        (institucion) =>
          institucion.idCentroMedico === formDataRegistroPaciente.institucion
      );

      if (institucionSeleccionada) {
        this.nombreInstitucionSeleccionada = institucionSeleccionada.nombre;
      } else {
        return;
      }
    }

    const requestData: ReqNuevoAgendamiento = {
      apellidos: formDataRegistroPaciente.apellidos,
      correoElectronico: formDataRegistroPaciente.email,
      fechaAtencion: formDataRegistroPaciente.fechaSeleccionada,
      horaAtencion: formDataRegistroPaciente.horaSeleccionada,
      idPrevision: formDataRegistroPaciente.prevision,
      idTipoDocumento: formDataRegistroPaciente.tipoDocumento,
      nombres: formDataRegistroPaciente.nombre,
      numeroDocumento:
        formDataRegistroPaciente.numeroDocumento ||
        formDataRegistroPaciente.numeroPasaporte ||
        formDataRegistroPaciente.numeroDocumentoOtros,
      telefono: formDataRegistroPaciente.numero,
      tipoAtencion: formDataRegistroPaciente.tipoAtencion,
      isUpdate: formDataRegistroPaciente.isUpdate,
      idAgendamientoToUpdate: formDataRegistroPaciente.idAgendamientoToUpdate
    };
    if (formDataRegistroPaciente.direccion){
      requestData.idDireccionGoogle = formDataRegistroPaciente.direccion;
      this.showdireccionCompleta = false;
    }
    if(formDataRegistroPaciente.direccionCompleta){
      requestData.direccionCompleta = formDataRegistroPaciente.direccionCompleta;
      this.showdireccionCompleta = true;
    }
    if (formDataRegistroPaciente.institucion)
      requestData.idCentroMedico = formDataRegistroPaciente.institucion;
    if (formDataRegistroPaciente.link)
      requestData.link = formDataRegistroPaciente.link;
    if(formDataRegistroPaciente.direccionCompleta){
      this.direccionCompleta = formDataRegistroPaciente.direccionCompleta;
      this.showdireccionCompleta = true;
      requestData.idDireccionGoogle = ''
      formDataRegistroPaciente.direccion = '';
    }

    this.ShareDataService.updateRequestData(requestData);
    this.nuevAConsulta(requestData);
  }
  private nuevAConsulta(req: ReqNuevoAgendamiento) {
    this._agendamientos.postNuevaConsulta(req).subscribe({
      next: (resp) => {
        this.nuevoAgendamiento = resp;
        if (resp) {
          this._fichaClinicaStore.newStore({
            idAgendamiento: resp.data.idAgendamiento,
            idPaciente: resp.data.idPaciente,
            estado: EstadoFichaClinica.AGENDADA,
            fechaAtencion: resp.data.fechaAtencion,
            horaAtencion: resp.data.horaAtencion,
            tipoAtencion: resp.data.tipoAtencion,
          });
          this.ShareDataService.setDataNuevaConsulta(resp);
          this.ShareDataService.setIdPaciente(resp.data.idPaciente);
          this.ShareDataService.setIdAgendamiento(resp.data.idAgendamiento);

          const institucion = this.institucionesOptions.find((inst) => {
            return inst.idCentroMedico === req.idCentroMedico;
          });

          this.dataModalNuevoAgendamiento(
            req.nombres,
            req.apellidos,
            institucion?.nombre || '',
            req.fechaAtencion,
            req.horaAtencion,
            req.tipoAtencion,
            req.link || '',
            this.direccion || '',
            this.direccionCompleta || '',
            this.showdireccionCompleta,
            this.formDataRegistroPaciente.casaDepto,
            this.formDataRegistroPaciente.isUpdate!
          );
        }
        this.formDataRegistroPaciente
        this.formDataRegistroPaciente.isUpdate = false;
        Object.keys(this.formDataRegistroPaciente).forEach((key) => {
          delete (this.formDataRegistroPaciente as any)[key];
        });
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }

  public dataModalNuevoAgendamiento(
    nombres: string,
    apellidos: string,
    institucion: string,
    fechaSelec: string,
    horaSelec: string,
    tipoConsultaSelec: string,
    link: string,
    direccion: string,
    direccionCompleta: string,
    flagDireccionCompleta: boolean,
    casaDpto: string,
    isUpdate: boolean
  ) {
    this.dialog.open(ModalNuevoAgendamientoCreadoComponent, {
      data: {
        nombres: nombres,
        apellidos: apellidos,
        institucion: institucion,
        fechaSelec: fechaSelec,
        horaSelec: horaSelec,
        tipoConsultaSelec: tipoConsultaSelec,
        link: link,
        direccion: direccion,
        direccionCompleta: direccionCompleta,
        flagDireccionCompleta: flagDireccionCompleta,
        casaDpto: casaDpto,
        isUpdate: isUpdate
      },
    });
  }

  private getCampoValorFom() {
    const forcampoValor: { [key: string]: any } = {};
    Object.keys(this.formHoraFecha.controls).forEach((key) => {
      const control = this.formHoraFecha.get(key);
      if (control) {
        forcampoValor[key] = control.value;
      }
    });
    return forcampoValor; // Retorna obj nombreCampo: valorCampo
  }

  async onSubmit() {
    if (this.formHoraFecha.valid) {
      const formValues = this.getCampoValorFom();
      this.armadoRequest(formValues);
    } else {
      return;
    }
  }
  errorMessages(): ErrorMessages | null {
    if (this.hora.hasError('required') && this.hora.touched) {
      return { required: 'Selecciona la fecha de la consulta', pattern: '' };
    }
    return null; // No hay errores
  }

  public volverFormDatosPaciente() {
    let url = '';
    if(this.formDataRegistroPaciente.isUpdate) {
      url = `/ingreso/${this.formDataRegistroPaciente.idAgendamientoToUpdate}`;
    } else {
      url = '/ingreso';
    }
    return this.router.navigate([url], {
      state: {
        formDataRegistroPaciente: this.formDataRegistroPaciente,
        isClicked: true,
      },
    });
  }
}
