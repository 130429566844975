import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IDataPacienteForm } from '../interfaces/paciente-form.interface';
import { IDataNuevaConsulta } from '../interfaces/nuevaConsulta.interface';
import { IDataExtPaciente } from '../interfaces/exite-paciente.interface';

export const INITIAL_STATE_FORM: IDataPacienteForm = {
  apellidos: '',
  casaDepto: '',
  direccion: '',
  direccionCompleta: '',
  email: '',
  fechaSeleccionada: '',
  horaSeleccionada: '',
  institucion: '',
  link: '',
  nombre: '',
  numero: '',
  numeroDocumento: '',
  numeroDocumentoOtros: '',
  numeroPasaporte: '',
  prevision: '',
  tipoAtencion: '',
  tipoDocumento: '',
};

@Injectable({
  providedIn: 'root',
})
export class ShareDataService {
  private requestDataSubject = new BehaviorSubject<any>(null);

  private idPacienteSource = new BehaviorSubject<string | null>(null);
  private idAgendamientoSource = new BehaviorSubject<string | null>(null);
  idPaciente$ = this.idPacienteSource.asObservable();
  idAgendamiento$ = this.idAgendamientoSource.asObservable();
  private dataNuevoAgendamiento = new BehaviorSubject<any | null>(null);
  private dataExistePaciente = new BehaviorSubject<
    any | Partial<IDataExtPaciente>
  >(null);
  dataAgendamiento$ = this.idPacienteSource.asObservable();
  requestData$ = this.requestDataSubject.asObservable();
  private formDataSubject = new BehaviorSubject<IDataPacienteForm>(
    INITIAL_STATE_FORM
  );

  private refreshCalendarSubject = new Subject<void>();
  refreshCalendar$ = this.refreshCalendarSubject.asObservable();

  updateRequestData(data: any) {
    this.requestDataSubject.next(data);
  }

  setIdPaciente(idPaciente: string) {
    this.idPacienteSource.next(idPaciente);
  }

  setIdAgendamiento(idAgendamiento: string) {
    this.idAgendamientoSource.next(idAgendamiento);
  }

  setFormData(data: IDataPacienteForm) {
    this.formDataSubject.next(data);
  }
  getFormData() {
    return this.formDataSubject.asObservable();
  }

  setDataNuevaConsulta(dataNuevaConsulta: IDataNuevaConsulta) {
    this.dataNuevoAgendamiento.next(dataNuevaConsulta);
  }
  getDataNuevaConsulta() {
    return this.dataNuevoAgendamiento;
  }

  setExistePaciente(exitePaciente: Partial<IDataExtPaciente>) {
    this.dataExistePaciente.next(exitePaciente);
  }

  getDataExistePaciente() {
    return this.dataExistePaciente;
  }
  refreshCalendar() {
    this.refreshCalendarSubject.next();
  }
}
