import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import {
  IDataExamn,
  IDataTypeExamn,
  IGenericResponse,
} from '../interfaces/examenes.interface';

@Injectable({
  providedIn: 'root',
})
export class TabExamenesService {
  private apiUrl = environment.baseURL + 'datos-medicos';
  private filesApiUrl = environment.baseURL + 'files';

  constructor(private httpClient: HttpClient) {}

  getTypesExams(): Observable<IGenericResponse<IDataTypeExamn[]>> {
    return this.httpClient.get<IGenericResponse<IDataTypeExamn[]>>(
      this.apiUrl + '/tipos-examenes'
    );
  }

  getExamsByType(): Observable<IGenericResponse<IDataExamn[]>> {
    return this.httpClient.get<IGenericResponse<IDataExamn[]>>(
      this.apiUrl + `/examenes`
    );
  }

  uploadFile(files: File[], idPaciente: string, idAgendamiento: string): Observable<any> {
    const formData = new FormData();
    for(const file of files) {
      formData.append('files', file);
    }
    return this.httpClient.post(this.filesApiUrl + `/upload/${idPaciente}/${idAgendamiento}`, formData);
  }
}
