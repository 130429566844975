
<h2 mat-dialog-title>Datos del paciente</h2>
<mat-dialog-content>
  <form>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>DNI</mat-label>
      <input matInput type="text" placeholder="Ingrese el DNI" />
      <mat-icon matSuffix>check_circle</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Nombre completo</mat-label>
      <input matInput type="text" placeholder="Ingrese el nombre completo" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Correo electrónico</mat-label>
      <input matInput type="email" placeholder="Ingrese el correo electrónico" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Teléfono de contacto</mat-label>
      <input matInput type="tel" placeholder="Ingrese el teléfono de contacto" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Previsión</mat-label>
      <mat-select>
        <mat-option value="soat">SOAT</mat-option>
        <mat-option value="otra">Otra</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Tipo de atención</mat-label>
      <mat-select>
        <mat-option value="domiciliaria">Visita domiciliaria</mat-option>
        <mat-option value="otra">Otra</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Dirección</mat-label>
      <textarea matInput placeholder="Calle y número"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Nº Casa o departamento</mat-label>
      <input matInput type="text" placeholder="Ingrese Nº Casa o departamento" />
    </mat-form-field>
  </form>

</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn-consulta">
    <i class="icon-calendar"></i> Nueva consulta
  </button>
</mat-dialog-actions>

