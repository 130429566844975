<soph-header-sophia></soph-header-sophia>

<div class="header-container">
  <p class="titulo-agenda">Tu agenda</p>
</div>

<div class="header-container2">

    <button mat-fab extended
            (click)="openModal()">
      <mat-icon>calendar_month</mat-icon>
      Nueva consulta
    </button>

  <div class="search-container">
    <input type="text" placeholder="¿Qué buscas?" />
    <button class="btn-filter">
      <i class="icon-filter"></i>
    </button>
  </div>

  <div>
    <button  class="btn-menu"
             (click)="openBlockModal" mat-mini-fab aria-label="Example icon button with a menu icon">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</div>


<div class="container">
  <div class="column right">
    <full-calendar [options]="calendarOptions"
     ></full-calendar>
  </div>
</div>



