<div class="card-container">
  <div class="card-header" style="margin-bottom: 5px;">
    <h2 style="color: white; font-family: Open Sans; font-size: 18px; font-weight: 700; margin-block-end: 0em">Fecha
      Inicio</h2>
    <div class="section-close">
      <button (click)="dialogRef.close()" tabindex="-1" mat-icon-button matTooltip="Cerrar"
        aria-label="icon-button close">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="card-row" style="gap: 40px">
      <div style="display: flex; gap: 4px">
        <img src="assets/icons/calendar-info-consulta.svg" style="display: table-cell" alt="house" />
        <span class="texto-light">{{ fechaInicio }}</span>
      </div>
      <div style="display: flex; gap: 4px">
        <img src="assets/icons/clock-info-consulta.svg" style="display: table-cell" alt="house" />
        <span class="texto-light">{{ horaInicio }}</span>
      </div>
    </div>
  </div>
  <div>
    <h2 style="color: white; font-family: Open Sans; font-size: 18px; font-weight: 700; margin-block-end: 0.3em">Fecha
      término</h2>
    <div class="card-row" style="display: flex; gap: 40px">
      <div style="display: flex; gap: 4px">
        <img src="assets/icons/calendar-info-consulta.svg" style="display: table-cell" alt="house" />
        <span class="texto-light">{{ fechaFin }}</span>
      </div>
      <div style="display: flex; gap: 4px">
        <img src="assets/icons/clock-info-consulta.svg" style="display: table-cell" alt="house" />
        <span class="texto-light">{{ horaFin }}</span>
      </div>
    </div>
  </div>

  <div class="card-footer" style="margin-top: 20px;">
    <div>
      <button class="btn edit-btn texto-info" (click)="dialogRef.close()">Cancelar</button>
    </div>
    <div>
      <button class="btn go-btn texto-info" (click)="desbloqueoHorario()">Desbloquear horario</button>
    </div>
  </div>
</div>