import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { InputFormFieldComponent } from '../../../../../shared/components/input-form-field/input-form-field.component';
import { MultiSelectSearchFormFieldComponent } from '../../../../../shared/components/multi-select-search-form-field/multi-select-search-form-field.component';
import { SelectFormFieldComponent } from '../../../../../shared/components/select-form-field/select-form-field.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TabTratamientoService } from '../../../../../shared/services/tab-tratamiento.service';
import { distinctUntilChanged, Subscription } from 'rxjs';
import {
  IDataProcedimiento,
  IProcedGuardado,
  IProcedSelected,
} from '../../../../../shared/interfaces/procedimientos.interface';
import { FirstCharactersPipe } from '../../../../../shared/pipes/first-characters.pipe';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'soph-procedimientos',
  standalone: true,
  imports: [
    CommonModule,
    MultiSelectSearchFormFieldComponent,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule
  ],
  templateUrl: './procedimientos.component.html',
  styleUrl: './procedimientos.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcedimientosComponent {
  formProcedimientos: FormGroup;
  public procedimientosOptions = signal<Array<IDataProcedimiento>>([]);
  procedOptions: IDataProcedimiento[] = [];
  idsProcedSelected: string[] = [];
  procedimientosSelected: IProcedSelected[] = [];
  procedimientosGuardados: IProcedGuardado[] = [];

  originalFormValues = signal<{ procedimientos: string[] }>({
    procedimientos: [],
  });

  subs: Subscription = new Subscription();

  get procedimientos() {
    return this.formProcedimientos.get('procedimientos')!;
  }

  constructor(
    private fb: FormBuilder,
    private tabTratamientoService: TabTratamientoService
  ) {
    this.formProcedimientos = this.fb.group({
      procedimientos: [''],
    });

    this.procedimientos?.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((values: string[]) => {
        if (values && values.length > 0) {
          this.onProcedSelected(values);
          this.idsProcedSelected = values;
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  ngOnInit(): void {
    this.llamarServicios();
  }
  setOriginalValues(procedimientos: string[]) {
    this.originalFormValues.set({ procedimientos });
  }

  llamarServicios() {
    this.procedimientosOptions.set(this.tabTratamientoService.getProcedimientos());
    this.procedOptions = this.tabTratamientoService.getProcedimientos();
  }
  showProcedimientosGuardadss(data: string[]) {
    if(data && data.length > 0) {
      data.forEach((item) => {
        const procedimiento = this.procedimientosOptions().find(
          (o) => o.idProcedimiento === item
        );
        if (procedimiento) {
          this.procedimientosGuardados.push(procedimiento);
        }
      });
    }
    this.resetForm();
  }
  editarProcedimientos() {
    if(this.procedimientosGuardados.length === 0) return;
    // Setear Valores originales en inputs
    this.formProcedimientos.setValue(this.originalFormValues());
    // Vaciar medicamentos guardados
    this.procedimientosGuardados = [];
  }
  resetForm() {
    this.procedimientosSelected = [];
    this.idsProcedSelected = [];
    this.formProcedimientos.reset();
  }

  armarRequestProcedimientos(): string[] {
    this.originalFormValues.set(this.formProcedimientos.value)
    if(this.procedimientos.value && this.procedimientos.value.length > 0) {
      return this.procedimientos.value;
    } else {
      return [];
    }
  }

  disableSelectorProcedimientos() {
    this.procedimientos.disable();
  }
  enableSelectorProcedimientos() {
    this.procedimientos.enable();
  }

  unselectProced(idTerapia: string) {
    const terapias = this.procedimientos.value;
    const newTerapias: string[] = [];
    terapias.forEach((id: string) => {
      if (idTerapia != id) {
        newTerapias.push(id);
      }
    });
    this.procedimientos.setValue(newTerapias);
  }

  onProcedSelected(idsProcedEmitidos: string[]) {
    this.procedimientosSelected = [];
    if (idsProcedEmitidos) {
      idsProcedEmitidos.forEach((id) => {
        const proced = this.procedOptions.find(
          (proced) => proced.idProcedimiento === id
        );
        if (proced) {
          this.procedimientosSelected.push(proced);
        }
      });
    }
  }
}
