import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'soph-container-diagnosticos',
  standalone: true,
  imports: [],
  templateUrl: './container-diagnosticos.component.html',
  styleUrl: './container-diagnosticos.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerDiagnosticosComponent {

}
