import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { Observable } from 'rxjs';
import { IRespDiagnosticos } from '../interfaces/diagnosticos.interface';

@Injectable({
  providedIn: 'root',
})
export class ServiceFormDiagnosticoService {
  private endpointUrlBase = `${environment.baseURL}datos-medicos`;
  private clientId = '4b9b9ab5b734408d915ec31751bbf114';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) {}

  getDiagnosticos(): Observable<IRespDiagnosticos> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.get<IRespDiagnosticos>(
      this.endpointUrlBase + '/diagnosticos',
      {
        headers,
        params,
      }
    );
  }
}
