import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {IRespExistePaciente} from "../interfaces/exite-paciente.interface";
import {AuthenticationService} from "../../pages/services/authentication.service";
import {IResponseCentrosMedicos} from "../interfaces/centros-medicos.interface";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObtenerInstitucionesService {
  private apiUrl = environment.baseURL + 'datos-medicos/centros-medicos';

  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) {}

  getInstituciones(): Observable<IResponseCentrosMedicos> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', '4b9b9ab5b734408d915ec31751bbf114')
      .set('numeroDocumento', '42181478-9');
    return this.httpClient.get<IResponseCentrosMedicos>(this.apiUrl, {headers, params});
  }

}
