<mat-dialog-content class="modal-content">
  <mat-icon style="font-size: 36px; color: #d96704; height: 36px; width: 36px"
    >error</mat-icon
  >
  <h2>¿Confirmas que deseas anular la consulta?</h2>
</mat-dialog-content>

<mat-dialog-actions class="modal-actions">
  <button mat-stroked-button class="btn-cancelar" (click)="cancelar()">
    Cancelar
  </button>
  <button
    class="button-confirm"
    (click)="anularConsulta()"
    mat-flat-button
    color="primary"
    mat-flat-button
  >
    Anular consulta
  </button>
</mat-dialog-actions>
