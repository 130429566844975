import { ChangeDetectionStrategy, Component, ChangeDetectorRef, effect, OnInit, OnDestroy,} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {CommonModule, registerLocaleData} from '@angular/common';
import { Validators  } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule} from "@angular/material/select";
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AgendamientosService } from '../../../services/agendamientos.service';
import { ModalErrorGeneralComponent } from '../../modal-error-general/modal-error-general.component';
import { HeaderSophiaComponent } from '../header-sophia/header-sophia.component';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { SelectFormFieldComponent } from '../../../../shared/components/select-form-field/select-form-field.component';
import { CustomDateAdapter } from '../nuevo-agendamiento/ingreso-hora-fecha/ingreso-hora-fecha.component';
import localeEs from '@angular/common/locales/es-CL';
import { MotivosBloqueoHorarioService } from '../../../../shared/services/motivos-bloqueo-horario.service';
import { IDataMotivos } from '../../../../shared/interfaces/motivos-bloqueo.interface';
import { BloqueoHorariosService } from '../../../../shared/services/bloqueo-horarios.service';
import { ReqBloqueoHorario } from '../../../../shared/interfaces/bloqueo-horario.interface';
import { parseToUTC } from '../../../../shared/helpers/date.helper';
import { IDataBloqueo } from '../../../../shared/interfaces/bloqueoHorario.interface';
import { ModalExitoBloqueoHorarioComponent } from '../../../../shared/components/modal-exito-bloqueo-horario/modal-exito-bloqueo-horario.component';

registerLocaleData(localeEs);
interface ISelectOption {
  value: string;
}

@Component({
  selector: 'soph-modal-bloqueo-horario',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    HeaderSophiaComponent,
    MatCardModule,
    MatAutocompleteModule,
    MatCardModule,
    MatDatepickerModule,
    SelectFormFieldComponent,
    MatNativeDateModule,
  ],
    providers: [
      MatDatepickerModule,
      provideNativeDateAdapter(),
      { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
      { provide: DateAdapter, useClass: CustomDateAdapter },
    ],
  templateUrl: './modal-bloqueo-horario.component.html',
  styleUrl: './modal-bloqueo-horario.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class ModalBloqueoHorarioComponent implements OnInit, OnDestroy {
  /*GET CAMPOS FORM*/
  get horaInicio(): FormControl {
    return this.formHoraFecha.get('horaInicio') as FormControl;
  }
  get horaTermino(): FormControl {
    return this.formHoraFecha.get('horaTermino') as FormControl;
  }
  get motivo(): FormControl {
    return this.formHoraFecha.get('motivo') as FormControl;
  }
  get start(): FormControl {
    return this.formHoraFecha.get('start') as FormControl;
  }
  get end(): FormControl {
    return this.formHoraFecha.get('end') as FormControl;
  }

  formHoraFecha: FormGroup;
  horaSelectOptions: ISelectOption[] = [];
  motivosBloqueo: IDataMotivos[] = [];
  selected: Date | null = null;
  subs = new Subscription();
  respBloqueo!: IDataBloqueo;
  flagFechaInvalida: boolean = true;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private _agendamientos: AgendamientosService,
    private _motivosBloqueo: MotivosBloqueoHorarioService,
    private _bloqueoHorariosService: BloqueoHorariosService,
    private cdr: ChangeDetectorRef
  ) {
    effect(() => {
    });
    this.formHoraFecha = this.fb.group({
      start: [null, Validators.required],
      end: [null, Validators.required],
      horaInicio: ['', Validators.required],
      horaTermino: ['', Validators.required],
      motivo: ['', Validators.required],
    },
  );
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.rangoHorario();
    this.getMotivosBloqueo();
    this.cdr.detectChanges();
  }


  public rangoHorario() {
    this._agendamientos.getRangoHora().subscribe({
      next: (resp) => {
        this.horaSelectOptions = resp.data.map((hora: string) => ({
          value: hora,
        }));
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }

  public getMotivosBloqueo() {
    this._motivosBloqueo.getMotivosBloqueo().subscribe({
      next: (resp) => {
        this.motivosBloqueo = resp.data;
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }


  isBtnDisables(){
     return this.formHoraFecha.invalid || !this.flagFechaInvalida;
  }
  async onSubmit() {    
    if (!this.formHoraFecha.valid) return;
    const fechaInicio = this.start.value.toISOString().split('T')[0];
    const fechaFin = this.end.value.toISOString().split('T')[0];


    if(!fechaInicio || !fechaFin) return;

    if (fechaFin < fechaInicio) {
      this.flagFechaInvalida = false;
      return;
    }  else {
      this.flagFechaInvalida = true;
    }

  const reqBloqueo: ReqBloqueoHorario = {
      fechaInicio,
      fechaFin,
      horaInicio: this.horaInicio.value,
      horaFin: this.horaTermino.value,
      idTipoBloqueo: String(this.motivo.value)
    }
    
    this._bloqueoHorariosService.postBloquearHorarios(reqBloqueo).subscribe({
      next: (resp) => {
        this.respBloqueo = resp;
        if(resp){
          const dialogRef = this.dialog.open(ModalExitoBloqueoHorarioComponent, {});
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
          });
        }
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
    this.cdr.detectChanges();
  }

  disableDays = (d: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return d ? d >= today : false;
  };
}
