import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatRadioButton, MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { ErrorMessages } from '../../interfaces/errorMesagges.interface';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'soph-radio-button-form-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
     MatRadioModule,
     MatFormFieldModule,
     MatInputModule
    
  ],
  templateUrl: './radio-button-form-field.component.html',
  styleUrl: './radio-button-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioButtonFormFieldComponent {
  @Input() control: FormControl = new FormControl();
  @Input() options: string[] = []; 
  @Input() label: string = ''; 
  @Input() errorMessages!: ErrorMessages;
  @Output() selectionChange = new EventEmitter<string>(); 

  onSelectionChange(event: MatRadioChange) {
    this.selectionChange.emit(event.value);
  }
  get hasError() {
    return this.control?.touched && this.control?.invalid;
  }

  get errorMessage() {
    if (!this.control) return '';
    const errors = this.control.errors;
    if (errors) {
      for (const error of Object.keys(errors) as (keyof ErrorMessages)[]) {
        if (errors[error]) {
          return this.errorMessages[error] || '';
        }
      }
    }
    return '';
  }

  ngOnInit() {}
}
