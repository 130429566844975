<div class="layaout-login col-md-12">
  <div  class="col-md-5 contenedor-login">
    <form [formGroup]="createPass">
      <div class="row contenedor-crear-pass">
        <p>Crea tu contraseña</p>
        <div>
          <p  class="texto-ayuda">
            Debe tener al menos 6 caracteres, incluyendo una letra mayúscula y un número
        </div>

        <div class="contenedor-inputs">
          <mat-form-field  class="estilo-input" appearance="outline">
            <mat-label>Contraseña</mat-label>
            <input matInput
                   formControlName="password"
                   [type]="hide ? 'password' : 'text'"
                   (keydown.space)="$event.preventDefault()"
                   maxlength="10"
                   required>
            <button mat-icon-button matSuffix
                    type="button"
                    (click)="toggleVisibility()"
                    [attr.aria-label]="'Mostrar u ocultar contraseña'"
                    [attr.aria-pressed]="!hide">
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="createPass.get('password')?.touched
          && createPass.get('password')?.invalid">
              {{getPasswordErrorMessage()}}
            </mat-error>
          </mat-form-field>

          <mat-form-field  class="estilo-input" appearance="outline">
            <mat-label>Repite la contraseña</mat-label>
            <input matInput
                   formControlName="confirmPassword"
                   [type]="hide ? 'password' : 'text'"
                   (keydown.space)="$event.preventDefault()"
                   maxlength="10"
                   required>
            <button mat-icon-button matSuffix
                    type="button"
                    (click)="toggleVisibility()"
                    [attr.aria-label]="'Mostrar u ocultar contraseña'"
                    [attr.aria-pressed]="!hide">
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error *ngIf="createPass.get('confirmPassword')?.touched
          && createPass.get('confirmPassword')?.invalid || createPass.hasError('passwordsMismatch')">
              {{getConfirmPasswordMessageError()}}
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="showLinkEnlace">
          <div class="alert-invalidos">
            <mat-icon class="cancel">cancel</mat-icon>
            <p class="estilo-texto-alert">
              El enlace para restablecer la contraseña ha expirado.</p>
          </div>
          <div class="texto-recuperar-pass">
            <a  class="hipervinculo" (click)="reenvioEmail()" >Reenviar enlace</a>
          </div>
        </div>

        <div class="row section-button-recuperar-pass">
          <div>
            <button mat-flat-button
            class="btn-crear-pass"
            color="primary"
                    [disabled]="createPass.invalid"
                    (click)="createPassword()">
              Crear contraseña
            </button>
          </div>
          <div>
            <button mat-stroked-button color="primary"
                    (click)="volverAlogin()">
              Cancelar
            </button>
          </div>
        </div>

        <div class="section-footer">
          <p class="texto-ayuda-footer">Protegido por reCAPTCHA</p>
          <p class="footer-login">Privacidad y condiciones</p>
        </div>
      </div>
    </form>
  </div>
</div>

