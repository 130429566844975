import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IDataOperacion, IRespOperaciones } from '../interfaces/operaciones.interface';
import { IDataProcedimiento, IresProcedimiento } from '../interfaces/procedimientos.interface';
import { IDataTerapias, IResTerapias } from '../interfaces/terapias.interface';
import { IDataMedicamento, IRespMedicamentos } from '../interfaces/tratamiento/medicamentos.interface';
import { IDataFrecuencias, IRespFrecuencias } from '../interfaces/tratamiento/terapias.interface';
import { IDataViasAdministracion, IRespViasAdministracion } from '../interfaces/vias-admin.interface';

@Injectable({
  providedIn: 'root',
})
export class TabTratamientoService {
  private apiUrl = environment.baseURL + 'datos-medicos';

  constructor(private httpClient: HttpClient) {}

  getMedicamentos(): IDataMedicamento[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const medicamentos = jsonData.data.medicamentos;
    return medicamentos;
  }

  getProcedimientos(): IDataProcedimiento[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const procedimientos = jsonData.data.procedimientos;
    return procedimientos
  }

  getTerapias(): IDataTerapias[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const terapias = jsonData.data.terapias;
    return terapias;
    
  }

  getOperaciones(): IDataOperacion[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const operaciones = jsonData.data.operaciones;
    return operaciones;
  }

  getViasAdministracion(): IDataViasAdministracion[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const viasAdministracion = jsonData.data.viasAdministracion;
    return viasAdministracion;

  }
  getFrecuencias(): IDataFrecuencias[] {
    const data = localStorage.getItem('datosMedicos') || '';
    const jsonData = JSON.parse(data);
    const frecuencias = jsonData.data.frecuencias;
    return frecuencias;
  }
}
