import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthenticationService} from "../../pages/services/authentication.service";
import {Observable} from "rxjs";
import {IRespDireccion} from "../interfaces/buscar-direccion.interface";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuscardorDireccionService {

  private apiUrl = environment.baseURL + 'direcciones/autocomplete';

  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) { }

  getDirecciones(direccion: string): Observable<IRespDireccion> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', '4b9b9ab5b734408d915ec31751bbf114')
    return this.httpClient.get<IRespDireccion>
    (`${this.apiUrl}?address=${direccion}`, {headers, params});
  }
}
