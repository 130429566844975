import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { TipoReporteResumenClinico } from '../../../shared/enums';

@Injectable({
  providedIn: 'root',
})
export class ReportesService {
  private readonly _reportesBaseUrl = `${environment.baseURL}reportes`;
  private readonly _http = inject(HttpClient);

  obtenerResumenClinicoPorNombre(
    nombre: TipoReporteResumenClinico,
    idAgendamiento: string
  ): Observable<Blob> {
    return this._http.get(
      `${this._reportesBaseUrl}/resumen-clinico/${nombre}/${idAgendamiento}`,
      {
        responseType: 'blob',
      }
    );
  }

  enviarResumenClinicoPorNombre(
    nombre: TipoReporteResumenClinico,
    idAgendamiento: string
  ): Observable<any> {
    return this._http.get(
      `${this._reportesBaseUrl}/resumen-clinico/enviar/${nombre}/${idAgendamiento}`
    );
  }
}
