<div class="text-xs">
  <div class="contenedor-formulario">
    <div class="input-container">
      <input class="telInput"
             type="tel"
             [id]="idInput"
             [placeholder]="'Teléfono contacto'"
             (keydown)="restrictNonNumericInput($event)"
             [formControl]="control">
      <p class="prefix" >+{{ telephonePrefix }}</p>
      <mat-hint *ngIf="!control.touched" class="form-label-msg" style="z-index: 0;">{{ hintText }}</mat-hint>
      <mat-error *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
        {{ 'Debes ingresar el teléfono de contacto'}}
      </mat-error>
      <mat-error *ngIf="control.hasError('pattern') && control.touched" class="form-error-msg">
        {{ 'Debe contener un código de área + 8 dígitos' }}
      </mat-error>
      <mat-error *ngIf="control.hasError('invalidPhone') && control.touched && !control.hasError('required')" class="form-error-msg">
        {{ 'Debe contener un código de área + 8 dígitos' }}
      </mat-error>
    </div>
  </div>
</div>

