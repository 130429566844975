import { DateTime } from "luxon"

const DATE_TIME_FORMAT = "yyyy-MM-dd hh:mm a";
const zonaHoraria = Intl.DateTimeFormat().resolvedOptions().timeZone;
const zonaHorariaLima = "America/Lima";


export const parseFechaHoraToUtc = (fecha: string, hora: string): DateTime<boolean> => {
  return DateTime.fromFormat(`${fecha} ${hora}`, DATE_TIME_FORMAT, {
    zone: zonaHorariaLima,
  });
}

export const parseToUTC = (fecha: Date, hora: string): string => {
   const fechaLuxon = DateTime.fromJSDate(fecha, { zone: "UTC" }).setZone(zonaHoraria, { keepLocalTime: true });
   const fechaStr = fechaLuxon.toFormat("yyyy-MM-dd");
   const fechaLima = DateTime.fromFormat(`${fechaStr} ${hora}`, DATE_TIME_FORMAT, {
     zone: zonaHorariaLima,
   });
   return fechaLima.isValid ? fechaLima.toISO() : "Fecha inválida";
};

// Parsear fecha en formato ISO a formato local '2025-03-10T15:00:00.000Z'
export const convertirUTCaLocal = (fechaUTCString: string): string => {
  const fechaUTC = DateTime.fromISO(fechaUTCString, { zone: "utc" });
  const fechaLocal = fechaUTC.setZone(zonaHoraria);
  return fechaLocal.toISO() ?? "Invalid date";
}
