import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ModalHistory360Component } from '../../../../shared/components/modal-history-360/modal-history-360.component';
import { ShareDataService } from '../../../../shared/services/share-data.service';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { HistorialService } from '../../../../shared/services/historial.service';
import { ResponseApiModel } from '../../../../shared/interfaces/api-response.model';
import { Historial360 } from '../../../../interfaces/historial-360.interface';
import { IDataExtPaciente } from '../../../../shared/interfaces/exite-paciente.interface';
import { ModalResumenAtencionComponent } from '../../../../shared/components/modal-resumen-atencion/modal-resumen-atencion.component';
import { ObtenerAtencionesService } from '../../../../shared/services/obtener-atenciones.service';
import { Agendada } from '../../../../shared/interfaces/atenciones-medicas.interface';
import { HistoricoConsultaService } from '../../../../shared/services/historico-consulta.service';
import { IRespDataAtencion } from '../../../../shared/interfaces/historicoConsulta.interface';
import { ResumenClinicoService } from '../../../services/reportes/resument-clinico/resumen-clinico.service';
import { UtilServiceService } from '../../../../shared/services/util-service.service';

@Component({
  selector: 'soph-menu-lateral-info',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatExpansionModule,
    NgOptimizedImage,
    CommonModule,
  ],
  templateUrl: './menu-lateral-info.component.html',
  styleUrl: './menu-lateral-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuLateralInfoComponent implements OnInit, OnDestroy {
  isExpanded = false;

  private matDialog = inject(MatDialog);
  private shouldSetAnamnesis = true;
  existePaciente = new BehaviorSubject<any | null>(null);
  @Input() nombrePaciente!: string;
  @Input() apellidosPaciente!: string;
  @Input() historiaClinica!: string;
  @Input() dniPaciente!: string;
  @Input() correoPaciente!: string;
  @Input() telefonoPaciente!: string;
  @Input() previsionPaciente!: string;
  @Input() tipoDocumentoPaciente!: string;
  @Input() valueTipoDocumentoPaciente!: string;
  @Output() hasToSetAnamnesis: EventEmitter<boolean> = new EventEmitter<boolean>();
  showCardPaciente = false;
  dataHistorial360: Historial360 | null = null;
  subs: Subscription = new Subscription();
  atenciones: WritableSignal<Agendada[]> = signal([]);
  resumenConsulta: IRespDataAtencion | null = null;

  contadorAtencionesRealizadas: WritableSignal<Agendada[]> = signal([]);

  readonly resumenClinico = inject(ResumenClinicoService);

  constructor(
    private readonly _shareDataService: ShareDataService,
    private readonly fichaClinicaStore: FichaClinicaStore,
    private readonly historialService: HistorialService,
    private readonly _obtenerAtencionesService: ObtenerAtencionesService,
    private readonly _historicoConsultaService: HistoricoConsultaService,
  ) {}
  ngOnInit(): void {
    this.getDataExistePaciente();
    this._getDataModal360();
    this.getAtenciones();
  }
  private getDataExistePaciente() {
    this._shareDataService.getDataExistePaciente().subscribe({
      next: (data) => {
        const dataPaciente = data as Partial<IDataExtPaciente>;
        if (data) {
          this.showCardPaciente = true;
          this.nombrePaciente = dataPaciente.nombres ?? '';
          this.apellidosPaciente = dataPaciente.apellidos ?? '';
          this.previsionPaciente = dataPaciente.prevision
            ? dataPaciente.prevision.nombre
            : '';
          this.correoPaciente = dataPaciente.correoElectronico ?? '';
          this.telefonoPaciente = dataPaciente.telefono ?? '';
          this.tipoDocumentoPaciente = dataPaciente.tipoDocumento
            ? dataPaciente.tipoDocumento.nombre
            : '';
          this.valueTipoDocumentoPaciente = dataPaciente.numeroDocumento ?? '';
        }
      },
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  public getAtenciones() {
    const idPaciente = this.fichaClinicaStore.values.idPaciente;

    this._obtenerAtencionesService.getAtenciones(idPaciente ?? '').subscribe({
      next: (resp) => {
        if (resp.success && resp.data) {
          const todasLasAtenciones: Agendada[] = [];
          if(resp.data.REALIZADA?.length > 0) { todasLasAtenciones.push(...resp.data.REALIZADA); }
          if(resp.data.ANULADA?.length > 0) { todasLasAtenciones.push(...resp.data.ANULADA); }
          if(resp.data.SUSPENDIDA?.length > 0) { todasLasAtenciones.push(...resp.data.SUSPENDIDA); }
          this.contadorAtencionesRealizadas.set(resp.data.REALIZADA);
          todasLasAtenciones.sort((a, b) => new Date(b.fechaAtencion).getTime() - new Date(a.fechaAtencion).getTime());
          this.atenciones.set(todasLasAtenciones);
        } else {
        }
      },
      error: (err) => {}
    });
  }

  private _getDataModal360() {
    const { idAgendamiento } = this.fichaClinicaStore.values;
    const sub = this.historialService
      .getHistorial_360(idAgendamiento ?? '')
      .pipe(map((response: ResponseApiModel<Historial360>) => response.data))
      .subscribe((historial) => {
        this.dataHistorial360 = historial;
        this.openHistory360();
      });
    this.subs.add(sub);
  }

  public openHistory360(): void {
    if (!this.dataHistorial360) return;
    if ( this.shouldSetAnamnesis ) {
      this.hasToSetAnamnesis.emit( true );
      this.shouldSetAnamnesis = false;
    }
    this.matDialog.open(ModalHistory360Component, {
      data: this.dataHistorial360,
      maxWidth: '900px',
      minWidth: '400px',
      minHeight: '400px',
    });
  }

  public openResumenConsulta(idAgendamiento: string): void {
    if (!this.atenciones) return;
    if (!idAgendamiento) return;

    this._historicoConsultaService.getResumenConsulta(idAgendamiento).subscribe({
      next: (resp) => {
        if (resp.data) {
          this.resumenConsulta = resp;

          this.matDialog.open(ModalResumenAtencionComponent, {
            data: this.resumenConsulta,
            maxWidth: '900px',
            minWidth: '400px',
            minHeight: '400px',
          });
        }
      },
      error: (err) => console.error('Error',err),
    });

  }

  public capitalizeName(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  showReceta(idAgendamiento: string){
    this.resumenClinico.openReceta(idAgendamiento);
  }


}
