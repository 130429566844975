import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'soph-modal-confirm-cambio-datos',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './modal-confirm-cambio-datos.component.html',
  styleUrl: './modal-confirm-cambio-datos.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmCambioDatosComponent {
  public formDataRegistroPaciente: any; 
  constructor(@Inject(MAT_DIALOG_DATA)
   public data: { formDataRegistroPaciente: any },
   private router: Router,
   private dialogRef:
    MatDialogRef<ModalConfirmCambioDatosComponent>) {}

  ngOnInit(): void {
   if(this.data){
      this.formDataRegistroPaciente = 
      this.data.formDataRegistroPaciente;
   } 
  }

  actualizarDatos() {
    this.dialogRef.close( {
     update: true,
     formDataRegistroPaciente: this.formDataRegistroPaciente
    });
  }

  cancelar() {
    this.dialogRef.close({
      updated: false, 
      formDataRegistroPaciente: this.formDataRegistroPaciente
    });
  }
}
