<h3>Cirugías</h3>
<p>Busca y selecciona las cirugías que requiere el paciente</p>
<soph-multi-select-search-form-field
    [formGroup]="formOperaciones"
    controlName="operaciones"
    placeholder="Seleccionar cirugía"
    [values]="operacionesOptions"
    [limitValues]="50"
    idField="idOperacion"
    viewField="nombre">
</soph-multi-select-search-form-field>
<div *ngIf="operaciones.value && operaciones.value.length > 0" class="selected-items">
  <h3>cirugías seleccionadas</h3>
  <div class="form-section-grupo" *ngFor="let operacion of operacionesSelected">
    <mat-divider></mat-divider>
    <mat-accordion class="full-width custom-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="style-tittle-colapse">
            {{ operacion.codigo+' '+ operacion.nombre | firstCharacters }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="section-eliminar">
          <button
            mat-button
            (click)="unselectOperacion(operacion.idOperacion)"
          >
            <mat-icon svgIcon="trash"></mat-icon>
            Eliminar
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<!-- operacions guardados -->
@if(operacionesGuardados.length > 0) {
<div class="parent-container">
  <div class="container-remedios">
    <div class="remedio-guardado" *ngFor="let operacion of operacionesGuardados">
      {{ operacion.codigo+' '+ operacion.nombre | firstCharacters }}
    </div>
  </div>
</div>
}

