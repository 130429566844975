import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IRespDesbloqueo } from '../interfaces/desbloqueoHorario.interface';

@Injectable({
  providedIn: 'root'
})
export class DesbloqueoHorarioService {
  private apiUrl = environment.baseURL + 'bloqueos' ;
  constructor(
    private httpClient: HttpClient) { }

    deleteDesbloqueoHorario(idAgendamiento: string): Observable<IRespDesbloqueo> {
      return this.httpClient.delete<IRespDesbloqueo>(`${this.apiUrl}/${idAgendamiento}`,{});
    }
}