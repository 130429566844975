import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class UtilServiceService {
  constructor() {}

  formatPeso(event: KeyboardEvent): void {
    const inputChar = event.key;
    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Delete',
    ];
    if (!/^[0-9.]$/.test(inputChar) && allowedKeys.indexOf(inputChar) === -1) {
      event.preventDefault();
      return;
    }
    const inputElement = event.target as HTMLInputElement;
    const currentInput = inputElement.value;
    if (inputChar === '.' && currentInput.includes('.')) {
      event.preventDefault();
      return;
    }
    const decimalIndex = currentInput.indexOf('.');

    if (
      decimalIndex === -1 &&
      currentInput.length >= 3 &&
      inputChar !== '.' &&
      allowedKeys.indexOf(inputChar) === -1
    ) {
      event.preventDefault();
      return;
    }
    if (
      decimalIndex !== -1 &&
      currentInput.length - decimalIndex > 3 &&
      allowedKeys.indexOf(inputChar) === -1
    ) {
      event.preventDefault();
    }
  }

  formatAltura(event: KeyboardEvent): void {
    const inputChar = event.key;
    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Delete',
    ];
    if (!/^[0-9.]$/.test(inputChar) && allowedKeys.indexOf(inputChar) === -1) {
      event.preventDefault();
      return;
    }
    const inputElement = event.target as HTMLInputElement;
    const currentInput = inputElement.value;
    if (inputChar === '.' && currentInput.includes('.')) {
      event.preventDefault();
      return;
    }
    const decimalIndex = currentInput.indexOf('.');

    if (
      decimalIndex === -1 &&
      currentInput.length >= 3 &&
      inputChar !== '.' &&
      allowedKeys.indexOf(inputChar) === -1
    ) {
      event.preventDefault();
      return;
    }
    if (
      decimalIndex !== -1 &&
      currentInput.length - decimalIndex > 2 &&
      allowedKeys.indexOf(inputChar) === -1
    ) {
      event.preventDefault();
    }
  }

  soloNumeros(event: Event, form: FormGroup): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    const controlName = input.getAttribute('formControlName');
    if (controlName && form.controls[controlName]) {
      const control = form.controls[controlName];
      value = value.replace(/[^0-9]/g, '');
      control.setValue(value);
    }
  }

  calcularEdad(fechaNacimiento: Date): number {
    const hoy = new Date();
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    const mes = hoy.getMonth() - fechaNacimiento.getMonth();
    if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
      edad--;
    }
    return edad;
  }

  restringirCaracteresEspeciales(
    event: KeyboardEvent,
    patron: string = '^[a-zA-Z0-9._@-]*$'
  ): void {
    const inputChar = event.key;
    const regex = new RegExp(patron);
    if (!regex.test(inputChar)) {
      event.preventDefault();
    }
  }
  onlyLetters(event: KeyboardEvent): void {
    const inputChar = String.fromCharCode(event.keyCode || event.which);
    if (!/^[a-zA-ZÑñ0-9\sáéíóúÁÉÍÓÚ]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  formatDni(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    value = value.replace(/[^0-9]/g, '').slice(0, 12);
    input.value = value;
  }
  formatPasaporte(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    value = value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 12);
    input.value = value;
  }
}
