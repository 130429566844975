import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ErrorMessages } from '../../interfaces/errorMesagges.interface';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { map, Observable, startWith } from 'rxjs';
import { IDataDireccion } from '../../interfaces/buscar-direccion.interface';

@Component({
  selector: 'soph-input-autocomplete-formfield',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgIf,
  ],
  templateUrl: './input-autocomplete-formfield.component.html',
  styleUrls: ['./input-autocomplete-formfield.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputAutocompleteFormfieldComponent {
  @Input() control: FormControl = new FormControl();
  @Input() label!: string;
  @Input() placeholder!: string; 
  @Input() errorMessages!: ErrorMessages;
  @Input() options: IDataDireccion[] = []; 
  @Output() optionSelected = new EventEmitter<any>(); // Emitir el objeto completo
  @Output() inputChanged = new EventEmitter<string>(); 

  filteredOptions!: Observable<IDataDireccion[]>;

  ngOnInit() {

    this.control.valueChanges.subscribe(value => {
      this.inputChanged.emit(value || ''); 
    });

    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }

  private _filter(value: string): IDataDireccion[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option =>
      option.address.toLowerCase().includes(filterValue)
    );
  }

  onOptionSelected(option: any) {
  this.optionSelected.emit(option);
  }
  displayWith(option: IDataDireccion): string {
    return option.address; 
  }

  get hasError() {
    return this.control?.touched && this.control?.invalid;
  }

  get errorMessage() {
    if (!this.control) return '';
    const errors = this.control.errors;
    if (errors) {
      for (const error of Object.keys(errors) as (keyof ErrorMessages)[]) {
        if (errors[error]) {
          return this.errorMessages[error] || '';
        }
      }
    }
    return '';
  }
}
