import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable} from 'rxjs';
import {IRespTipoDocumento} from "../interfaces/tipo-documento.interface";
import {AuthenticationService} from "../../pages/services/authentication.service";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObtenerTipoDocumentoService {
  private apiUrl = environment.baseURL + 'datos-medicos/tipos-documentos';

  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) { }

  getTipoDocumento(): Observable<IRespTipoDocumento> {

    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', '4b9b9ab5b734408d915ec31751bbf114')
    return this.httpClient.get<IRespTipoDocumento>(this.apiUrl, {headers, params});
  }
}
