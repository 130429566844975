import { DialogRef } from "@angular/cdk/dialog";
import { NgOptimizedImage } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatIconButton } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogContent } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { Historial360 } from "../../../interfaces/historial-360.interface";
import { InformationChipComponent } from "../information-chip/information-chip.component";
import { ContainerChipComponent } from "./components/container-chip/container-chip.component";
import { ContainerDiagnosticosComponent } from "./components/container-diagnosticos/container-diagnosticos.component";

@Component({
  selector: 'soph-modal-history-360',
  standalone: true,
  imports: [
    MatDialogContent,
    MatIcon,
    MatIconButton,
    MatTooltip,
    InformationChipComponent,
    ContainerChipComponent,
    NgOptimizedImage,
    ContainerDiagnosticosComponent
],
  templateUrl: './modal-history-360.component.html',
  styleUrl: './modal-history-360.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHistory360Component implements OnInit {
  public historialResult: Historial360 = inject(MAT_DIALOG_DATA);

  public readonly dialogRef = inject(DialogRef);

  ngOnInit(): void {}

}
