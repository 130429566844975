<h3>Terapias</h3>
<p>Busca y selecciona las terapias que incluirás en el tratamiento</p>
<soph-multi-select-search-form-field
  [formGroup]="formTerapias"
  controlName="terapias"
  placeholder="Seleccionar terapia"
  [values]="terapiasOptions"
  [limitValues]="50"
  idField="idTerapia"
  viewField="nombre"
>
</soph-multi-select-search-form-field>
<div *ngIf="terapias.value && terapias.value.length" class="selected-items">
  <h3>Terapias seleccionadas:</h3>
  <p>Ingresa la cantidad de sesiones, frecuencia horaria e indicaciones</p>

  <div class="form-section-grupo" *ngFor="let terapia of terapiasSelected; index as i">
    <mat-divider></mat-divider>
    <mat-accordion class="full-width custom-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title class="style-tittle-colapse">
            {{ terapia.nombreTerapia }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form-section-row input-grid">
          <soph-input-form-field
            [control]="terapia.controles.sesiones.terapControl"
            label="Sesiones"
            [errorMessages]="{
              required: 'Campo obligatorio.'
            }"
            [inputEvent]="onlyNumbers"
          >
          </soph-input-form-field>
          <soph-select-form-field
            [control]="terapia.controles.frecuencia.terapControl"
            [options]="frecuenciasOptions"
            label="Frecuencias"
            [errorMessages]="{ required: 'Campo obligatorio.' }"
            valueProperty="idFrecuencia"
            displayProperty="nombre"
          >
          </soph-select-form-field>
        </div>
        <div class="form-section-row input-full-width">
          <soph-text-area-form-field
          [control]="terapia.controles.indicaciones.terapControl"
          label="Indicaciones"
          [errorMessages]="{
            required: 'Campo obligatorio.',
            maxlength: 'Ha excedido el número máximo de caractéres'
          }"
          matInt = 'Describe indicaciones de la terapia '
          [maxLength]="140">

          </soph-text-area-form-field>
        </div>
        <div class="section-eliminar">
          <button mat-button (click)="unselectTerap(terapia.idTerapia)">
            <mat-icon svgIcon="trash"></mat-icon>
            Eliminar
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-divider *ngIf="i === terapiasSelected.length - 1"></mat-divider>
  </div>
</div>
<!-- Remedios guardados -->
<div class="parent-container">
  <div class="container-remedios">
    <div class="remedio-guardado" *ngFor="let terapia of terapiasGuardadas">
      {{ terapia.codigo + " " + terapia.nombreTerapia }}
    </div>
  </div>
</div>
