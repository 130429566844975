import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IRespOperaciones } from '../interfaces/operaciones.interface';
import { IresProcedimiento } from '../interfaces/procedimientos.interface';
import { IResTerapias } from '../interfaces/terapias.interface';
import { IRespMedicamentos } from '../interfaces/tratamiento/medicamentos.interface';
import { IRespFrecuencias } from '../interfaces/tratamiento/terapias.interface';
import { IRespViasAdministracion } from '../interfaces/vias-admin.interface';

@Injectable({
  providedIn: 'root',
})
export class TabTratamientoService {
  private apiUrl = environment.baseURL + 'datos-medicos';

  constructor(private httpClient: HttpClient) {}

  getMedicamentos(): Observable<IRespMedicamentos> {
    return this.httpClient.get<IRespMedicamentos>(
      this.apiUrl + '/medicamentos'
    );
  }

  getProcedimientos(): Observable<IresProcedimiento> {
    return this.httpClient.get<IresProcedimiento>(
      this.apiUrl + '/procedimientos'
    );
  }

  getTerapias(): Observable<IResTerapias> {
    return this.httpClient.get<IResTerapias>(this.apiUrl + '/terapias');
  }

  getOperaciones(): Observable<IRespOperaciones> {
    return this.httpClient.get<IRespOperaciones>(this.apiUrl + '/operaciones');
  }

  getViasAdministracion(): Observable<IRespViasAdministracion> {
    return this.httpClient.get<IRespViasAdministracion>(
      this.apiUrl + '/vias-administracion'
    );
  }
  getFrecuencias(): Observable<IRespFrecuencias> {
    return this.httpClient.get<IRespFrecuencias>(this.apiUrl + '/frecuencias');
  }
}
