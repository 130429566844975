import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDataHora } from '../interfaces/rango-horario.interface';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RangoHorarioConsultaService {
  private apiUrl = environment.baseURL + 'agendamientos';
  clientId = '4b9b9ab5b734408d915ec31751bbf114';

  constructor(private httpClient: HttpClient,
    private authService: AuthenticationService) { }

    getRangoHora(): Observable<IDataHora>{
      const token = this.authService.getToken();
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
      const params =new
      HttpParams()
        .set('client-id', this.clientId)
      return this.httpClient.get<IDataHora>(this.apiUrl+'/horarios-atencion', {headers, params});
    }
}
