import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { IGenericResponse } from '../../shared/interfaces/examenes.interface';

@Injectable({
  providedIn: 'root'
})
export class EventosService {
  private apiUrl = environment.baseURL + 'eventos';

  private _httpClient = inject(HttpClient);

  getHorariosDisponibles(fecha: string): Observable<IGenericResponse<string[]>> {
    const zona = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this._httpClient.get<IGenericResponse<string[]>>(`${this.apiUrl}/disponibilidad/${fecha}`, { params: { zona } } );
  }
}
