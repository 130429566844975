<div class="contenedor-correo">
  <div class="header">
    <img src="assets/images/Logo.svg" alt="" />
    <h2 style="color: #d96704">Anulación de reserva de hora</h2>
    <p style="text-align: left; padding: 0px 80px">
      Estimado (a), <strong>usuario</strong> <br /><br />
      Le informamos que la siguiente hora reservada ha sido anulada por
      “dinámico motivo”
    </p>
  </div>

  <div class="content-info">
    <div class="name-pacient" style="justify-content: center">
      <img src="assets/icons/Persona_circulo.svg" alt="" />
      <h2 style="margin: 0">Jorge Tamani</h2>
    </div>
    <div class="section-hora-fecha" style="justify-content: center">
      <img src="assets/icons/calendar_ficha.svg" alt="" />
      <p>10/02/2025</p>
      <img src="assets/icons/clock_ficha.svg" alt="" />
      <p>10:50</p>
    </div>
    <div class="section-tipo-consulta" style="justify-content: center">
      <img src="assets/icons/house.svg" alt="" />
      <p>Visita domiciliaria</p>
    </div>
    <div class="section-direccion-consulta">
      <img src="assets/icons/pin_icon.svg" alt="" />
      <p>Av. Republica de Argentina 1082, 302, Lima Perú</p>
    </div>
  </div>

  <p style="padding: 29px 45px 0">
    Nos comunicaremos con usted a la brevedad para reagendar nuevamente la
    consulta.
  </p>
  <div class="divider"></div>
  <p style="text-align: center; margin: 0; padding-top: 0 20px; color: #d96704">
    Le ofrecemos nuestros canales de atención:
  </p>
  <div class="contact-info">
    <img src="assets/icons/mail_icon.svg" alt="" />
    <p>ayudasophia.com.pe</p>
    <img src="assets/icons/phone_icon.svg" alt="" />
    <p>+51 2214 2542 2</p>
  </div>

  <div class="divider"></div>
  <p style="text-align: center">
    Enviado por <strong>Sophia</strong>, tu asistente médico de confianza.
  </p>
</div>
