import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

import { IRespDetalleNuevaConsulta } from '../../../../shared/interfaces/tratamiento/detalle-nueva-consulta.interface';
import { DialogRef } from '@angular/cdk/dialog';
import { UtilServiceService } from '../../../../shared/services/util-service.service';
import { Router } from '@angular/router';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { ModalConfirmAnularConsultaComponent } from '../../../../shared/components/modal-confirm-anular-consulta/modal-confirm-anular-consulta.component';
import { HistoricoConsultaService } from '../../../../shared/services/historico-consulta.service';
import { ModalResumenAtencionComponent } from '../../../../shared/components/modal-resumen-atencion/modal-resumen-atencion.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'soph-modal-detalle-consulta',
  standalone: true,
  imports: [MatButtonModule, CommonModule, MatDialogModule,
        MatIcon
  ],
  templateUrl: './modal-detalle-consulta.component.html',
  styleUrl: './modal-detalle-consulta.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDetalleConsultaComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IRespDetalleNuevaConsulta,
    private readonly _utilService: UtilServiceService,
    private router: Router,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _historicoConsultaService: HistoricoConsultaService
  ) {}
  
  private matDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);
  public readonly dialogRef = inject(DialogRef);
  fechaconsulta!: string;
  horaConsulta!: string;
  tipoAtencion!: string;
  public presecial = 'Presencial';
  public domiciliaria = 'Domiciliaria';
  public telemedicina = 'Telemedicina';
  public estadoAgendada = 'AGENDADA';
  public estadoRealizada = 'REALIZADA';
  public estadoEnProceso = 'PROCESO';
  resumenConsulta : any;

  ngOnInit(): void {
    this.data;
    this.getTipoAtencion();
  }

  getTipoAtencion() {
    this.tipoAtencion = this._utilService.formatUpperCaseFirstLetter(
      this.data.tipoATencion
    );
  }

  public editarConsulta() {
    this.dialogRef.close();
    this.router.navigate(['/ingreso/', this.data.idAgendamiento, { fromEdit: true }]);
  }
  
  
  public irAFicha() {
    this.dialogRef.close();
    this._fichaClinicaStore.newStore({
      idAgendamiento: this.data.idAgendamiento,
      idPaciente: this.data.idPaciente,
      estado: this.data.estado,
      fechaAtencion: this.data.fechaAtencion,
      horaAtencion: this.data.horaAtencion,
      tipoAtencion: this.data.tipoATencion,
    });
    return this.router.navigate(['/ficha']);
  }

  public openResumenConsulta(): void {
    const idAgendamiento = this.data.idAgendamiento;
    if (!this.data) return;
    if (!idAgendamiento) return;

    this._historicoConsultaService
      .getResumenConsulta(idAgendamiento)
      .subscribe({
        next: (resp) => {
          if (resp.data) {
            this.resumenConsulta = resp;

            this.matDialog.open(ModalResumenAtencionComponent, {
              data: this.resumenConsulta,
              maxWidth: '900px',
              minWidth: '400px',
              minHeight: '400px',
            });
          }
        },
        error: (err) => console.error('Error', err),
      });
  }

  anularConsulta() {
    this.dialogRef.close();
    this.dialog.open(ModalConfirmAnularConsultaComponent, {
      data: {
        idAgendamiento: this.data.idAgendamiento,
      },
    });
  }
}
