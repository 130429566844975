import {
  ChangeDetectionStrategy,
  Component,
  inject,
  model,
  signal,
} from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DateClickArg from '@fullcalendar/interaction';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ModalNuevaConsultaComponent } from '../../common/components/nueva-consulta/modal-nueva-consulta.component';
import { MatInputModule } from '@angular/material/input';
import { HeaderSophiaComponent } from '../../common/components/header-sophia/header-sophia.component';
import { ModalDetalleConsultaComponent } from '../../common/components/detalle-consulta/modal-detalle-consulta.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ModalBloqueoHorarioComponent } from '../../common/components/bloqueo-horario/modal-bloqueo-horario.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
@Component({
  selector: 'soph-calendario-agendamiento',
  standalone: true,
  imports: [
    FullCalendarModule,
    MatDialogModule,
    ModalNuevaConsultaComponent,
    MatInputModule,
    HeaderSophiaComponent,
    MatButtonModule,
    MatIconModule,
    ModalBloqueoHorarioComponent,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  templateUrl: './calendario-agendamiento.component.html',
  styleUrl: './calendario-agendamiento.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarioAgendamientoComponent {
  events: any = [
    {
      title: 'Evento de ejemplo',
      start: '2024-08-20T12:00:00', // Hora de inicio
      end: '2024-08-20T13:30:00', // Hora de término
      backgroundColor: '#D5DA66', // Color de fondo opcional
      borderColor: '#D5DA66',
    },
    {
      title: 'Evento de ejemplo',
      start: '2024-08-21T10:00:00', // Hora de inicio
      end: '2024-08-21T12:30:00', // Hora de término
      backgroundColor: '#D5DA66', // Color de fondo opcional
      borderColor: '#D5DA66',
    },
    {
      title: 'Evento de ejemplo',
      start: '2024-08-21T10:30:00', // Hora de inicio
      end: '2024-08-21T12:12:00', // Hora de término
      backgroundColor: '#94ECFF', // Color de fondo opcional
      borderColor: '#94ECFF', // Color del borde opcional
    },
    {
      title: 'Evento de ejemplo',
      start: '2024-08-22T10:00:00', // Hora de inicio
      end: '2024-08-22T10:30:00', // Hora de término
      backgroundColor: '#D5DA66', // Color de fondo opcional
      borderColor: '#D5DA66', // Color del borde opcional
    },
    {
      title: 'Evento de ejemplo',
      start: '2024-08-22T12:00:00',
      end: '2024-08-22T12:30:00',
      backgroundColor: '#EFE3FF',
      borderColor: '#EFE3FF',
    },
    {
      title: 'Evento de ejemplo',
      start: '2024-08-22T13:00:00',
      end: '2024-08-22T13:30:00',
      backgroundColor: '#D5DA66',
      borderColor: '#D5DA66',
    },
    {
      title: 'Evento de ejemplo',
      start: '2024-08-23T13:00:00',
      end: '2024-08-23T13:30:00',
      backgroundColor: '#94ECFF',
      borderColor: '#94ECFF',
    },
  ];
  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    locale: esLocale,
    plugins: [timeGridPlugin, dayGridPlugin, DateClickArg, interactionPlugin],
    headerToolbar: {
      left: 'prev,next', // Botones de navegación
      center: 'title', // Título central
      right: 'timeGridDay,timeGridWeek,dayGridMonth', // Cambios de vista
    },
    slotLabelFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
    dayHeaderFormat: { weekday: 'long', day: 'numeric' },
    contentHeight: 'auto', // Ajuste automático de altura
    slotMinTime: '08:00:00',
    slotMaxTime: '21:00:00',
    selectable: true, // Permitir seleccionar tiempos
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this), // Manejador de selección de fechas,
    eventClick: this.handleEventClick.bind(this),
    events: this.events, // Cargar eventos guardados
  };

  selected: Date | null = null;
  readonly animal = signal('');
  readonly name = model('');
  readonly dialog = inject(MatDialog);
  constructor() {}

  ngOnint(): void {}

  handleDateSelect(selectInfo: DateSelectArg) {
    console.log(selectInfo);
    const title = prompt('Escribe el título del evento:');
    const nombre = prompt('Ingresa el nombre del paciente:');
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // Limpiar la selección

    if (title && nombre) {
      const newEvent = {
        id: String(Date.now()), // Generar un ID único
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      };

      this.events.push(newEvent);
      calendarApi.addEvent(newEvent);
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    console.log(clickInfo);
    this.dialog.open(ModalDetalleConsultaComponent, {
      data: {
        title: clickInfo.event.title,
        start: clickInfo.event.start,
        end: clickInfo.event.end,
      },
    });
  }

  openModal(): void {
    const dialogRef = this.dialog.open(ModalNuevaConsultaComponent, {
      data: { name: this.name(), animal: this.animal() },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result !== undefined) {
        this.animal.set(result);
      }
    });
  }

  public openBlockModal() {
    const dialogRef = this.dialog.open(ModalBloqueoHorarioComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Datos recibidos:', result);
        // Aquí puedes manejar los datos retornados
      }
    });
  }
}
