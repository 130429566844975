import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectChange, MatSelectModule} from "@angular/material/select";
import { ErrorMessages } from '../../interfaces/errorMesagges.interface';



@Component({
  selector: 'soph-select-form-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  templateUrl: './select-form-field.component.html',
  styleUrl: './select-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFormFieldComponent {
  @Input() control: FormControl = new FormControl();
  @Input() options: any[] = [];
  @Input() label!: string;
  
  @Input() errorMessages!: ErrorMessages;
  @Input() valueProperty: string = 'value';  // propiedad a usar como value
  @Input() displayProperty: string = 'viewValue';  // propiedad a mostrar en el mat-option
  @Output() selectionChange = new EventEmitter<string>(); 
  get hasError() {
    return this.control?.touched && this.control?.invalid;
  }

  onSelectionChange(event: any) {
    this.selectionChange.emit(event.value);
  }
  get errorMessage() {
    if (!this.control) return '';
    const errors = this.control.errors;
    if (errors) {
      for (const error of Object.keys(errors) as (keyof ErrorMessages)[]) {
        if (errors[error]) {
          return this.errorMessages[error] || '';
        }
      }
    }
    return '';
  }
}
