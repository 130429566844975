export const icons: {
  name: string;
  fileName: string;
}[] = [
  { name: 'check_circle', fileName: 'check_circle.svg' },
  { name: 'cancel_circle', fileName: 'cancel_circle.svg' },
  { name: 'alert_circle', fileName: 'alert_circle.svg' },
  { name: 'info_circle', fileName: 'info_circle.svg' },
  { name: 'trash', fileName: 'trash.svg' },
  { name: 'mic', fileName: 'mic.svg' },
];
