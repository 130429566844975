<mat-dialog-content class="modal-content">
  <mat-icon>error</mat-icon>
  <h2>¿Estás seguro que quieres actualizar esta <br> información?</h2>
  <p>Al aceptar se actualizarán los cambios en los datos <br>del paciente.</p>
</mat-dialog-content>

<mat-dialog-actions class="modal-actions">
  <button mat-stroked-button
          class="btn-cancelar"
          (click)="cancelar()">
    Cancelar</button>
  <button
   (click)="actualizarDatos()"
    mat-flat-button color="primary"
    mat-flat-button>Actualizar
  </button>
</mat-dialog-actions>
