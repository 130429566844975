import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'soph-modal-exito-bloqueo-horario',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './modal-exito-bloqueo-horario.component.html',
  styleUrl: './modal-exito-bloqueo-horario.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalExitoBloqueoHorarioComponent {
      constructor(
       private dialogRef:
        MatDialogRef<ModalExitoBloqueoHorarioComponent>,
          private router: Router) {}
  ngOnInit(): void {}
 
  irACalendario() {
    this.dialogRef.close();
    this.router.navigate(['/calendario']);
   }
 
   cancelar() {
     this.dialogRef.close();
   }
}
