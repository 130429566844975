import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { count, delay, expand, Observable, of, retry, retryWhen, takeWhile} from 'rxjs';
import {IRespExistePaciente} from "../interfaces/exite-paciente.interface";
import {AuthenticationService} from "../../pages/services/authentication.service";
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ConsultaPacienteRegistradoService {
  private apiUrl = environment.baseURL + 'agendamientos/existe-paciente';
  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) {
  }

  getExistePaciente(numeroDocumento: string, reintentar = false): Observable<IRespExistePaciente> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', '4b9b9ab5b734408d915ec31751bbf114')
      .set('numeroDocumento', numeroDocumento);
      return this.httpClient.get<IRespExistePaciente>(this.apiUrl, { headers, params })
      .pipe(
        expand(response =>
          response?.data === null
            ? this.httpClient.get<IRespExistePaciente>(this.apiUrl, { headers, params }).pipe(delay(500)) // Reintenta tras medio segundo
            : of(response) // Devuelve la respuesta si no es null
        ),
        takeWhile((response, index) => response.data === null && index < 3 && reintentar, true) // Continúa mientras la respuesta sea null
      );
  }
}
