import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {
  FullCalendarComponent,
  FullCalendarModule,
} from '@fullcalendar/angular';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DateClickArg from '@fullcalendar/interaction';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { HeaderSophiaComponent } from '../../common/components/header-sophia/header-sophia.component';
import { ModalDetalleConsultaComponent } from '../../common/components/detalle-consulta/modal-detalle-consulta.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ModalBloqueoHorarioComponent } from '../../common/components/bloqueo-horario/modal-bloqueo-horario.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { ObtenerAgendamientosMedicosService } from '../../../shared/services/obtener-agendamientos-medicos.service';
import {
  IDataEvent,
  TipoEvento,
  DataAgendamiento,
} from '../../../shared/interfaces/tratamiento/evento-agendamiento.interface';
import { Router } from '@angular/router';
import { InfoDetalleModalNuevaConsultaService } from '../../../shared/services/info-detalle-modal-nueva-consulta.service';
import {
  IDataDetalleNuevaConsulta,
  IRespDetalleNuevaConsulta,
} from '../../../shared/interfaces/tratamiento/detalle-nueva-consulta.interface';
import { CommonModule } from '@angular/common';
import { ModalExitoBloqueoHorarioComponent } from '../../../shared/components/modal-exito-bloqueo-horario/modal-exito-bloqueo-horario.component';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { HistoricoConsultaService } from '../../../shared/services/historico-consulta.service';
import { ShareDataService } from '../../../shared/services/share-data.service';
import { Subscription } from 'rxjs';
import { convertirUTCaLocal } from '../../../shared/helpers/date.helper';
import { DetalleDesbloqueoHorarioComponent } from '../../common/components/detalle-desbloqueo-horario/detalle-desbloqueo-horario.component';
import { ServiceFormIdentificacionService } from '../../../shared/services/service-form-identificacion.service';
import { ServiceFormAnamnesisService } from '../../../shared/services/service-form-anamnesis.service';
@Component({
  selector: 'soph-calendario-agendamiento',
  standalone: true,
  imports: [
    FullCalendarModule,
    MatDialogModule,
    MatInputModule,
    HeaderSophiaComponent,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CommonModule,
    MatTooltipModule,
  ],
  templateUrl: './calendario-agendamiento.component.html',
  styleUrl: './calendario-agendamiento.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarioAgendamientoComponent {
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  calendarOptions: CalendarOptions = {
    initialDate: new Date().toISOString(),
    initialView: 'timeGridWeek',
    timeZone: 'local',
    locale: esLocale,
    firstDay: 1,
    plugins: [timeGridPlugin, dayGridPlugin, DateClickArg, interactionPlugin],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'timeGridDay,timeGridWeek,dayGridMonth',
    },
    slotLabelFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
    dayHeaderFormat: { weekday: 'long', day: '2-digit' },
    contentHeight: 'auto',
    slotDuration: '00:20:00',
    slotLabelInterval: '00:20:00',
    slotMinTime: '07:00:00',
    slotMaxTime: '22:20:00',
    selectMirror: true,
    dayMaxEvents: false,
    eventClick: this.openModalDetalleConsulta.bind(this),
    datesSet: this.setEventos.bind(this),
    eventDidMount: this.eventDidMount.bind(this),

    dayHeaderContent: (args) => {
      if (args.view.type === 'dayGridMonth') {
        return args.text.split(' ')[0];
      }
      return args.text;
    },

    eventContent: (arg) => {
      const backgroundColor = arg.event.backgroundColor;
      return {
        html: `
          <div style="
          background-color: ${backgroundColor};
          color: #0c2447; padding: 0 12px">
            ${arg.event.title}
          </div>
        `,
      };
    },
  };

  filtro = '';
  flagFiltros: boolean = false;
  selected: Date | null = null;
  details: IRespDetalleNuevaConsulta | null = null;
  allEvents: WritableSignal<IDataEvent[]> = signal([]);
  readonly dialog = inject(MatDialog);
  filtroActivo: string | null = null;
  private subscription!: Subscription;
  constructor(
    private readonly _eventosAgendamientosService: ObtenerAgendamientosMedicosService,
    private readonly _detalleConsulta: InfoDetalleModalNuevaConsultaService,
    private router: Router,
    private readonly _refreshCalendarService: ShareDataService,
        private datosPersonalesService: ServiceFormIdentificacionService,
        private datosMedicosService: ServiceFormAnamnesisService,
  ) {}

  ngOnInit(): void {
    this.subscription = this._refreshCalendarService.refreshCalendar$.subscribe(
      () => {
        this.refreshCalendar();
      }
    );
    this.datosPersonalesService.getDatosPersonales().subscribe();
    this.datosMedicosService.getDatosMedicos().subscribe();
  }

  refreshCalendar() {
    const calendarApi = this.calendarComponent?.getApi();
    if (!calendarApi) {
      return;
    }

    const { currentStart, currentEnd } = calendarApi.view;
    const start = currentStart.toISOString().split('T')[0];
    const end = currentEnd.toISOString().split('T')[0];

    this._eventosAgendamientosService.getEventos(start, end).subscribe({
      next: ({ data }) => {
        calendarApi.removeAllEvents();
        if (!data?.length) return;
        this.allEvents.set(data);
        calendarApi.addEventSource(data);
      },
      error: (err) => console.error('Error al refrescar eventos', err),
    });
  }



  eventDidMount(info: any) {
    info.el.setAttribute('title', info.event.title);
  }

  setEventos(dateInfo: any): void {
    const inicioFormateada = new Date(dateInfo.start)
      .toISOString()
      .split('T')[0];
    const terminoFormateada = new Date(dateInfo.end)
      .toISOString()
      .split('T')[0];
    const calendarApi = dateInfo.view.calendar;

    this._eventosAgendamientosService
      .getEventos(inicioFormateada, terminoFormateada)
      .subscribe({
        next: (res) => {
          if (res.data?.length === 0) return;

          res.data.map((e) => {
            e.start = convertirUTCaLocal(e.start);
            e.end = convertirUTCaLocal(e.end);
          });

          console.log('Eventos:', res.data);
          this.allEvents.set(res.data);



          calendarApi.removeAllEvents();

          if (this.filtroActivo) {
            const eventosFiltrados = res.data.filter((e) => {
              const tipoAtencion = (e.metadata as DataAgendamiento)
                .tipoAtencion;
              return tipoAtencion === this.filtroActivo;
            });
            calendarApi.addEventSource(eventosFiltrados);
          } else {
            calendarApi.addEventSource(res.data);
          }
        },
        error: (err) => {
          console.error('Error al obtener eventos', err);
        },
      });
  }

  filtrarEventosPorTipo(tipoFiltro: string): void {
    const calendarApi = this.calendarComponent.getApi();

    if (tipoFiltro === 'DELETE') {
      this.filtroActivo = null;
      const eventos = this.allEvents();
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(eventos);
    } else {
      this.filtroActivo = tipoFiltro;
      const eventosFiltrados = this.allEvents().filter((e) => {
        const tipoAtencion = (e.metadata as DataAgendamiento).tipoAtencion;
        return tipoAtencion === tipoFiltro;
      });
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(eventosFiltrados);
    }
  }

  filterTipoDomiciliaria(): void {
    this.flagFiltros = true;
    this.filtrarEventosPorTipo('DOMICILIARIA');
  }

  filterTipoPresencial(): void {
    this.flagFiltros = true;
    this.filtrarEventosPorTipo('PRESENCIAL');
  }

  filterTipoTelemedicina(): void {
    this.flagFiltros = true;
    this.filtrarEventosPorTipo('TELEMEDICINA');
  }

  eliminarFiltrosTipo(): void {
    this.flagFiltros = false;
    this.filtrarEventosPorTipo('DELETE');
  }

  openModalDetalleConsulta(clickInfo: EventClickArg) {
    const tipo = clickInfo.event.extendedProps['tipo'];
    const idAgendamiento = clickInfo.event._def.publicId;
    const estado = clickInfo.event.extendedProps['metadata']['estadoAtencion'];
    const idPaciente = clickInfo.event.extendedProps['metadata']['idPaciente'];
    if (!idAgendamiento) return;

    if (tipo === TipoEvento.Agendamiento) {
      this._detalleConsulta.getDetalleConsulta(idAgendamiento).subscribe({
        next: (res: IDataDetalleNuevaConsulta) => {
          if (res.success) {
            this.details = res.data;
            this.dialog.open(ModalDetalleConsultaComponent, {
              data: {
                fechaAtencion: this.details.fechaAtencion,
                horaAtencion: this.details.horaAtencion,
                tipoATencion: this.details.tipoATencion,
                nombrePaciente: this.details.nombrePaciente,
                telefonoPaciente: this.details.telefonoPaciente,
                detalleAtencion: this.details.detalleAtencion,
                idAgendamiento: this.details.idAgendamiento,
                estado,
                idPaciente,
              },
            });
          }
        },
        error: (err) => {
          console.error('Error al obtener el detalle de consulta:', err);
        },
      });
    } else {
      this.dialog.open(DetalleDesbloqueoHorarioComponent, {
        data: {
          fechaAtencion: clickInfo.event._instance?.range.start,
          idAgendamiento: clickInfo.event._def.publicId,
          fechaTermino: clickInfo.event._instance?.range.end,
          evento: this.allEvents().filter((e) => e.id === idAgendamiento)[0],
        },
      });
    }
  }

  openModalNuevaConsulta(): void {
    this.router.navigate(['/ingreso']);
  }

  public openBlockModal() {
    const dialogRef = this.dialog.open(ModalBloqueoHorarioComponent, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  openBloqueoHorarios(): void {
    this.router.navigate(['/bloqueo-horario']);
  }

  openModalPrueba() {
    this.dialog.open(ModalExitoBloqueoHorarioComponent);
  }
}
