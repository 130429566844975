import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MultiselectFormfieldComponent } from '../../../../shared/components/multiselect-formfield/multiselect-formfield.component';
import { IReqTratamiento } from '../../../../shared/interfaces/tratamiento/tratamiento.interfaces';
import { TabTratamientoService } from '../../../../shared/services/tab-tratamiento.service';
import { MedicamentosComponent } from '../../../common/components/tratamiento/medicamentos/medicamentos.component';
import { TratamientoService } from '../../../../shared/services/tratamiento/tratamiento.service';
import { ShareDataService } from '../../../../shared/services/share-data.service';
import { EstadoTratamiento } from '../../../../shared/enums';
import { TerapiasComponent } from '../../../common/components/tratamiento/terapias/terapias.component';
import { ProcedimientosComponent } from '../../../common/components/tratamiento/procedimientos/procedimientos.component';
import { OperacionesComponent } from '../../../common/components/tratamiento/operaciones/operaciones.component';
import { TextareaWithSpeechToTextFormFieldComponent } from '../../../../shared/components/textarea-with-speech-to-text-form-field/textarea-with-speech-to-text-form-field.component';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';

@Component({
  selector: 'soph-tratamiento',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDividerModule,
    MatGridListModule,
    MatExpansionModule,
    MedicamentosComponent,
    TerapiasComponent,
    ProcedimientosComponent,
    OperacionesComponent,
    TextareaWithSpeechToTextFormFieldComponent
  ],
  templateUrl: './tratamiento.component.html',
  styleUrl: './tratamiento.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TratamientoComponent {
  readonly path = 'tratamiento';
  estadoTratamiento = EstadoTratamiento.INICIAL;
  formTratamiento: FormGroup;

  idAgendamiento: string = '';
  reqTratamiento: IReqTratamiento = {} as IReqTratamiento;

  @ViewChild(MedicamentosComponent) medsComponente!: MedicamentosComponent;
  @ViewChild(TerapiasComponent) terapsComponente!: TerapiasComponent;
  @ViewChild(ProcedimientosComponent) procedComponente!: ProcedimientosComponent;
  @ViewChild(OperacionesComponent) operacionesComponent!: OperacionesComponent;
  isFormMedicamentosValid = true;
  isFormTerapiasValid = true;

  showBtnGuardar() : boolean {
    return (this.estadoTratamiento != EstadoTratamiento.GUARDADO);
  }
  showBtnEditar() : boolean {
    return (this.estadoTratamiento === EstadoTratamiento.GUARDADO)
  }
  showBtnCancelar() : boolean {
    return (this.estadoTratamiento != EstadoTratamiento.GUARDADO);
  }

  get indicacionesExtra() {
    return this.formTratamiento.get('indicacionesExtra') as FormControl;
  }

  constructor(
    private tratamientoService: TratamientoService,
    private shareDataService: ShareDataService,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private fb: FormBuilder
  ) {
    this.formTratamiento = this.fb.group({
      indicacionesExtra: new FormControl<string | null>(
        null,
        Validators.compose([Validators.maxLength(1000)])
      )
    })

    this._fichaClinicaStore.updateMedicalHistoryBySection('tratamiento', true);
  }

  ngOnInit(): void {
    this.shareDataService.idAgendamiento$.subscribe((resp) => {
      if (resp) {
        this.idAgendamiento = resp;
      }
    })
  }

  public allFormsValid() {
    return (this.isFormMedicamentosValid && this.isFormTerapiasValid)
  }

  guardarTratamiento() {
    console.log(this.indicacionesExtra.value)

    const medicamentos = this.medsComponente.armarRequestMedicamentos()
    const terapias = this.terapsComponente.armarRequestTerapias();
    const procedimientos: string[] = this.procedComponente.armarRequestProcedimientos();
    const operaciones: string[] = this.operacionesComponent.armarRequestOperaciones();
    const indicaciones: string | null = (this.indicacionesExtra.value) ?? null;
    this.reqTratamiento = {
      idAgendamiento: this.idAgendamiento,
      medicamentos,
      terapias,
      operaciones,
      procedimientos,
      indicaciones
    }
    console.log(this.reqTratamiento)
    this.tratamientoService.postNuevoTratamiento(this.reqTratamiento).subscribe({
      next: () => {
        this.estadoTratamiento = EstadoTratamiento.GUARDADO;
        // Mostrar chips
        this.medsComponente.showMedicamentosGuardados(medicamentos);
        this.terapsComponente.showTerapiasGuardadas(terapias);
        this.procedComponente.showProcedimientosGuardadss(procedimientos)
        this.operacionesComponent.showOperacionesGuardadss(operaciones)
        // deshabilitar inputs
        this.medsComponente.disableSelectorMedicamentos();
        this.terapsComponente.disableSelectorTerapias();
        this.procedComponente.disableSelectorProcedimientos()
        this.operacionesComponent.disableSelectorOperaciones()
        this.indicacionesExtra.disable();

      },
      error: (err) => console.log(err),
    });
  }

  editarTratamiento() {
    //colocar los medicamentos en el estado anterior
    this.medsComponente.editarMedicamentos();
    this.medsComponente.enableSelectorMedicamentos();

    this.terapsComponente.editarTerapias();
    this.terapsComponente.enableSelectorTerapias();

    this.procedComponente.editarProcedimientos()
    this.procedComponente.enableSelectorProcedimientos()

    this.operacionesComponent.editarOperaciones()
    this.operacionesComponent.enableSelectorOperaciones()

    this.indicacionesExtra.enable();


    // colocar el estado del formulario EN_EDICION
    this.estadoTratamiento = EstadoTratamiento.EN_EDICION
  }
  cancelar() {
    // Si es la primera vez que guarda, debe dejar todo en blanco
    if(this.estadoTratamiento === EstadoTratamiento.INICIAL) {
      this.medsComponente.resetForm();
      this.terapsComponente.resetForm();
      this.procedComponente.resetForm();
      this.operacionesComponent.resetForm();
      this.indicacionesExtra.reset()
    } else if (this.estadoTratamiento === EstadoTratamiento.EN_EDICION) {
      // si está en modo edicion, debe dejar todo en el estado guardado
      this.medsComponente.showMedicamentosGuardados(this.reqTratamiento.medicamentos);
      this.medsComponente.disableSelectorMedicamentos();

      this.terapsComponente.showTerapiasGuardadas(this.reqTratamiento.terapias);
      this.terapsComponente.disableSelectorTerapias();

      this.procedComponente.showProcedimientosGuardadss(this.reqTratamiento.procedimientos);
      this.procedComponente.disableSelectorProcedimientos();

      this.operacionesComponent.showOperacionesGuardadss(this.reqTratamiento.operaciones);
      this.operacionesComponent.disableSelectorOperaciones();

      this.indicacionesExtra.setValue(this.reqTratamiento.indicaciones);
      this.indicacionesExtra.disable();

      this.estadoTratamiento = EstadoTratamiento.GUARDADO;
    }
  }
}
