
<div
  class="card-container">
  <div class="card-header">
    <i class="fas fa-user-circle"></i>
    <span class="contact-name texto-info">{{ data.title }}</span>
  </div>
  <div class="card-body">
    <div class="card-row">
      <i class="fas fa-calendar-alt"></i>
      <span class="texto-light">01/01/2024</span>
      <i class="fas fa-clock"></i>
      <span class="texto-light">11:00 am</span>
    </div>
    <div class="card-row texto-info">
      <i class="fas fa-phone"></i>
      <span  class="texto-light">+51 2334 11 22</span>
    </div>
    <div class="card-row">
      <i class="fas fa-home"></i>
      <span class="visit-type texto-info">Visita domiciliaria</span>
    </div>
    <div class="card-row">
      <i class="fas fa-map-marker-alt"></i>
      <span class="texto-light">Av. Republica de Argentina 1082, 302, Lima, Perú</span>
    </div>
  </div>
  <div class="card-footer">
    <button class="btn edit-btn texto-info">Editar consulta</button>
    <button class="btn go-btn texto-info">Ir a la consulta</button>
  </div>
  <button class="close-btn">
    <i class="fas fa-times"></i>
  </button>
</div>
