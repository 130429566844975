import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Type,
} from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MenuLateralInfoComponent } from './menu-lateral-info/menu-lateral-info.component';
import { RegistroComponent } from './registro/registro.component';
import { AnamnesisComponent } from './anamnesis/anamnesis.component';
import { ExamenesComponent } from './examenes/examenes.component';
import { DiagnosticoComponent } from './diagnostico/diagnostico.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HeaderSophiaComponent } from '../../common/components/header-sophia/header-sophia.component';
import { Router } from '@angular/router';
import { TratamientoComponent } from './tratamiento/tratamiento.component';
import { FichaClinicaStore } from '../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { FichaClinicaService } from '../../services/ficha-clinica.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalErrorGeneralComponent } from '../../common/modal-error-general/modal-error-general.component';

export enum TabPosition {
  Identificacion,
  Anamnesis,
  Diagnostico,
  Tratamiento,
  Examenes,
}

@Component({
  selector: 'soph-ficha-clinica',
  standalone: true,
  imports: [
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    HeaderSophiaComponent,
    MenuLateralInfoComponent,
    RegistroComponent,
    AnamnesisComponent,
    DiagnosticoComponent,
    TratamientoComponent,
    ExamenesComponent,
  ],
  templateUrl: './ficha-clinica.component.html',
  styleUrl: './ficha-clinica.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FichaClinicaComponent {
  readonly dialog = inject(MatDialog);

  constructor(
    private readonly _router: Router,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _fichaClinicaService: FichaClinicaService
  ) {
    if (!this._fichaClinicaStore.values.idAgendamiento) {
      this.irAregistro();
    } else {
      this.getFichaClinica();
    }
  }

  private getFichaClinica() {
    this._fichaClinicaService
      .info(this._fichaClinicaStore.values.idAgendamiento!)
      .subscribe({
        next: (response) => {
          if (response.data) {
            this._fichaClinicaStore.updateStore({
              ...this._fichaClinicaStore.values,
              estado: response.data.estado,
            });
          }
        },
        error: () => {
          this.dialog.open(ModalErrorGeneralComponent);
        },
      });
  }

  public irAregistro() {
    this._router.navigate(['/ingreso'], { replaceUrl: true });
  }

  public onSelectedTabChange(event: MatTabChangeEvent) {
    const { index } = event;

    this._fichaClinicaStore.updateStore({
      ...this._fichaClinicaStore.values,
      tabActualSeleccionado: index,
    });
  }
}
