import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { NgOptimizedImage } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ModalHistory360Component } from '../../../../shared/components/modal-history-360/modal-history-360.component';
import { ShareDataService } from '../../../../shared/services/share-data.service';
import { BehaviorSubject, map, Subscription } from 'rxjs';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { HistorialService } from '../../../../shared/services/historial.service';
import { ResponseApiModel } from '../../../../shared/interfaces/api-response.model';
import { Historial360 } from '../../../../interfaces/historial-360.interface';

@Component({
  selector: 'soph-menu-lateral-info',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatExpansionModule,
    NgOptimizedImage,
    CommonModule,
  ],
  templateUrl: './menu-lateral-info.component.html',
  styleUrl: './menu-lateral-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuLateralInfoComponent {
  isExpanded = false;

  private matDialog = inject(MatDialog);
  existePaciente = new BehaviorSubject<any | null>(null);
  @Input()nombrePaciente!: string;
  @Input()apellidosPaciente!: string;
  @Input()historiaClinica!: string;
  @Input()dniPaciente!: string;
  @Input()correoPaciente!: string;
  @Input()telefonoPaciente!: string;
  @Input()previsionPaciente!: string;
  @Input()tipoDocumentoPaciente!: string;
  @Input()valueTipoDocumentoPaciente!: string;
  showCardPaciente = false;
  dataHistorial360: Historial360 | null = null;
  subs: Subscription = new Subscription();

  constructor(
    private readonly _shareDataService: ShareDataService,
    private readonly fichaClinicaStore: FichaClinicaStore,
    private readonly historialService: HistorialService
  ) {}
  ngOnInit(): void {
    this.getDataExistePaciente();
    this._getDataModal360();
  }
  private getDataExistePaciente() {
    this._shareDataService.getDataExistePaciente().subscribe({
      next: (data) => {
        this.existePaciente.next(data);
        if(data){
          this.showCardPaciente = true;
          this.nombrePaciente = this.existePaciente.value.nombres;
          this.apellidosPaciente = this.existePaciente.value.apellidos;
          this.previsionPaciente = this.existePaciente.value.prevision.nombre;
          this.correoPaciente = this.existePaciente.value.correoElectronico;
          this.telefonoPaciente = this.existePaciente.value.telefono;
          this.tipoDocumentoPaciente = this.existePaciente.value.tipoDocumento?.nombre;
          this.valueTipoDocumentoPaciente = this.existePaciente.value.numeroDocumento;

        }
      },
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private _getDataModal360() {
    const { idAgendamiento } = this.fichaClinicaStore.values;
    const sub = this.historialService
      .getHistorial_360(idAgendamiento ?? '')
      .pipe(map((response: ResponseApiModel<Historial360>) => response.data))
      .subscribe((historial) => {
        this.dataHistorial360 = historial;
        this.openHistory360();
      });
    this.subs.add(sub);
  }

  public openHistory360(): void {
    if(!this.dataHistorial360) return;
    this.matDialog.open(ModalHistory360Component, {
      data: this.dataHistorial360,
      maxWidth: '900px',
      minWidth: '400px',
      minHeight: '400px',
    });
  }
}
