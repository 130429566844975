import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  ChangeDetectorRef
} from '@angular/core';
import {
  FormBuilder, FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {ModalRecuperarPasswordComponent} from "../modal-recuperar-password/modal-recuperar-password.component";
import { signInWithEmailAndPassword, Auth } from '@angular/fire/auth';
import { MatIconModule } from '@angular/material/icon';
import {Router} from "@angular/router";
import {CommonModule} from '@angular/common';
import {CrearPasswordComponent} from "./crear-password/crear-password.component";
import {environment} from "../../../../environments/environment";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {InputFormFieldComponent} from "../../../shared/components/input-form-field/input-form-field.component";



interface ErrorMessages {
  required: string;
  pattern: string;
}

@Component({
  selector: 'soph-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    ModalRecuperarPasswordComponent,
    CrearPasswordComponent,
    MatProgressSpinnerModule,
  InputFormFieldComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LoginComponent implements OnInit {

  formularioLogin: FormGroup;
  private auth = inject(Auth);
  public errorMessage: string | null = null;
  public loading = false;
  public showErrorFire = false;
  public hide = true;
  public isLoading = false;


  public crearPass = false;
  tokenTimeout: any;


  constructor(private fb: FormBuilder,
              private router: Router,
              private cdr: ChangeDetectorRef) {
    const formatoEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    this.formularioLogin = this.fb.group({
                email: ['', [Validators.required,
                  Validators.pattern(formatoEmail)]],
                password: ['',  [Validators.required,
                  Validators.minLength(8),
                  Validators.maxLength(12)]]
              });
    this.auth.tenantId = environment.tenantId;

  }


  get errorMessages(): ErrorMessages {
    return {
      required: 'Por favor ingrese un correo electrónico',
      pattern: 'Por favor, ingrese un correo válido',
    };
  }

  get emailControl(): FormControl {
    return this.formularioLogin.get('email') as FormControl;
  }

  get passwordControl(): FormControl {
    return this.formularioLogin.get('password') as FormControl;
  }

  get email() {
    return this.formularioLogin.get('email');
  }
  get password() {
    return this.formularioLogin.get('constraseña');
  }


  ngOnInit(): void {
    this.formularioLogin.valueChanges.
    subscribe(() => {
      this.showErrorFire = false;
    });
  }

 public toggleVisibility() {
    this.hide = !this.hide;
  }

  async onLogin() {
    if (this.formularioLogin.valid) {
      const { email, password } = this.formularioLogin.value;
      this.isLoading = true;
      try {
        this.isLoading = false;
        this.showErrorFire = false;
        const userCredential = await
          signInWithEmailAndPassword(this.auth, email!, password!);
        const token = await
          userCredential.user.getIdToken();
        if (token) {
          try {
            localStorage.setItem('authToken', token);
            if (localStorage.getItem('authToken')) {
              this.crearNuevaconsulta();
            }
          } catch (storageError) {
            console.error(storageError);
          }
        }
      } catch (error: any) {
        this.showErrorFire = true;
        this.handleError(error);
        this.cdr.detectChanges();
        this.isLoading = false;
      } finally {
      }
    }
  }

  setLogoutTimeout(timeUntilExpiration: number) {
    // Limpiar cualquier timeout anterior
    // para evitar múltiples cierres de sesión
    if (this.tokenTimeout) {
      clearTimeout(this.tokenTimeout);
    }

    // Establecer un nuevo timeout
    this.tokenTimeout = setTimeout(() => {
      console.log('El token ha expirado');
      this.logout();
    }, timeUntilExpiration);
  }

  logout() {
    this.auth.signOut().then(() => {
      console.log('Sesión cerrada');
      this.router.navigate(['/login']);
    }).catch((error) => {
    });
  }

  private handleError(error: any) {
    switch (error.code) {
      case 'auth/user-not-found':
        this.errorMessage = 'El email ingresado no tiene cuenta en Sophia, por favor crea tu cuenta';
        break;
      case 'auth/wrong-password':
        this.errorMessage = 'La contraseña ingresada es incorrecta, intente nuevamente';
        break;
      case 'auth/invalid-email':
        this.errorMessage = 'Correo electrónico inválido.';
        break;
      case 'auth/argument-error':
        this.errorMessage = 'Decoding Firebase ID token failed.';
        break;
      default:
        this.errorMessage = 'Error al iniciar sesión. Por favor, intenta de nuevo.';
        break;
    }
  }

  getPasswordErrorMessage(): string {
    const password = this.formularioLogin.get('password');
    if (password?.hasError('required')) {
      return 'Por favor, ingrese una contraseña';

    }
    if (password?.hasError('minlength')) {
      return 'Debes ingresar el menos 6 caractéres';
    }
    if(password?.hasError('maxlength')){
      return 'Debes ingresar un máximo de 10 caractéres';
    }
    return '';
  }

  getEmaildErrorMessage(): string {
    const email = this.formularioLogin.get('email');
    if (email?.hasError('required')) {
      return 'Por favor ingrese un correo electrónico';
    }
    if(email?.hasError('pattern')){
      return 'No se permite el ingreso de caractéres especiales';
    }
    return email?.hasError('email') ? 'Por favor, ingrese un correo válido' : '';

  }


  isFormInvalid(): boolean {
    return this.formularioLogin.invalid || this.showErrorFire;
  }

  public restringirCaracteresEspeciales(event: Event) {

    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    inputElement.value = value.replace(/[^a-zA-Z0-9._@-]/g, '');
  }

  recuperarPassword(){
    this.crearPass = true; // Oculta el contenido
    this.router.navigate(['/recuperar-pass']); // Navega al otro componente
  }

  crearNuevaconsulta(){
    this.router.navigate(['/ingreso']);
  }

}
