import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'soph-header-sophia',
  standalone: true,
  imports: [],
  templateUrl: './header-sophia.component.html',
  styleUrl: './header-sophia.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderSophiaComponent {

}
