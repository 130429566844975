import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { IRespFichaClinica } from '../../interfaces/ficha-clinica.interface';

@Injectable({
  providedIn: 'root',
})
export class FichaClinicaService {
  private clientId = '4b9b9ab5b734408d915ec31751bbf114';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) {}

  info(idAgendamiento: string): Observable<IRespFichaClinica> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.get<IRespFichaClinica>(
      `${environment.baseURL}ficha-clinica/status/${idAgendamiento}`,
      {
        headers,
        params,
      }
    );
  }

  finish(idAgendamiento: string): Observable<IRespFichaClinica> {
    const token = this.authService.getToken();

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const params = new HttpParams().set('client-id', this.clientId);

    return this.httpClient.get<IRespFichaClinica>(
      `${environment.baseURL}ficha-clinica/finish/${idAgendamiento}`,
      {
        headers,
        params,
      }
    );
  }
}
