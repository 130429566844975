import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { IRespOperaciones } from '../interfaces/operaciones.interface';
import { IresProcedimiento } from '../interfaces/procedimientos.interface';
import { IResTerapias } from '../interfaces/terapias.interface';
import { IRespMedicamentos } from '../interfaces/tratamiento/medicamentos.interface';
import { IRespViasAdministracion } from '../interfaces/vias-admin.interface';
import { IRespFrecuencias } from '../interfaces/tratamiento/terapias.interface';

@Injectable({
  providedIn: 'root',
})
export class TabTratamientoService {
  private apiUrl = environment.baseURL + 'datos-medicos';
  clientId = '4b9b9ab5b734408d915ec31751bbf114';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService
  ) {}

    getMedicamentos(): Observable<IRespMedicamentos>{
      const token = this.authService.getToken();
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
      const params =new
      HttpParams()
        .set('client-id', this.clientId)
      return this.httpClient.get<IRespMedicamentos>(this.apiUrl+'/medicamentos', {headers, params});
    }

    getProcedimientos(): Observable<IresProcedimiento>{
      const token = this.authService.getToken();
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`
      });
      const params =new
      HttpParams()
        .set('client-id', this.clientId)
      return this.httpClient.get<IresProcedimiento>(this.apiUrl+'/procedimientos', {headers, params});
    }

  getTerapias(): Observable<IResTerapias> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const params = new HttpParams().set('client-id', this.clientId);
    return this.httpClient.get<IResTerapias>(this.apiUrl + '/terapias', {
      headers,
      params,
    });
  }

  getOperaciones(): Observable<IRespOperaciones> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const params = new HttpParams().set('client-id', this.clientId);
    return this.httpClient.get<IRespOperaciones>(this.apiUrl + '/operaciones', {
      headers,
      params,
    });
  }

  getViasAdministracion(): Observable<IRespViasAdministracion> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const params = new HttpParams().set('client-id', this.clientId);
    return this.httpClient.get<IRespViasAdministracion>(this.apiUrl + '/vias-administracion', {
      headers,
      params,
    });
  }
  getFrecuencias(): Observable<IRespFrecuencias> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    const params = new HttpParams().set('client-id', this.clientId);
    return this.httpClient.get<IRespFrecuencias>(this.apiUrl + '/frecuencias', {
      headers,
      params,
    });
  }
}
