import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { IconsService } from './icons.service';

@NgModule({
  imports: [CommonModule, MatIconModule],
  providers: [IconsService],
})
export class IconsModule {
  constructor(private readonly iconsService: IconsService) {}
}
