<mat-form-field
  [formGroup]="formGroup"
  appearance="outline"
  subscriptSizing="dynamic"
  floatLabel="always"
>
  <mat-icon matPrefix>search</mat-icon>

  @if(label) {
  <mat-label>{{ label }}</mat-label>
  }

  <input [formControlName]="controlName" hidden />

  <input
    matInput
    #autoTrigger="matAutocompleteTrigger"
    type="text"
    [placeholder]="placeholderTemp() ? placeholderTemp() : placeholder"
    [formControl]="searchCtrl"
    [matAutocomplete]="auto"
    [required]="formGroup.controls[controlName].hasError('required')"
    (focus)="$event.stopPropagation(); autoTrigger.openPanel()"
    (blur)="$event.stopPropagation()"
    (click)="$event.stopPropagation()"
  />

  <mat-icon matSuffix>arrow_drop_down</mat-icon>

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    role="checkbox"
    (closed)="getSelectedValuesTag('panel-closed')"
  >
    @for(item of filteredValues(); track item) {
    <mat-option (click)="$event.stopPropagation()">
      <div (click)="$event.stopPropagation(); toggleSelection(item)">
        <mat-checkbox
          [checked]="item['selected']"
          (change)="toggleSelection(item)"
          (click)="$event.stopPropagation()"
        >
          <span
            class="custom-checkbox-label"
            (click)="$event.stopPropagation()"
            >{{ item[viewField] }}</span
          >
        </mat-checkbox>
      </div>
    </mat-option>
    }
  </mat-autocomplete>

  @if (hasError) {
  <mat-error>{{ errorMessage }}</mat-error>
  }
</mat-form-field>
