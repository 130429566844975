<h3>Procedimientos</h3>
<p>Busca y selecciona los medicamentos que incluirás en el tratamiento</p>
<soph-multi-select-search-form-field
    [formGroup]="formProcedimientos"
    controlName="procedimientos"
    placeholder="Seleccionar procedimiento"
    [values]="procedimientosOptions"
    [limitValues]="50"
    idField="idProcedimiento"
    viewField="nombre">
</soph-multi-select-search-form-field>
<div *ngIf="procedimientos.value && procedimientos.value.length > 0" class="selected-items">
  <h3>Procedimientos seleccionados</h3>
  <div class="form-section-grupo" *ngFor="let proced of procedimientosSelected; index as i">
    <mat-divider></mat-divider>
    <mat-accordion class="full-width custom-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title class="style-tittle-colapse">
            {{ proced.codigo+' '+ proced.nombre }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="section-eliminar">
          <button
            mat-button
            (click)="unselectProced(proced.idProcedimiento)"
          >
            <mat-icon svgIcon="trash"></mat-icon>
            Eliminar
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-divider *ngIf="i === procedimientosSelected.length - 1"></mat-divider>
  </div>
</div>
<!-- procedimientos guardados -->
@if(procedimientosGuardados.length > 0) {
<div class="parent-container">
  <div class="container-remedios">
    <div class="remedio-guardado" *ngFor="let proced of procedimientosGuardados">
      {{ proced.codigo+' '+ proced.nombre }}
    </div>
  </div>
</div>
}

