import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { Auth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { UserService } from "../../../../shared/services/user/user.service";
import { Subscription } from "rxjs";
import { Profile } from "../../../../shared/interfaces/perfil.interface";
import { SessionKeys } from "../../../../shared/enums";

@Component({
  selector: 'soph-header-sophia',
  standalone: true,
  imports: [],
  templateUrl: './header-sophia.component.html',
  styleUrl: './header-sophia.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderSophiaComponent implements OnInit, OnDestroy {

  private auth = inject( Auth );
  private userService = inject(UserService);
  private router= inject(Router);
  private subscriptions: Subscription[] = [];
  userInfo: Profile | undefined;
  userName: WritableSignal<string | undefined> = signal( undefined );

  ngOnInit(): void {
    this.userService.getUserInfo();
    const subscription = this.userService.user.subscribe( value => {
      this.userInfo = value;
      const { medicos } = value!;
      if ( medicos.nombreCompleto ) this.userName.set( medicos.nombreCompleto.split(' ')[0] );
    });
    this.subscriptions.push( subscription );
  }

  async logout(): Promise<void> {
    this.userService.user.next( undefined );
    sessionStorage.removeItem( SessionKeys.USER );
    await this.auth.signOut();
    await this.router.navigate(['login']);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( subscription =>  subscription.unsubscribe() );
  }
}
