import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { distinctUntilChanged } from 'rxjs';
import { TabPosition } from '../ficha-clinica.component';
import { SelectSearchFormFieldComponent } from '../../../../shared/components/select-search-form-field/select-search-form-field.component';
import { TextareaWithSpeechToTextFormFieldComponent } from '../../../../shared/components/textarea-with-speech-to-text-form-field/textarea-with-speech-to-text-form-field.component';
import { InputWithChipFormFieldV2Component } from '../../../../shared/components/input-with-chip-form-field-v2/input-with-chip-form-field-v2.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  ModalConfirmComponent,
  ModalConfirmProps,
  ModalConfirmType,
} from '../../../../shared/components/modal-confirm/modal-confirm.component';
import { FichaClinicaService } from '../../../services/ficha-clinica.service';
import { AppointmentStatus } from '../../../../shared/enums';
import { ModalErrorGeneralComponent } from '../../../common/modal-error-general/modal-error-general.component';
import { isFinalizedStatus } from '../../../../shared/helpers/ficha-clinica.helper';

interface ExamenesForm {
  tipoExamen: FormControl<string | null>;
  examenes: FormControl<Array<any> | null>;
  archivos: FormControl<Array<any> | null>;
  diagnosticoExamen: FormControl<string | null>;
}

@Component({
  selector: 'soph-examenes',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    SelectSearchFormFieldComponent,
    TextareaWithSpeechToTextFormFieldComponent,
    InputWithChipFormFieldV2Component,
  ],
  templateUrl: './examenes.component.html',
  styleUrl: './examenes.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExamenesComponent {
  readonly path = 'examenes';
  readonly dialog = inject(MatDialog);
  readonly snackBar = inject(MatSnackBar);

  public typeExamn = signal<any[]>([
    {
      idTipoExamen: '1',
      nombre: 'Laboratorio',
    },
    {
      idTipoExamen: '2',
      nombre: 'Imágenes',
    },
  ]);

  public exams = signal<any[]>([
    {
      idExamen: '1',
      nombre: 'Hemograma',
    },
    {
      idExamen: '2',
      nombre: 'Hematocrito',
    },
    {
      idExamen: '3',
      nombre: 'Hemoglobina',
    },
    {
      idExamen: '4',
      nombre: 'Recuento de plaquetas',
    },
    {
      idExamen: '5',
      nombre: 'Pefil lipídico',
    },
    {
      idExamen: '6',
      nombre: 'Perfil hepático',
    },
  ]);

  readonly isAllTabsCompleted = signal(false);

  public form: FormGroup<ExamenesForm>;
  readonly errorMessage: WritableSignal<{ [key: string]: string }> = signal({});

  private readonly examenes = signal<any | null>(null);
  public readonly hasChanges = signal<boolean>(false);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _fichaClinicaService: FichaClinicaService
  ) {
    this.form = this._fb.group({
      tipoExamen: new FormControl<string | null>(null, Validators.compose([])),
      examenes: new FormControl<Array<any> | null>(
        null,
        Validators.compose([])
      ),
      archivos: new FormControl<Array<any> | null>(
        null,
        Validators.compose([])
      ),
      diagnosticoExamen: new FormControl<string | null>(
        null,
        Validators.compose([])
      ),
    });

    this._fichaClinicaStore.state$
      .pipe(distinctUntilChanged())
      .subscribe((values) => {
        if (values.tabActualSeleccionado === TabPosition.Examenes) {
          this.isAllTabsCompleted.set(
            Object.values(values.historial).every((value) => value === true) &&
              !isFinalizedStatus(values.estado || '')
          );

          this.hasChanges.set(this.isAllTabsCompleted());
        }
      });

    this._fichaClinicaStore.updateMedicalHistoryBySection('examenes', true);
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((newValues) => {
      this.hasChanges.set(this.checkForChanges(newValues));
    });
  }

  public updateErrorMessage(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    const currentErrorMessages = this.errorMessage();

    if (control?.hasError('required')) {
      currentErrorMessages[fieldName] = 'Este campo es obligatorio';
    } else {
      currentErrorMessages[fieldName] = '';
    }

    this.errorMessage.set({ ...currentErrorMessages });
  }

  public isRequired(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    return control?.hasError('required') ?? false;
  }

  public isInvalid(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    return control?.invalid ?? false;
  }

  public revertChanges() {
    if (this.examenes()) {
      this.form.patchValue({
        ...this.examenes(),
      });
    } else {
      this.form.reset();
    }
  }

  private checkForChanges(newValues: any): boolean {
    if (this.examenes()) {
      return JSON.stringify(newValues) !== JSON.stringify(this.examenes());
    } else {
      const defaultValue = {
        tipoExamen: null,
        examenes: null,
        archivos: null,
        diagnosticoExamen: null,
      };

      return JSON.stringify(defaultValue) !== JSON.stringify(newValues);
    }
  }

  public onSave(form: FormGroup) {
    if (form.valid) {
      const { value } = form;

      if (this.isAllTabsCompleted()) {
        this.onFinishAppointmentConfirmation();
      }
    }
  }

  private onFinishAppointmentConfirmation() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      autoFocus: false,
      panelClass: 'custom-confirmation-dialog',
      width: '550px',
      disableClose: true,
      data: {
        type: ModalConfirmType.WARNING,
        description: '¿Confirmas que deseas finalizar la consulta?',
        message:
          'Los datos ingresados se guardarán y solo algunos datos específicos podrán ser editados posteriormente.',
        config: {
          buttons: [
            { title: 'Volver', action: false },
            { title: 'Finalizar consulta', action: true },
          ],
        },
      } as ModalConfirmProps,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result === 'boolean' && result) {
        this.onFinishAppointment();
      }
    });
  }

  private onFinishAppointment() {
    this._fichaClinicaService
      .finish(this._fichaClinicaStore.values.idAgendamiento!)
      .subscribe({
        next: () => {
          this._fichaClinicaStore.updateStore({
            ...this._fichaClinicaStore.values,
            estado: AppointmentStatus.REALIZADA,
          });

          this.onFinishAppointmentFinished();
        },
        error: () => {
          this.dialog.open(ModalErrorGeneralComponent);
        },
      });
  }

  private onFinishAppointmentFinished() {
    this.dialog.open(ModalConfirmComponent, {
      autoFocus: false,
      panelClass: 'custom-confirmation-dialog',
      width: '452px',
      disableClose: true,
      data: {
        type: ModalConfirmType.SUCCESS,
        description: 'La consulta ha sido finalizada y guardada con éxito',
        message:
          'La ficha clínica de esta consulta está lista para ser revisada cuando lo necesites',
        config: {
          buttons: [{ title: 'Aceptar', action: true }],
        },
      } as ModalConfirmProps,
    });
  }
}
