import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../pages/services/authentication.service';
import { Observable } from 'rxjs';
import { IRespAcompanante } from '../interfaces/existe-acompanante.interface';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExisteAcompananteService {
  private apiUrl = environment.baseURL + 'acompaniante/existe-acompaniante';
  private apiUrlParentezco = environment.baseURL + 'datos-personales/parentescos'; 

  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) {
  }

  getExisteAcompanante(numeroDocumento: string): Observable<IRespAcompanante> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', '4b9b9ab5b734408d915ec31751bbf114')
      .set('numeroDocumento', numeroDocumento);
    return this.httpClient.get<IRespAcompanante>(this.apiUrl, {headers, params});
  }

  getParentezcoAcompanante(): Observable<any> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', '4b9b9ab5b734408d915ec31751bbf114')
    return this.httpClient.get<any>(this.apiUrlParentezco, {headers, params});
  }
}
