import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'soph-file-stepper',
  standalone: true,
  providers: [{ provide: CdkStepper, useExisting: FileStepperComponent }],
  imports: [NgTemplateOutlet, CdkStepperModule, MatButtonModule, MatIconModule],
  templateUrl: './file-stepper.component.html',
  styleUrl: './file-stepper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileStepperComponent extends CdkStepper {
  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
