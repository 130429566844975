<h2 mat-dialog-title class="titulo-modal">Bloquear horario</h2>
<div mat-dialog-content>
  <div class="info-box">
    <mat-icon>event_busy</mat-icon>
    <p>Ingresa la fecha y hora que desees bloquear para no recibir reservas de consultas</p>
  </div>
  <form [formGroup]="blockForm">

    <mat-form-field appearance="outline">
    <mat-label>Seleccione el rango de fecha</mat-label>
    <mat-date-range-input [formGroup]="blockForm" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Fecha de inicio">
      <input matEndDate formControlName="end" placeholder="Fecha de termino">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

  </mat-form-field>


    <mat-form-field appearance="outline">
      <mat-label>Hora inicio</mat-label>
      <mat-select [(value)]="horaInicio" formControlName="startTime">
        <mat-option *ngFor="let time of times" [value]="time">
          {{ time }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Hora termino</mat-label>
      <mat-select [(value)]="horaTermino" formControlName="endTime">
        <mat-option *ngFor="let time of times" [value]="time">
          {{ time }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Motivo</mat-label>
      <input matInput formControlName="reason" >
    </mat-form-field>


  </form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button
          [mat-dialog-close]="blockForm.value"
          [disabled]="blockForm.invalid"
          (click)="bloquearHorario()"
  >Bloquear horario</button>
</div>
