import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthenticationService} from "../../pages/services/authentication.service";
import {Observable} from "rxjs";
import {IDataEnitas, IRespEtnias} from "../interfaces/etnias.interface";
import {IRespReligion} from "../interfaces/religion.interface";
import {IResGrupoSanguineo} from "../interfaces/grupo-sanguineo.interface";
import {IResOcupaciones} from "../interfaces/ocupacion.interface";
import {IResEstadoCivil} from "../interfaces/estado-civil.interface";
import {IResGenero} from "../interfaces/genero.interface";
import {IResRegiones} from "../interfaces/regiones.interface";
import { IRespPaises } from '../interfaces/paises.interface';

@Injectable({
  providedIn: 'root'
})
export class ServiceFormIdentificacionService {

  private apiUrl = 'https://dev.api.sophia.seiza-ti.cl/api/v1/datos-personales';
  clientId = '4b9b9ab5b734408d915ec31751bbf114';
  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) { }



  getEtnias(): Observable<IRespEtnias>{
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', this.clientId)
    return this.httpClient.get<IRespEtnias>(this.apiUrl+'/etnias', {headers, params});
  }

  getReligion(): Observable<IRespReligion>{
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', this.clientId)
    return this.httpClient.get<IRespReligion>(this.apiUrl+'/religion', {headers, params});
  }

  getGrupoSanguineo(): Observable<IResGrupoSanguineo>{
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', this.clientId)
    return this.httpClient.get<IResGrupoSanguineo>(this.apiUrl+'/grupos-sanguineos', {headers, params});
  }

  geInstrucciones(): Observable<IResOcupaciones>{
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', this.clientId)
    return this.httpClient.get<IResOcupaciones>(this.apiUrl+'/grado-instruccion', {headers, params});
  }

  getEstadoCivil(): Observable<IResEstadoCivil>{
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', this.clientId)
    return this.httpClient.get<IResEstadoCivil>(this.apiUrl+'/estado-civil', {headers, params});
  }

  getGenero(): Observable<IResGenero>{
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', this.clientId)
    return this.httpClient.get<IResGenero>(this.apiUrl+'/sexo', {headers, params});
  }

  getRegiones(): Observable<IResRegiones>{
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', this.clientId)
    return this.httpClient.get<IResRegiones>(this.apiUrl+'/regiones', {headers, params});
  }

  getPaises(): Observable<IRespPaises>{
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', this.clientId)
    return this.httpClient.get<IRespPaises>(this.apiUrl+'/paises', {headers, params});
  }
}
