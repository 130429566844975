<div class="container-parent" (mouseleave)="showMessage = false">
  <div class="container">
    <div class="image-picker">
      <label
        class="upload-content"
        [ngClass]="{ 'error-border': isControlInvalid(), 
        'disabled': isDisabled, 
        'text-disabled': isDisabled  }"
      >
        <div class="custom-file-upload">
          <img src="assets/icons/clip.svg" class="icon" />
          <span class="custom-file-text"
          [class.text-disabled]="isDisabled">Adjuntar exámen</span>
          <input
            type="file"
            (change)="onFileSelected($event)"
            accept="image/*,application/pdf"
            multiple
            [disabled]="isDisabled"
          />
        </div>
        <p *ngIf="showEye" class="eye-tooltip">Ver archivo</p>
        <p *ngIf="showDelete" class="close-tooltip">Eliminar archivo</p>

        <div
          *ngFor="let doc of documentosCargados"
          [ngClass]="{ hasDocumentUrl: hasDocumentUrl }"
          class="image-card"
        >
          <span class="file-name">{{ doc.fileName }}</span>

          <img
            src="assets/icons/eye_upload.svg"
            class="icon card-icon-eye"
            (click)="onImageOpen($event, doc)"
            [class.disabled]="isDisabled"
            (mouseover)="showEye = true"
            (mouseleave)="showEye = false"
          />

          <img
            src="assets/icons/delete_upload.svg"
            class="icon card-icon-close"
            (click)="onImageDelete($event, doc)"
            [class.disabled]="isDisabled"
            (mouseover)="showDelete = true"
            (mouseleave)="showDelete = false"
          />
        </div>

        <div *ngIf="uploading && percentLoaded !== 100" class="spinner">
          <span>{{ "register.textUploading" }}</span>
        </div>
      </label>
    </div>
  </div>

  <p class="format" *ngIf="!isControlInvalid()">
    Formatos JPG, PNG o PDF con un peso máximo de 3MB.
  </p>
  <p  class="format-error" *ngIf="maximoCarga">El archivo seleccionado supera el límite de 3 MB.</p>
  <p  class="format-error" *ngIf="formatInvalid">El archivo seleccionado no es una imagen o un PDF.</p>
</div>
