import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';


@Component({
  selector: 'soph-modal-nueva-consulta',
  standalone: true,
    imports: [CommonModule,
      MatDialogModule,
      MatButtonModule,
      MatFormFieldModule,
      MatInputModule,
      MatIconModule,
      MatSelectModule
    ],
  templateUrl: './modal-nueva-consulta.component.html',
  styleUrl: './modal-nueva-consulta.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalNuevaConsultaComponent {
  constructor(public dialogRef: MatDialogRef<ModalNuevaConsultaComponent>) {}

  close(): void {
    this.dialogRef.close();
  }

}
