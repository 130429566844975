import { ChangeDetectionStrategy, Component } from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {Router} from "@angular/router";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'soph-modal-pass-exitosa',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './modal-pass-exitosa.component.html',
  styleUrl: './modal-pass-exitosa.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalPassExitosaComponent {

  constructor(private router: Router,
              private dialogRef: MatDialogRef<ModalPassExitosaComponent>) {
  }

  volverAlogin(){
    this.dialogRef.close();
    this.router.navigate(['/login']); // redireccionamiento login
  }
}
