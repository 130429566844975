<h3>Medicamentos</h3>
<p>Busca y selecciona los medicamentos que incluirás en el tratamiento</p>
<soph-multi-select-search-form-field
  [formGroup]="formMedicamentos"
  controlName="medicamentos"
  placeholder="Seleccionar medicamento"
  [values]="medicamentosOptions"
  [limitValues]="50"
  idField="idMedicamento"
  viewField="nombre"
>
</soph-multi-select-search-form-field>
<div
  *ngIf="medicamentos.value && medicamentos.value.length > 0"
  class="selected-items"
>
  <h3>Medicamentos seleccionados</h3>
  <p>Ingresa la frecuencia horaria y la vía de administración</p>
  <div class="form-section-grupo" *ngFor="let medicamento of medsSelected; index as i">
    <mat-divider></mat-divider>
    <mat-accordion class="full-width custom-accordion">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title class="style-tittle-colapse">
            {{ medicamento.nombreMedicamento }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form-section-row input-grid">
          <soph-input-form-field
            *ngFor="let control of medicamento.controles.generales"
            [control]="control.medControl"
            [label]="control.labelControl"
            [errorMessages]="{ required: 'Campo obligatorio.' }"
          >
          </soph-input-form-field>
        </div>
        <div class="form-section-row input-full-width">
          <soph-select-form-field
            [control]="medicamento.controles.viaAdmin.medControl"
            [options]="viasAdminOptions"
            [label]="medicamento.controles.viaAdmin.labelControl"
            [errorMessages]="{ required: 'Campo obligatorio.' }"
            valueProperty="idViaAdministracion"
            displayProperty="nombre"
          >
          </soph-select-form-field>
        </div>
        <div class="section-eliminar">
          <button mat-button (click)="unselectMed(medicamento.idMedicamento)">
            <mat-icon svgIcon="trash"></mat-icon>
            Eliminar
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-divider *ngIf="i === medsSelected.length - 1"></mat-divider>
  </div>
</div>
<!-- Remedios guardados -->
@if(medicamentosGuardados.length > 0) {
<div class="parent-container">
  <div class="container-remedios">
    <div class="remedio-guardado" *ngFor="let med of medicamentosGuardados">
      {{
        med.nombreMedicamento +
          " " +
          med.dosis +
          ". Cada " +
          med.horario +
          ". Duración " +
          med.duracion
      }}
    </div>
  </div>
</div>
}
