import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'soph-modal-detalle-consulta',
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule],
  templateUrl: './modal-detalle-consulta.component.html',
  styleUrl: './modal-detalle-consulta.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalDetalleConsultaComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
