import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@environments/environment";
import {ResponseApiModel} from "../interfaces/api-response.model";
import {AuthenticationService} from "../../pages/services/authentication.service";
import {Historial360} from "../../interfaces/historial-360.interface";

@Injectable({
  providedIn: 'root'
})
export class HistorialService {

  #http = inject(HttpClient);

  public getHistorial_360(idAgendamiento: string): Observable<ResponseApiModel<Historial360>> {
    const params = new HttpParams()
    .set('idAgendamiento', idAgendamiento)

    return this.#http.get<ResponseApiModel<Historial360>>(this._urlHistorial_360(), { params });
  }

  private _urlHistorial_360(): string {
    return `${environment.baseURL}${environment.endpoint.modal_360}`
  }

}
