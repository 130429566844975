import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable} from 'rxjs';
import {IRespSeguro} from "../interfaces/seguros.interface";
import {AuthenticationService} from "../../pages/services/authentication.service";
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ObtenerPrevisionService {
  private apiUrl = environment.baseURL + 'datos-medicos/previsiones';


  constructor(private httpClient: HttpClient,
              private authService: AuthenticationService) { }

  getPrevision(): Observable<IRespSeguro> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    const params =new
    HttpParams()
      .set('client-id', '4b9b9ab5b734408d915ec31751bbf114')
    return this.httpClient.get<IRespSeguro>(this.apiUrl, {headers, params});
  }
}

