import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { TextareaWithSpeechToTextFormFieldComponent } from '../../../../shared/components/textarea-with-speech-to-text-form-field/textarea-with-speech-to-text-form-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { distinctUntilChanged, forkJoin } from 'rxjs';
import { MultiSelectSearchFormFieldComponent } from '../../../../shared/components/multi-select-search-form-field/multi-select-search-form-field.component';
import { MatListModule } from '@angular/material/list';
import { IDataDiagnosticos } from '../../../../shared/interfaces/diagnosticos.interface';
import { ServiceFormDiagnosticoService } from '../../../../shared/services/service-form-diagnostico.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalErrorGeneralComponent } from '../../../common/modal-error-general/modal-error-general.component';
import { DiagnosticoService } from '../../../services/diagnostico.service';
import {
  SnackInfoComponent,
  SnackInfoProps,
  SnackInfoType,
} from '../../../../shared/components/snack-info/snack-info.component';
import { IDataDiagnostico } from '../../../../interfaces/diagnostico.interface';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { isFinalizedStatus } from '../../../../shared/helpers/ficha-clinica.helper';

interface DiagnosticoForm {
  tipoDiagnostico: FormControl<string | null>;
  diagnosticos: FormControl<Array<string> | null>;
  observacion: FormControl<string | null>;
}

@Component({
  selector: 'soph-diagnostico',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCheckboxModule,
    MatListModule,
    TextareaWithSpeechToTextFormFieldComponent,
    MultiSelectSearchFormFieldComponent,
  ],
  templateUrl: './diagnostico.component.html',
  styleUrl: './diagnostico.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiagnosticoComponent {
  readonly path = 'diagnostico';
  readonly dialog = inject(MatDialog);
  readonly snackBar = inject(MatSnackBar);

  public form: FormGroup<DiagnosticoForm>;

  public diagnoses = signal<Array<IDataDiagnosticos>>([]);
  readonly tipoDiagnositico = signal<string | null>(null);
  readonly selectedValuesTag: WritableSignal<Array<any>> = signal([]);

  private readonly diagnostico = signal<IDataDiagnostico | null>(null);
  public readonly hasChanges = signal<boolean>(false);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _diagnosticoDataService: ServiceFormDiagnosticoService,
    private readonly _diagnosticoService: DiagnosticoService,
    private readonly _fichaClinicaStore: FichaClinicaStore
  ) {
    this.form = this._fb.group({
      tipoDiagnostico: new FormControl<string | null>(
        null,
        Validators.compose([Validators.required])
      ),
      diagnosticos: new FormControl<Array<string> | null>(
        null,
        Validators.compose([Validators.required])
      ),
      observacion: new FormControl<string | null>(
        null,
        Validators.compose([Validators.maxLength(1000)])
      ),
    });

    this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((newValues) => {
        this.hasChanges.set(this.checkForChanges(newValues));
      });

    this._fichaClinicaStore.state$
      .pipe(distinctUntilChanged())
      .subscribe((values) => {
        this.toggleFormState(isFinalizedStatus(values.estado || ''));
      });

    this.form
      .get('diagnosticos')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((values) => {
        if (values) {
          this.selectedValuesTag.set(
            this.diagnoses().filter((item) =>
              values.includes(item.idDiagnostico)
            )
          );
        }
      });
  }

  ngOnInit() {
    this.getData();
  }

  private toggleFormState(shouldDisable: boolean) {
    shouldDisable ? this.form.disable() : this.form.enable();
  }

  public onRadioButtonClick(value: string) {
    if (!this.form.controls['tipoDiagnostico'].disabled) {
      if (!!this.tipoDiagnositico() && this.tipoDiagnositico() !== value) {
        this.selectedValuesTag.set([]);
        this.form.reset();
        this.form.updateValueAndValidity();
      }

      this.tipoDiagnositico.set(value);
      this.form.get('tipoDiagnostico')?.setValue(this.tipoDiagnositico());
    }
  }

  public eliminarDiagnosticoSeleccionado(id: string) {
    const selectedValues = this.form.get('diagnosticos')?.value || [];
    const filteredValues = selectedValues?.filter((value) => value !== id);
    this.form.get('diagnosticos')?.setValue(filteredValues);
  }

  public onSave(form: FormGroup) {
    form.markAllAsTouched();

    if (form.valid) {
      const { value } = form;

      this._diagnosticoService
        .guardar({
          ...value,
          idAgendamiento: this._fichaClinicaStore.values.idAgendamiento,
        })
        .subscribe({
          next: () => {
            this.diagnostico.set({
              ...value,
            });

            this.form.updateValueAndValidity();

            this._fichaClinicaStore.updateMedicalHistoryBySection(
              'diagnostico',
              true
            );

            this.snackBar.openFromComponent(SnackInfoComponent, {
              data: {
                message: 'Los cambios han sido guardado con éxito',
                type: SnackInfoType.SUCCESS,
              } as SnackInfoProps,
            });
          },
          error: () => {
            this.snackBar.openFromComponent(SnackInfoComponent, {
              data: {
                message:
                  'No pudimos guardar los cambios, por favor intenta nuevamente',
                type: SnackInfoType.ERROR,
              } as SnackInfoProps,
            });
          },
        });
    }
  }

  public revertChanges() {
    if (this.diagnostico()) {
      this.form.patchValue({
        ...this.diagnostico(),
      });
    } else {
      this.form.reset();
      this.tipoDiagnositico.set(null);
    }
  }

  private checkForChanges(newValues: any): boolean {
    if (this.diagnostico()) {
      return JSON.stringify(newValues) !== JSON.stringify(this.diagnostico());
    } else {
      const defaultValue = {
        tipoDiagnostico: null,
        diagnosticos: null,
        observacion: null,
      };

      return JSON.stringify(defaultValue) !== JSON.stringify(newValues);
    }
  }

  private getData() {
    forkJoin([this._diagnosticoDataService.getDiagnosticos()]).subscribe({
      next: ([diagnosticoResponse]) => {
        this.diagnoses.set(diagnosticoResponse.data);

        if (this._fichaClinicaStore.values.idAgendamiento) {
          this.getDiagnostico();
        }
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }

  private getDiagnostico() {
    this._diagnosticoService
      .obtener(this._fichaClinicaStore.values.idAgendamiento!)
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.form.reset();

            this.diagnostico.set(response.data);

            this.tipoDiagnositico.set(response.data.tipoDiagnostico);

            this.selectedValuesTag.set(
              this.diagnoses().filter((item) =>
                response.data.diagnosticos.includes(item.idDiagnostico)
              )
            );

            this.form.patchValue({
              ...response.data,
            });
          }
        },
        error: () => {
          this.dialog.open(ModalErrorGeneralComponent);
        },
      });
  }
}
