<div>
  <div class="container">
    <div class="header">
      @if(!isLoading()) {
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
      }
    </div>

    <div class="content">
      @if(pdfPreview().length === 0) {
      <mat-spinner></mat-spinner>
      } @else {
      <soph-file-stepper>
        @for(item of pdfPreview(); track item) {
        <cdk-step>
          <img [src]="item.imageUrl" class="pdf-image" />

          <ng-template
            [ngTemplateOutlet]="actions"
            [ngTemplateOutletContext]="{ data: item }"
          ></ng-template>
        </cdk-step>
        }
      </soph-file-stepper>
      }
    </div>

    <ng-template #actions let-data="data">
      <div class="footer">
        <button
          mat-flat-button
          color="primary"
          (click)="printPdf(data.downloadUrl)"
        >
          <mat-icon>print</mat-icon>Imprimir
        </button>

        <button
          mat-flat-button
          color="primary"
          (click)="downloadPdf(data.downloadUrl)"
        >
          <mat-icon>download</mat-icon>Descargar
        </button>

        <button mat-flat-button color="primary" (click)="sendPdf(data.name)">
          <mat-icon>forward_to_inbox</mat-icon>Enviar
        </button>
      </div>
    </ng-template>
  </div>
</div>
