import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileStepperComponent } from './file-stepper/file-stepper.component';
import * as pdfjsLib from 'pdfjs-dist';
import { Subject } from 'rxjs';

/*webpackIgnore: true*/
pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.mjs';

export enum FileType {
  PDF,
}

export interface ModalFileViewerData {
  files: Array<{
    name: string;
    blob: Blob;
  }>;
  type: FileType;
}

@Component({
  selector: 'soph-modal-file-viewer',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    forwardRef(() => FileStepperComponent),
    CdkStepperModule,
  ],
  templateUrl: './modal-file-viewer.component.html',
  styleUrl: './modal-file-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFileViewerComponent {
  public readonly sendAction$: Subject<string> = new Subject();

  public pdfPreview: WritableSignal<
    Array<{
      imageUrl: string;
      downloadUrl: string;
      name: string;
    }>
  > = signal([]);

  readonly isLoading = signal(false);

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalFileViewerData) {
    const files = data.files.map((data) => data.blob);

    if (this.isArrayOfBlobs(files) && data.type === FileType.PDF) {
      this.loadAllPdfs(data.files);
    }
  }

  private isArrayOfBlobs(data: Array<Blob>): boolean {
    return Array.isArray(data) && data.every((file) => file instanceof Blob);
  }

  private loadAllPdfs(
    files: Array<{
      name: string;
      blob: Blob;
    }>
  ) {
    this.isLoading.set(true);

    const allImages: Array<{ imageUrl: string; downloadUrl: string; name: string } | null> =
      new Array(files.length).fill(null);

    let processedCount = 0;

    files.forEach((data, index) => {
      this.processPdf(data, index, allImages, () => {
        processedCount++;
        if (processedCount === files.length) {
          this.pdfPreview.set(allImages.filter((item) => item !== null) as Array<{
            imageUrl: string;
            downloadUrl: string;
            name: string;
          }>);
          this.isLoading.set(false);
        }
      });
    });
  }

  private processPdf(
    data: {
      name: string;
      blob: Blob;
    },
    index: number,
    allImages: Array<{ imageUrl: string; downloadUrl: string; name: string } | null>,
    onComplete: () => void
  ) {
    const blobUrl = URL.createObjectURL(data.blob);

    const loadingTask = pdfjsLib.getDocument(blobUrl);

    loadingTask.promise.then((pdf) => {
      const images: { imageUrl: string; downloadUrl: string; name: string }[] =
        [];

      for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
        pdf.getPage(pageNumber).then((page) => {
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          if (context) {
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            page.render(renderContext).promise.then(() => {
              const imageUrl = canvas.toDataURL('image/png');

              images.push({ imageUrl, downloadUrl: blobUrl, name: data.name });

              if (images.length === pdf.numPages) {
                allImages[index] = images[0];
                onComplete();
              }
            });
          }
        });
      }
    });
  }

  printPdf(url: string) {
    window.open(url, '_blank');
  }

  downloadPdf(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'documento.pdf';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  sendPdf(reportName: string) {
    this.sendAction$.next(reportName);
  }
}
