<div class="container">
  <div class="full-width">
    <div class="input-container">
      <div class="input-half">
        <!-- Medicamentos -->
        <soph-medicamentos
          (isFormValid)="isFormMedicamentosValid = $event"
        ></soph-medicamentos>
      </div>

      <div class="input-half">
        <!-- Terapias -->
        <soph-terapias
        (isFormValid)="isFormTerapiasValid = $event"></soph-terapias>
      </div>
    </div>
  </div>

  <div class="full-width">
    <mat-divider></mat-divider>

    <div class="input-container">
      <div class="input-half">
        <soph-procedimientos></soph-procedimientos>
      </div>

      <div class="input-half">
        <soph-operaciones></soph-operaciones>
      </div>
    </div>
  </div>

  <div class="full-width">
    <mat-divider></mat-divider>

    <div class="input-container">
      <div class="input-total">
        <p class="texto-info">Indicaciones extra</p>
        <p>Ingresa las notas que necesites con respecto al diagnóstico</p>
        <soph-textarea-with-speech-to-text-form-field
          [control]="indicacionesExtra"
          label="Indicaciones extra"
          placeholder="Indicaciones extra"
          [maxLength]="1000"
          [errorMessages]="{
            maxlength: 'Ha excedido el número máximo de caracteres'
          }"
        >
        </soph-textarea-with-speech-to-text-form-field>
      </div>
    </div>
  </div>

  <div class="button-container">
    <button *ngIf="showBtnCancelar()" mat-stroked-button (click)="cancelar()">Cancelar</button>
    <button
      *ngIf="showBtnGuardar()"
      mat-flat-button
      class="save-btn"
      color="primary"
      (click)="guardarTratamiento()"
      [disabled]="!allFormsValid()"
    >
      Guardar
    </button>
    <button
      *ngIf="showBtnEditar()"
      mat-flat-button
      class="save-btn"
      color="primary"
      (click)="editarTratamiento()"
    >
      Editar
    </button>
  </div>
</div>
