import { inject, Injectable } from '@angular/core';
import { ReportesService } from '../reportes.service';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { TipoReporteResumenClinico } from '../../../../shared/enums';
import { distinctUntilChanged, forkJoin, map, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  FileType,
  ModalFileViewerComponent,
  ModalFileViewerData,
} from '../../../../shared/components/modal-file-viewer/modal-file-viewer.component';
import { ModalSpinnerComponent } from '../../../../shared/components/modal-spinner/modal-spinner.component';

@Injectable({
  providedIn: 'root',
})
export class ResumenClinicoService {
  private readonly _reportesService = inject(ReportesService);
  private readonly _fichaClinicaStore = inject(FichaClinicaStore);
  private readonly _dialog = inject(MatDialog);

  public openResumenClinicoModal(): void {
    const _spinner = this._dialog.open(ModalSpinnerComponent, {
      disableClose: true,
    });

    const pdfBlobs = this.obtenerReportesResumenClinico().map((data) =>
      data.blob.pipe(map((blob) => ({ blob, name: data.nombre, order: data.order })))
    );

    forkJoin(pdfBlobs).subscribe({
      next: (pdfBlobs) => {
        _spinner.close();

        const orderedPdfBlobs = pdfBlobs.sort((a, b) => a.order - b.order);

        const modal = this._dialog.open(ModalFileViewerComponent, {
          panelClass: 'file-viewer-dialog',
          disableClose: true,
          autoFocus: false,
          data: {
            files: orderedPdfBlobs.map(({ blob, name }) => ({ blob, name })),
            type: FileType.PDF,
          } as ModalFileViewerData,
        });

        modal.componentInstance.sendAction$.subscribe((value) => {
          if (value) {
            this._reportesService
              .enviarResumenClinicoPorNombre(
                value as TipoReporteResumenClinico,
                this._fichaClinicaStore.values.idAgendamiento!
              )
              .subscribe();
          }
        });
      },
      error: (error) => {
        _spinner.close();
        console.error('Error al obtener los PDFs:', error);
      },
    });
  }

  private obtenerReportesResumenClinico(): Array<{
    nombre: TipoReporteResumenClinico;
    blob: Observable<Blob>;
    order: number;
  }> {
    return [
      {
        nombre: TipoReporteResumenClinico.RecetaMedica,
        blob: this._reportesService.obtenerResumenClinicoPorNombre(
          TipoReporteResumenClinico.RecetaMedica,
          this._fichaClinicaStore.values.idAgendamiento!
        ),
        order: 2
      },
      {
        nombre: TipoReporteResumenClinico.InformeMedico,
        blob: this._reportesService.obtenerResumenClinicoPorNombre(
          TipoReporteResumenClinico.InformeMedico,
          this._fichaClinicaStore.values.idAgendamiento!
        ),
        order: 1
      },
      {
        nombre: TipoReporteResumenClinico.Terapias,
        blob: this._reportesService.obtenerResumenClinicoPorNombre(
          TipoReporteResumenClinico.Terapias,
          this._fichaClinicaStore.values.idAgendamiento!
        ),
        order: 3
      },
      {
        nombre: TipoReporteResumenClinico.Examenes,
        blob: this._reportesService.obtenerResumenClinicoPorNombre(
          TipoReporteResumenClinico.Examenes,
          this._fichaClinicaStore.values.idAgendamiento!
        ),
        order: 4
      },
    ];
  }
}
