import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { MultiSelectSearchFormFieldComponent } from '../../../../../shared/components/multi-select-search-form-field/multi-select-search-form-field.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { TabTratamientoService } from '../../../../../shared/services/tab-tratamiento.service';
import { IDataTerapias } from '../../../../../shared/interfaces/terapias.interface';
import { ControlesTerapia, IDataFrecuencias, IReqTerapiasTratamiento, ITerapiaControl, ITerapiaSelected, TerapGuardada } from '../../../../../shared/interfaces/tratamiento/terapias.interface';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { InputFormFieldComponent } from '../../../../../shared/components/input-form-field/input-form-field.component';
import { SelectFormFieldComponent } from '../../../../../shared/components/select-form-field/select-form-field.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { TextAreaFormFieldComponent } from '../../../../../shared/components/text-area-form-field/text-area-form-field.component';

@Component({
  selector: 'soph-terapias',
  standalone: true,
  imports: [
    CommonModule,
    MultiSelectSearchFormFieldComponent,
    MatExpansionModule,
    InputFormFieldComponent,
    SelectFormFieldComponent,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    TextAreaFormFieldComponent
  ],
  templateUrl: './terapias.component.html',
  styleUrl: './terapias.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerapiasComponent implements OnInit, OnDestroy {
  @Output() isFormValid = new EventEmitter<boolean>;

  formTerapias: FormGroup;
  public terapiasOptions = signal<Array<IDataTerapias>>([]);
  frecuenciasOptions: IDataFrecuencias[] = [];
  idsTerapSelected: string[] = [];
  terapiasSelected: ITerapiaSelected[] = [];
  terapiasGuardadas: TerapGuardada[] = [];

  originalFormValues = signal<{[key: string]: string | string[], terapias: string[]}>({
    terapias: []
  });

  subs: Subscription = new Subscription;


  get terapias() {
    return this.formTerapias.get('terapias')!;
  }

  constructor(
    private fb: FormBuilder,
    private tabTratamientoService: TabTratamientoService
  ) {
    this.formTerapias = this.fb.group({
      terapias: ['']
    })

    this.terapias
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((values) => {

        if( values && !(values === this.idsTerapSelected) ) {

          this.onTerapSelected(values)
          this.idsTerapSelected = values;

        }

      });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  ngOnInit(): void {
    this.llamarServicios();
    this.emitirEstadoForm();
  }

  llamarServicios() {
    this.terapiasOptions.set(this.tabTratamientoService.getTerapias());
    this.frecuenciasOptions = this.tabTratamientoService.getFrecuencias();
  }
  showTerapiasGuardadas(data: IReqTerapiasTratamiento[]) {
    if(data && data.length > 0) {
      data.forEach( item => {
        const terapia = this.terapiasOptions().find(o => o.idTerapia === item.idTerapia)
        if(terapia){
          const terapGuardado: TerapGuardada = {
            idTerapia: item.idTerapia,
            nombreTerapia: terapia.nombre,
            codigo: terapia.codigo,
            frecuencia: item.idFrecuencia,
            indicaciones: item.indicaciones,
            sesiones: item.sesiones
          }
          this.terapiasGuardadas.push(terapGuardado)
        }
      })
    }
    this.resetForm();
  }
  editarTerapias() {
    if(this.terapiasGuardadas.length === 0) return;
    const idsTerapias: string[] = this.terapiasGuardadas.map(ter => ter.idTerapia)
    // Crear controles
    this.onTerapSelected(idsTerapias);
    // Setear Valores originales en inputs
    this.formTerapias.setValue(this.originalFormValues());
    // Vaciar medicamentos guardados
    this.terapiasGuardadas = [];

  }
  setOriginalValues(data: IReqTerapiasTratamiento[]){
    const originalValues = data.reduce((acc, terap) => {
      acc['frecuencia'+terap.idTerapia] = terap.idFrecuencia
      acc['sesiones'+terap.idTerapia] = terap.sesiones
      acc['indicaciones'+terap.idTerapia] = terap.indicaciones
      if(acc['terapias']) {
        acc['terapias'].push(terap.idTerapia)
      } else {
        acc['terapias'] = [terap.idTerapia]
      }
      return acc;
    }, {} as {[key: string]: string | string[], terapias: string[]})

    this.originalFormValues.set(originalValues)

  }
  resetForm() {
    this.terapiasSelected = [];
    this.idsTerapSelected = [];
    const nombresControles = Object.keys(this.formTerapias.controls);
    nombresControles.forEach( nombreControl =>{
      if( nombreControl != 'terapias') {
        this.formTerapias.removeControl(nombreControl);
      }
    })
    this.formTerapias.reset();
  }

  disableSelectorTerapias () {
    this.terapias.disable();

  }
  enableSelectorTerapias () {
    this.terapias.enable();

  }

  emitirEstadoForm() {
    const formSub = this.formTerapias.statusChanges.subscribe( status => {
      const formState = (status === 'VALID');
      this.isFormValid.emit(formState);
    })

    this.subs.add(formSub);
  }

  armarRequestTerapias() : IReqTerapiasTratamiento[] {
    // Valores originales
    this.originalFormValues.set(this.formTerapias.value)

    const terapias: IReqTerapiasTratamiento[] = [];
    if(this.terapias.value && this.terapias.value.length > 0) {
      this.terapias.value.forEach( (idTerap: string) => {
        const terap:  IReqTerapiasTratamiento = {
          idTerapia: idTerap,
          sesiones: this.formTerapias.get(`sesiones${idTerap}`)?.value,
          idFrecuencia: this.formTerapias.get(`frecuencia${idTerap}`)?.value,
          indicaciones: this.formTerapias.get(`indicaciones${idTerap}`)?.value,
        }
        terapias.push(terap)
      })
      return terapias;
    }
    return []
  }

  onTerapSelected(idsTerapsEmitidos: string[]) {
    const terapsEliminadas = this.idsTerapSelected.filter(id => !idsTerapsEmitidos.includes(id))
    const terapsAgregadas = idsTerapsEmitidos.filter(id => !this.idsTerapSelected.includes(id))
    this.idsTerapSelected = idsTerapsEmitidos
    terapsEliminadas.forEach(idTerap => this.removeTerapia(idTerap))
    terapsAgregadas.forEach(idTerap => this.addTerapia(idTerap))
  }

  unselectTerap(idTerapia: string) {
    const terapias = this.terapias.value;
    const newTerapias: string[] = [];
    terapias.forEach( (id: string) => {
      if(idTerapia != id) {
        newTerapias.push(id);
      }
    })
    this.terapias.setValue(newTerapias)
  }

  addTerapia(idTerapia: string) {
    // Preparar data
    const terapOption = this.terapiasOptions().find((terap) => terap.idTerapia === idTerapia)
    const nombreTerapia = terapOption ? terapOption.nombre : '';
    const controles = this.createControlsTeraps(idTerapia);

    // añadir controles al formulario
    this.addTerapControls(controles);

    // Agregar medicamento a array de medicamentos seleccionados
    const terapia: ITerapiaSelected = {
      nombreTerapia,
      idTerapia,
      controles,
    };
    this.terapiasSelected.push(terapia);
  }

  removeTerapia(idTerapia: string) {
    // Al deseleccionar un medicamento
    const terapToDelet = this.terapiasSelected.find(
      (ter) => ter.idTerapia === idTerapia
    );
    if (terapToDelet) {
      // eliminar controles
      this.removeTerapControls(terapToDelet.controles);

      // eliminar medicamento de la seleccion
      const terapsFilter = this.terapiasSelected.filter(
        (ter) => ter.idTerapia !== idTerapia
      );
      this.terapiasSelected = terapsFilter;
    }
  }

  // Crear controles para dosis, duracion y horario por cada medicamento seleccionado
  createControlsTeraps(idTerapia: string): ControlesTerapia {
    const terapControls: ControlesTerapia = {
      sesiones: {} as ITerapiaControl,
      frecuencia: {} as ITerapiaControl,
      indicaciones: {} as ITerapiaControl
    };
    //Por cada medicamento, crear un 3 controles
    const sesionesName = `sesiones${idTerapia}`;
    const frecuenciaName = `frecuencia${idTerapia}`;
    const indicacionesName = `indicaciones${idTerapia}`;
    // añadir a array
    terapControls.sesiones = {
      nombreControl: sesionesName,
      terapControl: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      labelControl: 'Dosis',
    };
    terapControls.frecuencia = {
      nombreControl: frecuenciaName,
      terapControl: new FormControl('', [Validators.required]),
      labelControl: 'Duracion',
    };
    terapControls.indicaciones = {
      nombreControl: indicacionesName,
      terapControl: new FormControl('', [Validators.required, Validators.maxLength(140)]),
      labelControl: 'Horario',
    };

    return terapControls;
  }

  addTerapControls(controles: ControlesTerapia) {
    this.addNewControls(controles.frecuencia.nombreControl, this.formTerapias, controles.frecuencia.terapControl);
    this.addNewControls(controles.sesiones.nombreControl, this.formTerapias, controles.sesiones.terapControl);
    this.addNewControls(controles.indicaciones.nombreControl, this.formTerapias, controles.indicaciones.terapControl);
  }

  removeTerapControls(controles: ControlesTerapia) {
    this.removeControls(controles.frecuencia.nombreControl, this.formTerapias);
    this.removeControls(controles.sesiones.nombreControl, this.formTerapias);
    this.removeControls(controles.indicaciones.nombreControl, this.formTerapias);

  }

  addNewControls(nombre: string, form: FormGroup, control: FormControl) {
    form.addControl(nombre, control);
  }
  removeControls(nombre: string, form: FormGroup) {
    form.removeControl(nombre);
  }

  onlyNumbers(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
  }
}
