import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {IRespTipoDocumento} from "../interfaces/tipo-documento.interface";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {AuthenticationService} from "../../pages/services/authentication.service";
import {IRespEnlace} from "../interfaces/reenvio-enlace.interface";

@Injectable({
  providedIn: 'root'
})
export class ReenvioEnlacePasswordService {

  private apiUrl = 'https://dev.api.sophia.seiza-ti.cl/api/v1/auth/validar-restablecer-pass';
  constructor(private httpClient: HttpClient) { }

  getEnlacePass(email: string): Observable<IRespEnlace> {
    const params =new
    HttpParams()
      .set('client-id', '4b9b9ab5b734408d915ec31751bbf114')
      .set('email', email)
    return this.httpClient.get<IRespEnlace>(this.apiUrl, {params});
  }
}
