<div class="section-inputs">
  <mat-form-field class="full-width" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input 
      type="text"
      matInput
      [formControl]="control"
      [matAutocomplete]="auto"
      >
    
    <mat-autocomplete 
      autoActiveFirstOption 
      #auto="matAutocomplete" 
      (optionSelected)="onOptionSelected($event.option.value)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.address">
        {{ displayWith(option) }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="control.hasError('required') && control.touched">
      {{  errorMessages.required }}
    </mat-error>
    <mat-error *ngIf="control.hasError('pattern') && control.touched">
      {{ errorMessages.pattern }}
    </mat-error>
  </mat-form-field>
</div>
