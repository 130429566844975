<div class="layaout-login col-md-12">
  <div class="col-md-5 contenedor-login">
    <div class="titulo-login">
      <h1 class="titulo-modal">Recupera tu contraseña</h1>
    </div>
    <div class="row contenedor-formulario-login">
      <p class="texto-ayuda">Ingresa tu correo electrónico. <br>
        Te enviaremos las instrucciones para que puedas crear una nueva contraseña</p>
     <form [formGroup]="recuperarPass" (ngSubmit)="onSumbit()">
       <div class="section-inputs">
         <mat-form-field  class="estilo-input" appearance="outline">
           <mat-label>Correo electrónico</mat-label>
           <input matInput formControlName="email"
                  type="email"
                  (input)="restringirCaracteresEspeciales($event)"
                  (keydown.space)="$event.preventDefault()"
           required>
           <mat-error *ngIf="recuperarPass.get('email')?.touched
          && recuperarPass.get('email')?.invalid">
             {{getEmaildErrorMessage()}}
           </mat-error>
         </mat-form-field>

       </div>
       <div class="section-footer">
         <div class="row section-button">
           <button mat-flat-button
                   color="primary"
                   type="submit"
                   class="btn-enviar-enlace"
           [disabled]="!disabled()"
           (click)="recuperarPassword()">
             <mat-spinner
               class="custom-spinner"
               *ngIf="isLoading" diameter="24"></mat-spinner>
             <span *ngIf="!isLoading">Enviar enlace</span>
           </button>
         </div>
         <div class="row section-button">
           <button mat-stroked-button
                   color="primary"
                   class="btn-cancelar"
           (click)="volverAlogin()">
             Cancelar
           </button>
         </div>
       </div>
     </form>
    </div>
  </div>
</div>
