//Angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  model,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Auth } from '@angular/fire/auth';
import { CommonModule, NgIf } from '@angular/common';
import { provideNativeDateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';

//Angular Material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

//Librerias externas
import { Observable, of, zip, Subscription } from 'rxjs';

//Componentes
import { BuscardorDireccionService } from '../../../../shared/services/buscardor-direccion.service';
import { ConsultaPacienteRegistradoService } from '../../../../shared/services/consulta-paciente-registrado.service';
import { HeaderSophiaComponent } from '../header-sophia/header-sophia.component';
import { InputTelephoneComponent } from '../../../../shared/components/input-telephone/input-telephone.component';
import { ModalConfirmCambioDatosComponent } from '../../../../shared/components/modal-confirm-cambio-datos/modal-confirm-cambio-datos.component';
import { ModalErrorGeneralComponent } from '../../modal-error-general/modal-error-general.component';

//Interfaces
import { IDataCentrosMedico } from '../../../../shared/interfaces/centros-medicos.interface';
import { IDataDireccion } from '../../../../shared/interfaces/buscar-direccion.interface';
import { IDataDocumento } from '../../../../shared/interfaces/tipo-documento.interface';
import { IDataPacienteForm } from '../../../../shared/interfaces/paciente-form.interface';
import { IDataSeguro } from '../../../../shared/interfaces/seguros.interface';
import { TConsulta } from '../../../../shared/enums';

//Services
import { IdDireccionSeleccionadaSignalService } from '../../../../shared/services/id-direccion-seleccionada-signal.service';
import { ObtenerInstitucionesService } from '../../../../shared/services/obtener-instituciones.service';
import { ObtenerPrevisionService } from '../../../../shared/services/obtener-prevision.service';
import { ObtenerTipoDocumentoService } from '../../../../shared/services/obtener-tipo-documento.service';
import { ShareDataService } from '../../../../shared/services/share-data.service';
import { UserEdirUseCaseService } from '../../../../shared/services/user-edir-use-case.service';
import { UtilServiceService } from '../../../../shared/services/util-service.service';


interface DireccionResponse {
  success: boolean;
  data: any; // Cambia 'any' al tipo adecuado si lo conoces
  error?: {
    statusCode: number;
    code: string;
    message: string;
  };
}
@Component({
  selector: 'soph-ingreso-nueva-consulta',
  standalone: true,
  imports: [
    CommonModule,
    HeaderSophiaComponent,
    InputTelephoneComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    NgIf,
    ReactiveFormsModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './ingreso-nueva-consulta.component.html',
  styleUrl: './ingreso-nueva-consulta.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IngresoNuevaConsultaComponent implements OnInit, OnDestroy {
  consultaForm: FormGroup;
  formularioHora = false;
  selected = model<Date | null>(null);
  times: string[] = [];
  horaInicio: string | null = null;

  previsiones: any;
  tipoConsultaOptions: { key: string; value: string }[] = [];
  tipoPrevisionOptions: IDataSeguro[] = [];
  tipoDocumentoOptions: IDataDocumento[] = [];
  institucionesOptions: IDataCentrosMedico[] = [];
  dataExistePaciente: any;
  filterDireccion!: Observable<string[]>;
  filterers!: string[];
  listDirecciones: IDataDireccion[] = [];
  filteredOptionsEmptyDireccion: boolean = false;
  telephonePrefix: string = '';
  idDireccionSeleccionada: any;
  addresDireccionSeleccionada: any;

    // Definir la señal
    idDirecSeleccionada = signal<string | null>(null);

    // Método para actualizar la señal
    actualizarDireccionSeleccionada(id: string | null) {
      this.idDireccionSeleccionada.set(id);
    }

  private auth = inject(Auth);

  /*FLAGS VISUALIZACION DE CAMPOS*/
  public showCamposDni = false;
  public showCamposPassport = false;
  public showCamposOtros = false;
  public showCamposDomicilio = false;
  public showCampoTelMed = false;
  public showCampoPresencial = false;
  public showDireccionManual = false;

  idNumeroDocumento :any;
  /*GETTERS CAMPOS FORM*/
  get tipoDocumento() : FormControl {
    return this.consultaForm.get('tipoDocumento') as FormControl;
  }
  get numeroDocumento() : FormControl {
    return this.consultaForm.get('numeroDocumento') as FormControl;
  }
  get numeroPasaporte() : FormControl {
    return this.consultaForm.get('numeroPasaporte') as FormControl;
  }
  get numeroDocumentoOtros() : FormControl {
    return this.consultaForm.get('numeroDocumentoOtros') as FormControl;
  }
  get nombre() : FormControl {
    return this.consultaForm.get('nombre') as FormControl;
  }
  get apellidos() : FormControl {
    return this.consultaForm.get('apellidos') as FormControl;
  }
  get email() : FormControl {
    return this.consultaForm.get('email') as FormControl;
  }
  get numero() : FormControl {
    return this.consultaForm.get('numero') as FormControl;
  }
  get prevision() : FormControl {
    return this.consultaForm.get('prevision') as FormControl;
  }
  get tipoAtencion() : FormControl {
    return this.consultaForm.get('tipoAtencion') as FormControl;
  }
  get institucion() : FormControl {
    return this.consultaForm.get('institucion') as FormControl;
  }
  get link() : FormControl {
    return this.consultaForm.get('link') as FormControl;
  }
  get direccion() : FormControl {
    return this.consultaForm.get('direccion') as FormControl;
  }
  get casaDepto() : FormControl {
    return this.consultaForm.get('casaDepto') as FormControl;
  }


  pasaporteId: string = '';
  dniId: string = '';
  otrosId: string = '';

  formDataRegistroPaciente: IDataPacienteForm = {} as IDataPacienteForm;

  subs: Subscription = new Subscription();

  signalDireccion: WritableSignal<string | null> = signal<string | null>(null);
  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dialog: MatDialog,
    private ExistePacienteService: ConsultaPacienteRegistradoService,
    private obtenerPrevisionService: ObtenerPrevisionService,
    private obtenerTipoDocumentoService: ObtenerTipoDocumentoService,
    private obtenerInstitucionesService: ObtenerInstitucionesService,
    private buscarDireccionService: BuscardorDireccionService,
    private UserEdirUseCaseService: UserEdirUseCaseService,
    private IdDireccionSignal: IdDireccionSeleccionadaSignalService,
    private shareDataService: ShareDataService,
    private utilServiceService: UtilServiceService
  ) {
    const formatoEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.consultaForm = this.fb.group({
      tipoDocumento: ['', Validators.required],
      numeroDocumento: [''],
      numeroPasaporte: [''],
      numeroDocumentoOtros: [''],
      nombre: ['', Validators.required],
      apellidos: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(formatoEmail)]],
      numero: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
      ]),
      prevision: ['', Validators.required],
      tipoAtencion: ['', Validators.required],
      institucion: [''],
      link: [''],
      direccion: [''],
      casaDepto: [''],
    });
    this.tipoConsultaOptions = Object.keys(TConsulta).map((key) => ({
      key,
      value: TConsulta[key as keyof typeof TConsulta],
    }));
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.cargaDeServicios();
    this.consultaForm.get('direccion')?.valueChanges.subscribe((valor) => {
      this.filterDirecciones(valor);
    });
    this.cdr.detectChanges();
  }

  /*SERVICES*/
  private cargaDeServicios(): void {
    const sub = zip(
      this.obtenerInstitucionesService.getInstituciones(),
      this.obtenerPrevisionService.getPrevision(),
      this.obtenerTipoDocumentoService.getTipoDocumento()
    ).subscribe({
      next: ([instituciones, previsiones, tiposDocumento]) => {
        this.institucionesOptions = instituciones.data;
        this.tipoPrevisionOptions = previsiones.data;
        this.tipoDocumentoOptions = tiposDocumento.data;
        this.pasaporteId = this.tipoDocumentoOptions.find(doc => doc.nombre === 'Pasaporte')?.idTipoDocumento || '';
        this.dniId = this.tipoDocumentoOptions.find(doc => doc.nombre === 'DNI')?.idTipoDocumento || '';
        this.otrosId = this.tipoDocumentoOptions.find(doc => doc.nombre === 'Otro')?.idTipoDocumento || '';
        this.getFormDataExistente();
      }
    })

    this.subs.add(sub);

  }
  getFormDataExistente() {
    const sub = this.shareDataService.getFormData().subscribe((formData) => {
      console.log('formData => ', formData)
      if (formData) {
        this.formDataRegistroPaciente = formData;
        this.consultaForm.get('prevision')?.setValue(formData.prevision);
        this.consultaForm.get('tipoDocumento')?.setValue(formData.tipoDocumento);
        this.consultaForm.get('prevision')?.setValue(formData.prevision);
        this.showCampoNumeroDocumento(formData.tipoDocumento)

        this.consultaForm.patchValue(formData); // Precargar los datos en el formulario
      }
    });
    this.subs.add(sub);
  }
  public registroPacienteDni(numDoc: any, idTipoDocumento: any) {
    if (numDoc && idTipoDocumento === null) {
      return;
    }

    const sub = this.ExistePacienteService.getExistePaciente(numDoc).subscribe({
      next: (resp) => {
        this.resetCamposNombreApellidoEmail();
        const existePaciente = resp.data;

        if (!resp || !resp.data) {
          this.dataExistePaciente = null;
          this.setCamposRequeridos();
          return;
        }
        const numeroDocumentoService =
          existePaciente.numeroDocumento;
        const idTipoDocumentoService =
          existePaciente.tipoDocumento.idTipoDocumento;

        const data = {
          numeroDocumento: existePaciente.numeroDocumento || numDoc,
          nombre: existePaciente.nombres || '',
          apellidos: existePaciente.apellidos || '',
          email: existePaciente.correoElectronico || '',
          numero: existePaciente.telefono || '',
          prevision: existePaciente.prevision.idPrevision || '',
        };

        if (
          numDoc === numeroDocumentoService &&
          idTipoDocumento === idTipoDocumentoService
        ) {
          this.dataExistePaciente = { ...data };
          this.consultaForm.patchValue(data);
          this.validarCamposDelServicio();
        } else {
          return
        }
        this.consultaForm.updateValueAndValidity();
      },
    });
    this.subs.add(sub);
  }
  /* RESET CAMPOS FORMULARIO*/
  private resetCamposNombreApellidoEmail() {
    this.consultaForm.get('nombre')?.setValue(null);
    this.consultaForm.get('nombre')?.setErrors(null);
    this.consultaForm.get('nombre')?.markAsPristine();
    this.consultaForm.get('nombre')?.markAsUntouched();

    this.consultaForm.get('apellidos')?.setErrors(null);
    this.consultaForm.get('apellidos')?.setValue(null);
    this.consultaForm.get('apellidos')?.markAsPristine();
    this.consultaForm.get('apellidos')?.markAsUntouched();

    this.consultaForm.get('email')?.setValue(null);
    this.consultaForm.get('email')?.setErrors(null);
    this.consultaForm.get('email')?.markAsPristine();
    this.consultaForm.get('email')?.markAsUntouched();

    this.consultaForm.get('numero')?.setValue(null);
    this.consultaForm.get('numero')?.setErrors(null);
    this.consultaForm.get('numero')?.markAsPristine();
    this.consultaForm.get('numero')?.markAsUntouched();
  }
  private setCamposRequeridos() {
    this.consultaForm.get('nombre')?.setValidators([Validators.required]);
    this.consultaForm.get('apellidos')?.setValidators([Validators.required]);
    this.consultaForm
      .get('email')
      ?.setValidators([Validators.required, Validators.email]);
    this.consultaForm.get('numero')?.setValidators([Validators.required]);

    this.consultaForm.get('nombre')?.updateValueAndValidity();
    this.consultaForm.get('apellidos')?.updateValueAndValidity();
    this.consultaForm.get('email')?.updateValueAndValidity();
    this.consultaForm.get('numero')?.updateValueAndValidity();
  }
  private validarCamposDelServicio() {
    const camposAValidar = [
      'numeroDocumento',
      'nombre',
      'apellidos',
      'email',
      'numero',
    ];

    camposAValidar.forEach((campo) => {
      const control = this.consultaForm.get(campo);
      if (control) {
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    });
    this.consultaForm.updateValueAndValidity();
  }

  public displayAddress(direccion: any): string {
    return direccion ? direccion : ''; // Mostramos el atributo address
  }


  public onSelectDireccion(selectedAddress: string): void {
    //se busca la direccion seleccionada en el la lista de direcciones
    const direccionSeleccionada = this.listDirecciones.find(
      (direccion) => direccion.address === selectedAddress
    );
      this.addresDireccionSeleccionada = direccionSeleccionada?.id
      const nombreDireccion = direccionSeleccionada?.address
      //id de la seleccion de direccion

      if(nombreDireccion){
        this.IdDireccionSignal.setDireccionSeleccionada(nombreDireccion);
      }
      this.idDireccionSeleccionada = direccionSeleccionada?.id;
      console.log(this.idDireccionSeleccionada);

    if (this.addresDireccionSeleccionada) {
      this.consultaForm.patchValue({
        direccion: direccionSeleccionada?.address,
      });
    }
  }

  public filterDirecciones(direc: any) {
    const filterValue =
      direc !== null && (typeof direc === 'string' || direc instanceof String)
        ? direc.toLocaleLowerCase()
        : '';

    if (filterValue) {
      const sub = this.buscarDireccionService.getDirecciones(filterValue).subscribe({
        next: (resp) => {
          this.listDirecciones = resp.data;
          let filter = this.listDirecciones.filter((direc) =>
            direc.address.toLocaleLowerCase().includes(filterValue)
          );
          this.filterDireccion = of(
            filter.map((direccion) => direccion.address)
          );

          this.filteredOptionsEmptyDireccion = !Boolean(filter.length);
          this.showDireccionManual = false;
        },
        error: (error) => {
          if (error.error?.error.statusCode === 500
            && error.error.error.code === "error/unknown") {
            this.consultaForm.get('direccion')?.setErrors({ notFound: true });
          } else {
          }
        },
      });
      this.subs.add(sub);
      this.showDireccionManual = false;
    }
  }

  /*MÉTODOS QUE MUESTRAN CAMPOS DEPENDIENTES SEGUN OPCION SELECCIONADA*/
  public showCampoNumeroDocumento(valor: any): void {

    const numeroDocumento = this.consultaForm.get('numeroDocumento');
    const numeroPasaporte = this.consultaForm.get('numeroPasaporte');
    const numeroDocOtros = this.consultaForm.get('numeroDocumentoOtros');


    const idDocOtros = this.otrosId;
    const idDocDni = this.dniId;
    const idDocPasaporte = this.pasaporteId;

    numeroDocumento?.clearValidators();
    numeroPasaporte?.clearValidators();
    numeroDocOtros?.clearValidators();

    this.showCamposDni = false;
    this.showCamposPassport = false;
    this.showCamposOtros = false;

    if (valor) {
      switch (valor) {
        case idDocDni:
          this.showCamposDni = true;
          numeroDocumento?.setValidators([Validators.required]);
          break;

        case idDocPasaporte:
          this.showCamposPassport = true;
          numeroPasaporte?.setValidators([Validators.required]);
          break;

        case idDocOtros:
          this.showCamposOtros = true;
          numeroDocOtros?.setValidators([Validators.required]);
          break;

        default:
          break;
      }
      numeroDocumento?.updateValueAndValidity();
      numeroPasaporte?.updateValueAndValidity();
      numeroDocOtros?.updateValueAndValidity();
    }
    this.consultaForm.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  public actualizarCamposTipoConsulta(tipoAtencion: string): void {
    this.showCampoPresencial = false;
    this.showCamposDomicilio = false;
    this.showCampoTelMed = false;

    this.consultaForm.get('institucion')?.clearValidators();
    this.consultaForm.get('direccion')?.clearValidators();
    this.consultaForm.get('casaDepto')?.clearValidators();
    this.consultaForm.get('link')?.clearValidators();
    switch (tipoAtencion) {
      case 'Presencial (Centro de salud)':
        this.showCampoPresencial = true;
        this.consultaForm
          .get('institucion')
          ?.setValidators([Validators.required]);
        break;
      case 'Visita Domiciliaria':
        this.showCamposDomicilio = true;
        this.consultaForm
          .get('direccion')
          ?.setValidators([Validators.required]);
        break;
      case 'Telemedicina':
        const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\/?].*)?$/i;
        this.showCampoTelMed = true;
        this.consultaForm
          .get('link')
          ?.setValidators([
            Validators.required,
            Validators.pattern(urlPattern),
          ]);
        break;
    }
    this.consultaForm.get('institucion')?.updateValueAndValidity();
    this.consultaForm.get('direccion')?.updateValueAndValidity();
    this.consultaForm.get('casaDepto')?.updateValueAndValidity();
    this.consultaForm.get('link')?.updateValueAndValidity();
    this.consultaForm.updateValueAndValidity();
  }

  /*VALIDACIONES INPUTS*/
  public soloNumeros(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    value = value.replace(/[^0-9]/g, '');
    this.consultaForm.controls['numeroDocumento'].setValue(value);
  }

  public soloLetras(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    if (!/^[a-zA-ZÑñ0-9\sáéíóúÁÉÍÓÚ]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }
  public restringirCaracteresEspeciales(event: KeyboardEvent) {
    const inputChar = event.key;
    if (!/^[a-zA-Z0-9._@-]*$/.test(inputChar)) {
      event.preventDefault();
    }
  }

  public validateUrl(value: string): boolean {
    const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\/?].*)?$/i;
    return urlPattern.test(value);
  }

  manejoDataDni(event: Event) {
    this.utilServiceService.formatDni(event);
    this.manejarInputValue(event);
  }
  manejoDataPasaporte(event: Event) {
    this.utilServiceService.formatPasaporte(event);
    this.manejarInputValue(event);
  }

  /*Manejo de eventos campos seleccionables*/
  public manejarInputValue(event: Event): void {
    const input = event.target as HTMLInputElement;
    const idTipoDocumento = this.consultaForm.get('tipoDocumento')?.value;
    this.cdr.detectChanges();
    this.registroPacienteDni(input.value, idTipoDocumento);
  }
  public campoSeleccionado(event: MatSelectChange) {
    const valorSeleccionado = event.value;
    this.consultaForm.reset();
    this.consultaForm.get('tipoDocumento')?.setValue(valorSeleccionado);
    this.consultaForm.updateValueAndValidity();
    this.showCampoNumeroDocumento(valorSeleccionado);
  }
  public campoSeleccionadoPrevision(event: MatSelectChange) {
    const previsionSeleccionada = event.value;
    if (previsionSeleccionada) {
      this.consultaForm.get('prevision')?.updateValueAndValidity();
    }
  }
  public campoSeleccionadoTipoAtencion(event: MatSelectChange) {
    const atencionSeleccionada = event.value;
    this.resetCamposTipoAtencion();
    this.actualizarCamposTipoConsulta(atencionSeleccionada);
  }
  private resetCamposTipoAtencion() {
    const tipoAtencion = this.consultaForm.get('tipoAtencion')?.value;
    this.consultaForm.patchValue({
      tipoAtencion: tipoAtencion,
      institucion: '',
      link: '',
      direccion: '',
      casaDepto: '',
    });
    const fieldsToUntouch = [
      'tipoAtencion',
      'institucion',
      'link',
      'direccion',
      'casaDepto',
    ];
    fieldsToUntouch.forEach((field) => {
      this.consultaForm.get(field)?.markAsUntouched();
    });
    this.consultaForm.updateValueAndValidity();
  }

  /*LOGICA PARA MOSTRAR MODAL DE DATOS MODIFICADOS*/
  public formularioModificado(): boolean {
    const valoresActuales = this.consultaForm.value;
    if (!this.dataExistePaciente) {
      return false;
    }
    return (
      valoresActuales.nombre !== this.dataExistePaciente?.nombre ||
      valoresActuales.apellidos !== this.dataExistePaciente?.apellidos ||
      valoresActuales.email !== this.dataExistePaciente?.email
    );
  }

  /*REDIRECT Y SUBMIT FORM*/
  public ingresarHoraConsulta() {
    this.formularioHora = true;
  }
  async onSubmit() {

    this.consultaForm.get('direccion')?.setValue(this.idDireccionSeleccionada);
    const formDataRegistroPaciente: IDataPacienteForm = this.consultaForm.value;
    this.shareDataService.setFormData(formDataRegistroPaciente);
    if (this.formularioModificado()) {
      const dialogRef =this.dialog.open(ModalConfirmCambioDatosComponent, {
        data: { formDataRegistroPaciente } // Aquí pasas los datos al modal
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result && result.update) {
          this.router.navigate(['/hora-fecha']);
        } else {
           console.log('click en cancelar modal');
        }
      });
    } else {
      /*case cuando no se modifica el formulario*/
      console.log('formulario no modificado'),
      this.router.navigate(['/hora-fecha'], {
        state: { formDataRegistroPaciente: formDataRegistroPaciente },
      });
    }

  }

}
