import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { catchError, distinctUntilChanged, Observable, tap } from 'rxjs';
import { TabPosition } from '../ficha-clinica.component';
import { TextareaWithSpeechToTextFormFieldComponent } from '../../../../shared/components/textarea-with-speech-to-text-form-field/textarea-with-speech-to-text-form-field.component';
import { InputWithChipFormFieldV2Component } from '../../../../shared/components/input-with-chip-form-field-v2/input-with-chip-form-field-v2.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  ModalConfirmComponent,
  ModalConfirmProps,
  ModalConfirmType,
} from '../../../../shared/components/modal-confirm/modal-confirm.component';
import { FichaClinicaService } from '../../../services/ficha-clinica.service';
import { EstadoFichaClinica } from '../../../../shared/enums';
import { ModalErrorGeneralComponent } from '../../../common/modal-error-general/modal-error-general.component';
import { fichaClinicaFinalizada } from '../../../../shared/helpers/ficha-clinica.helper';
import { ResumenClinicoService } from '../../../services/reportes/resument-clinico/resumen-clinico.service';
import { TabExamenesService } from '../../../../shared/services/tab-examenes.service';
import {
  IDataExamn,
  IDataTypeExamn,
} from '../../../../shared/interfaces/examenes.interface';
import {
  SnackInfoComponent,
  SnackInfoProps,
  SnackInfoType,
} from '../../../../shared/components/snack-info/snack-info.component';
import { UploadArchivosComponent } from '../../../../shared/components/upload-archivos/upload-archivos.component';
import { UploadArchivos } from '../../../../shared/interfaces/cargaArchivo.interface';

interface ExamenesForm {
  examenLaboratorio: FormControl<Array<any> | null>;
  examenImagen: FormControl<Array<any> | null>;
  fileExam: FormControl<Array<any> | null>;
  diagnosticoExamen: FormControl<string | null>;
}

@Component({
  selector: 'soph-examenes',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    UploadArchivosComponent,
    TextareaWithSpeechToTextFormFieldComponent,
    InputWithChipFormFieldV2Component,
  ],
  templateUrl: './examenes.component.html',
  styleUrl: './examenes.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExamenesComponent {
  readonly path = 'examenes';
  readonly dialog = inject(MatDialog);
  readonly snackBar = inject(MatSnackBar);
  readonly resumenClinico = inject(ResumenClinicoService);

  public typeExamn = signal<Array<IDataTypeExamn>>([]);

  public laboratorioTypeExam: string | null = null;
  public imagenesTypeExam: string | null = null;

  public examsLaboratorio = signal<Array<IDataExamn>>([]);
  public examsImagen = signal<Array<IDataExamn>>([]);

  readonly isAllTabsCompleted = signal(false);

  public form: FormGroup<ExamenesForm>;
  readonly errorMessage: WritableSignal<{ [key: string]: string }> = signal({});

  private readonly examenes = signal<any | null>(null);
  public readonly hasChanges = signal<boolean>(false);

  public fileName: string = '';
  public file: UploadArchivos[] = [];
  public hasDocumentUrl: boolean = false;
  public documentUrl: string = '';
  isUploaderDisabled: boolean = false;
  isBtnEditarDisabled: boolean = false;
  constructor(
    private readonly _fb: FormBuilder,
    private readonly _fichaClinicaStore: FichaClinicaStore,
    private readonly _fichaClinicaService: FichaClinicaService,
    private readonly _tabExamenesService: TabExamenesService
  ) {
    this.form = this._fb.group({
      examenImagen: new FormControl<Array<any> | null>(
        null,
        Validators.compose([])
      ),
      examenLaboratorio: new FormControl<Array<any> | null>(
        null,
        Validators.compose([])
      ),
      fileExam: new FormControl<Array<any> | null>(
        null,
        Validators.compose([])
      ),
      diagnosticoExamen: new FormControl<string | null>(
        null,
        Validators.compose([Validators.maxLength(1000)])
      ),
    });

    this._fichaClinicaStore.state$
      .pipe(distinctUntilChanged())
      .subscribe((values) => {
        if (values.tabActualSeleccionado === TabPosition.Examenes) {
          this.isAllTabsCompleted.set(
            Object.values(values.historial).every((value) => value === true) &&
              !fichaClinicaFinalizada(values.estado || '')
          );

          this.hasChanges.set(this.isAllTabsCompleted());
        }

        if(values.estado === 'REALIZADA') {
          this.isUploaderDisabled = true;
          this.form.disable();
          this.isBtnEditarDisabled = true;
        }
      });

    this._fichaClinicaStore.updateMedicalHistoryBySection('examenes', true);
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((newValues) => {
      this.hasChanges.set(this.checkForChanges(newValues));
    });
    this.setTypesExams();
  }

  onExamenSelected(file: any): void {
    this.file = file;
    console.log(this.file);
  }


  public updateErrorMessage(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    const currentErrorMessages = this.errorMessage();

    if (control?.hasError('required')) {
      currentErrorMessages[fieldName] = 'Este campo es obligatorio';
    } else {
      currentErrorMessages[fieldName] = '';
    }

    this.errorMessage.set({ ...currentErrorMessages });
  }

  public isRequired(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    return control?.hasError('required') ?? false;
  }

  public isInvalid(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    return control?.invalid ?? false;
  }

  public revertChanges() {
    if (this.examenes()) {
      this.form.patchValue({
        ...this.examenes(),
      });
    } else {
      this.form.reset();
    }
  }

  private checkForChanges(newValues: any): boolean {
    if (this.examenes()) {
      return JSON.stringify(newValues) !== JSON.stringify(this.examenes());
    } else {
      const defaultValue = {
        tipoExamenLaboratorio: null,
        examenes: null,
        archivos: null,
        diagnosticoExamen: null,
      };

      return JSON.stringify(defaultValue) !== JSON.stringify(newValues);
    }
  }


  uploadFiles(): Observable<void> {
    this.isUploaderDisabled = false;
    const files = this.file.map((file) => file.file);
    console.log('archivos cargados en componente padre', files);

    return this._tabExamenesService
      .uploadFile(
        files,
        this._fichaClinicaStore.values.idPaciente!,
        this._fichaClinicaStore.values.idAgendamiento!
      )
      .pipe(
        tap(() => {
          this.snackBar.openFromComponent(SnackInfoComponent, {
            data: {
              message: 'Archivo cargado con exito.',
              type: SnackInfoType.SUCCESS,
            },
            duration: 2000,
          });
        }),
        catchError((err) => {
          this.snackBar.openFromComponent(SnackInfoComponent, {
            data: {
              message: 'Error al cargar los archivos, intente nuevamente',
              type: SnackInfoType.ERROR,
            },
          });
          throw err;
        })
      );
  }

  public onSave(form: FormGroup) {
    if (form.valid) {
      const { value } = form;

      this.uploadFiles().subscribe({
        next: () => {
          if (this.isAllTabsCompleted()) {
            this.onFinishAppointmentConfirmation();
          }
        },
        error: () => {},
      });
    }
  }

  private onFinishAppointmentConfirmation() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      autoFocus: false,
      panelClass: 'custom-confirmation-dialog',
      width: '550px',
      disableClose: true,
      data: {
        type: ModalConfirmType.WARNING,
        description: '¿Confirmas que deseas finalizar la consulta?',
        message:
          'Los datos ingresados se guardarán y solo algunos datos específicos podrán ser editados posteriormente.',
        config: {
          buttons: [
            { title: 'Volver', action: false },
            { title: 'Finalizar consulta', action: true },
          ],
        },
      } as ModalConfirmProps,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result === 'boolean' && result) {
        this.onFinishAppointment();
      }
    });
  }

  private onFinishAppointment() {
    this._fichaClinicaService
      .finish(this._fichaClinicaStore.values.idAgendamiento!)
      .subscribe({
        next: () => {
          this._fichaClinicaStore.updateStore({
            ...this._fichaClinicaStore.values,
            estado: EstadoFichaClinica.REALIZADA,
          });

          this.onFinishAppointmentFinished();
          this.isUploaderDisabled = true;
          this.form.disable();
          this.isBtnEditarDisabled = true;
        },
        error: () => {
          /*this.dialog.open(ModalErrorGeneralComponent);*/
          this.snackBar.openFromComponent(SnackInfoComponent, {
            data: {
              message:
                'No pudimos guardar los cambios, por favor intenta nuevamente.',
              type: SnackInfoType.ERROR,
            } as SnackInfoProps,
          });
        },
      });
  }

  private onFinishAppointmentFinished() {
    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      autoFocus: false,
      panelClass: 'custom-confirmation-dialog',
      width: '452px',
      disableClose: true,
      data: {
        type: ModalConfirmType.SUCCESS,
        description: 'La consulta ha sido finalizada y guardada con éxito',
        message:
          'La ficha clínica de esta consulta está lista para ser revisada cuando lo necesites',
        config: {
          buttons: [{ title: 'Aceptar', action: true }],
        },
      } as ModalConfirmProps,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (typeof result === 'boolean' && result) {
        this.resumenClinico.openResumenClinicoModal();
      }
    });
  }

  private setTypesExams() {
    this._tabExamenesService.getTypesExams().subscribe((typeExams) => {
      if (typeExams.success) {
        const exams = typeExams.data;
        const laboratorio = exams.find(
          (exam: any) => exam.descripcion === 'Laboratorio'
        );
        const imagenes = exams.find(
          (exam: any) => exam.descripcion === 'Imágenes'
        );
        this.laboratorioTypeExam = laboratorio
          ? laboratorio.idTipoExamen
          : null;
        this.imagenesTypeExam = imagenes ? imagenes.idTipoExamen : null;
      }
      this.setExams();
    });
  }
  private setExams() {
    this._tabExamenesService.getExamsByType().subscribe((response) => {
      if (response.success) {
        const examsData = response.data;
        const laboratorioExams = examsData
          .filter(
            (examType: any) =>
              examType.idTipoExamen === this.laboratorioTypeExam
          )
          .flatMap((examType: any) => examType.examenes);
        const imagenesExams = examsData
          .filter(
            (examType: any) => examType.idTipoExamen === this.imagenesTypeExam
          )
          .flatMap((examType: any) => examType.examenes);
        this.examsLaboratorio.set(laboratorioExams);
        this.examsImagen.set(imagenesExams);
      }
    });
  }
}
