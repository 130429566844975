<div class="section-inputs">
  <mat-form-field appearance="outline" [class.disabled-select]="control.disabled">
    <mat-label>{{label}}</mat-label>
    <mat-select
      matSelect
      [formControl]="control"
      [placeholder]="label"
      (selectionChange)="onSelectionChange($event)"
    >
      <mat-option *ngFor="let option of options"
                  [value]="option[valueProperty]">
        {{ option[displayProperty] }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="control.hasError('required') && control.touched">
      {{  errorMessages.required}}
    </mat-error>
  </mat-form-field>
</div>
