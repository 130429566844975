<div class="video-background">
  <video autoplay muted loop id="background-video">
    <source src="/assets/images/AdobeStock_273863456.mov" type="video/mp4">
    Your browser does not support HTML5 video.
  </video>

<div class="layaout-login col-md-12">
  <div *ngIf="!crearPass" class="col-md-5 contenedor-login">
    <div class="titulo-login">
      <h1>
        <span class="bienvenido">¡Bienvenido a Sophia,<br></span>
        <span class="tu">tu </span>
        <span class="asistente">asistente médico!</span></h1>
    </div>

    <form [formGroup]="formularioLogin" (ngSubmit)="onLogin()">
      <div class="row contenedor-formulario-login">

        <p>Ingresa tu correo electrónico y contraseña</p>
        <div class="section-inputs">
          <div class="input-generico">
            <soph-input-form-field
              [control]="emailControl"
              label="Correo electrónico"
              [errorMessages]="errorMessages"
              (input)="restringirCaracteresEspeciales($event)">
              >
            </soph-input-form-field>
          </div>

          <mat-form-field  class="estilo-input" appearance="outline">

            <mat-label>Contraseña</mat-label>
            <input matInput
                   formControlName="password"
                   [type]="hide ? 'password' : 'text'"
                   maxlength="12"
                   (keydown.space)="$event.preventDefault()"
                   required>
            <button mat-icon-button matSuffix
                    type="button"
                    (click)="toggleVisibility()"
                    [attr.aria-label]="'Mostrar u ocultar contraseña'"
                    [attr.aria-pressed]="!hide">
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-hint>Debe contener mínimo 8 caracteres, una mayúscula y un número.</mat-hint>
            <mat-error *ngIf="formularioLogin.get('password')?.hasError('required')">
              Por favor ingrese una contraseña
            </mat-error>
            <mat-error *ngIf="formularioLogin.get('password')?.hasError('maxlength')">
              Debe contener mínimo 8 caracteres, una mayúscula y un número
            </mat-error>
            <mat-error *ngIf="formularioLogin.get('password')?.hasError('minlength')">
              Debe contener mínimo 8 caracteres, una mayúscula y un número
            </mat-error>
          </mat-form-field>

          <div class="texto-recuperar-pass">
            <a  class="hipervinculo" (click)="recuperarPassword()" >Recupera tu contraseña</a>
          </div>
          <div *ngIf="showErrorFire" class="alert-invalidos">
            <p class="estilo-texto-alert">{{ errorMessage }}</p>
          </div>
          <div class="row section-button">
            <button mat-flat-button color="primary"
                    type="submit"
            [disabled]="isFormInvalid()">
              <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
              <span *ngIf="!isLoading">Iniciar sesión</span>
            </button>
          </div>
        </div>
        <div class="section-footer">
          <p class="texto-ayuda-footer">Al continuar, aceptas los</p>
          <p class="footer-login">Términos y condiciones del servicio</p>
        </div>
      </div>
    </form>
  </div>
</div>

</div>
