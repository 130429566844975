import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'soph-container-chip',
  standalone: true,
  imports: [],
  templateUrl: './container-chip.component.html',
  styleUrl: './container-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerChipComponent {

}
