import {ChangeDetectionStrategy, Component,
  ChangeDetectorRef,
  inject} from '@angular/core';
import {CommonModule} from "@angular/common";
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {Router} from "@angular/router";
import {
  Auth,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail
} from '@angular/fire/auth';
import {environment} from "../../../../../environments/environment";
import {MatIconModule} from "@angular/material/icon";
import { MatDialog } from '@angular/material/dialog';
import {ModalPassExitosaComponent} from "../modal-pass-exitosa/modal-pass-exitosa.component";
import { ActivatedRoute } from '@angular/router';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ModalErrorGeneralComponent} from "../../../common/modal-error-general/modal-error-general.component";
import {ReenvioEnlacePasswordService} from "../../../../shared/services/reenvio-enlace-password.service";
import {ModalSolicitudEnviadaComponent} from "../modal-solicitud-enviada/modal-solicitud-enviada.component";
import {RecuperarPasswordService} from "../../../../shared/services/recuperar-password.service";
import {Observable, of} from "rxjs";

@Component({
  selector: 'soph-crear-password',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ModalPassExitosaComponent,
    MatProgressSpinnerModule
  ],
  templateUrl: './crear-password.component.html',
  styleUrl: './crear-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrearPasswordComponent {

  createPass: FormGroup;
  private auth = inject(Auth);
  public hide = true;
  public oobCode: string | null = null;
  public isLoading = false;
  get password() {
    return this.createPass.get('password');
  }
  get confirmPassword() {
    return this.createPass.get('confirmPassword');
  }
  showErrorExpiroEnlace = false;
  isLoadingLink : boolean = false;
  reenvioEnlace: any
  showLinkEnlace: boolean = false;
  email: string | null = null;
  constructor(private fb: FormBuilder,
              private router: Router,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private reenvioEnlaceService:
                ReenvioEnlacePasswordService,
              private recuperarPasswordService:
                RecuperarPasswordService,
              private cd: ChangeDetectorRef) {
    this.createPass = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(10)]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(10)]]
    }, {
      asyncValidators: this.passwordsMatchValidator()
    });
    this.auth.tenantId = environment.tenantId;
    this.oobCode =
      this.route.snapshot.queryParamMap.get('oobCode');
    this.email =
      this.route.snapshot.queryParamMap.get('email');
  }
  ngOnInit(): void {
      this.reenvioEnlacePass();
    this.cd.detectChanges();
  }

  async createPassword() {
    if (this.createPass.valid && this.oobCode) {
      const password = this.createPass.get('password')?.value;
      this.isLoading = true;
      try {
        await confirmPasswordReset(this.auth, this.oobCode, password);
        this.dialog.open(ModalPassExitosaComponent);
      } catch (err) {
        console.error('Error al restablecer la contraseña:', err);
      } finally {
        this.isLoading = false;
      }
    }
  }



   passwordsMatchValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: boolean } | null> => {
      const password = control.get('password')?.value;
      const confirmPassword = control.get('confirmPassword')?.value;

      if (password && confirmPassword && password !== confirmPassword) {
        this.createPass.get('confirmPassword')?.setErrors({ passwordsMismatch: true });
        return of({ passwordsMismatch: true });
      } else {
        this.createPass.get('confirmPassword')?.setErrors(null);  // Eliminar errores si coinciden
        return of(null)
      }
    };
  }


  public getPasswordErrorMessage(): string {
    const password = this.createPass.get('password');
    if (password?.hasError('required')) {
      return 'Por favor, ingrese una contraseña';
    }
    if (password?.hasError('minlength')) {
      return 'Debes ingresar el menos 6 caractéres';
    }
    return '';
  }

  public getConfirmPasswordMessageError(){
    const confirmPassword = this.createPass.get('confirmPassword');
    if (confirmPassword?.hasError('required')) {
      return 'Por favor, ingrese una contraseña';
    }
    if (confirmPassword?.hasError('minlength')) {
      return 'Debes ingresar el menos 6 caractéres';
    }
    if (confirmPassword?.hasError('passwordsMismatch')){
      return 'Las contraseñas no coindicen';
    }
    return '';
  }



  reenvioEnlacePass(){
    if(this.email){
      const email = this.email.split('?')[0];
      this.reenvioEnlaceService.getEnlacePass(email).
      subscribe({
        next: (resp) => {
          this.isLoadingLink = false;
          this.reenvioEnlace = resp.data;
          if (this.reenvioEnlace.expirate) {
            this.showLinkEnlace = true;
          } else {
            this.showLinkEnlace = false;
          }
          this.cd.detectChanges();
        }
      });
    }
  }

  public reenvioEmail() {
    const clientId ="4b9b9ab5b734408d915ec31751bbf114";
    if(this.email){
      const email = this.email.split('?')[0];
      this.recuperarPasswordService.
      postRecuperarPassword(clientId,
        email).subscribe({
        next: (response) => {
          this.dialog.open(ModalSolicitudEnviadaComponent);
        },
        error: (err) => {
          console.error('API call failed:', err);
        },
      });
    }

  }

  public toggleVisibility() {
    this.hide = !this.hide;
  }

  public volverAlogin(){
    this.router.navigate(['/login']);
  }

}
